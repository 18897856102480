import React, { useContext } from 'react'
import { firebaseAuth } from 'contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// components
import AppLogo from '../../../assets/img/eiotlogo.png'
import { Button } from '@chakra-ui/react'
import { systemInfoPost } from 'redux/actions'
import { useTranslation } from 'react-i18next'
import Flags from 'country-flag-icons/react/3x2'
import * as actions from '../../../redux/actions'

export default function Navbar(props) {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  const { handleSignout } = useContext(firebaseAuth)
  const system = useSelector((state) => state.system)
  const history = useHistory()
  const onSignOut = async () => {
    dispatch(actions.meSignout())
    history.push('/login')
  }

  if (system && system.isLoading === true) {
    return (
      <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow-md">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start font-sans">
            <div className="flex gap-2">
              <Link
                to="/"
                className="text-blue-900 text-base font-bold leading-relaxed mr-4 py-2 whitespace-no-wrap flex"
              >
                <img
                  src={system?.logo || AppLogo}
                  className="h-10 rounded-md"
                />
                <div className="my-auto pl-4">
                  {system?.name || 'ระบบ E-IoT'}
                </div>
              </Link>
            </div>
            <div className="lg:hidden block py-2 px-3 text-gray-600">
              <Button
                size="sm"
                colorScheme="blue"
                variant="outline"
                onClick={() => {
                  onSignOut()
                }}
              >
                <i className="fas fa-sign-out-alt"></i>
              </Button>
            </div>
          </div>
          <div
            className={`lg:flex flex-grow items-center bg-white text-white lg:bg-transparent lg:shadow-none${
              navbarOpen ? ' block' : ' hidden'
            }`}
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <div className="flex ">
                  <div
                    className="shadow-sm mr-2 cursor-pointer"
                    onClick={() => {
                      i18n.changeLanguage('th')
                    }}
                  >
                    <Flags.TH title="Thai" className="w-5" />
                  </div>
                  <div
                    className="shadow-sm mr-2 cursor-pointer"
                    onClick={() => {
                      i18n.changeLanguage('en')
                    }}
                  >
                    <Flags.GB title="English" className="w-5" />
                  </div>
                </div>
              </li>
              <li className="flex items-center">
                <Link to="/auth/login">
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant="outline"
                    leftIcon={<i className="fas fa-sign-out-alt"></i>}
                    onClick={() => {
                      onSignOut()
                    }}
                  >
                    ออกจากระบบ
                  </Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  } else {
    return <></>
  }
}
