import React from 'react'
import {
  Button,
  ButtonGroup,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { printPDF } from './EstimateBillReport'
import { useTranslation } from 'react-i18next'

export default function EstimateBillLists({
  electricConsumption,
  systemInfo,
  stationId,
}) {
  console.log('Station ID ----------------------------------', stationId)
  const { t } = useTranslation()
  const genEstimateBillLists = () =>
    // ดึงค่าไฟแต่ละเดือน เฉพาะ station ที่เลือก
    _.map(
      _.filter(
        electricConsumption,
        (station) => station.stationId === stationId
      ),
      (_electricConsumption, index) => (
        <Tr key={_electricConsumption._id}>
          <Td>{index + 1}</Td>
          <Td> {moment(_electricConsumption.date).format('MM/YYYY')} </Td>
          <Td>{parseFloat(_electricConsumption.all).toFixed(2) + ' หน่วย'}</Td>

          <Td>
            {parseFloat(_electricConsumption.max_on_peak).toFixed(2) +
              ' กิโลวัตต์ '}
          </Td>
          <Td>
            {parseFloat(_electricConsumption.needPower).toFixed(2) + ' บาท'}
          </Td>
          <Td>
            {parseFloat(_electricConsumption.summary).toFixed(2) + ' บาท '}
          </Td>
          <Td>
            <Link
              to={
                '/electric-bill-estimator/' +
                _electricConsumption.stationName +
                '/' +
                _electricConsumption._id
              }
            >
              <Button colorScheme="linkedin" variant="solid">
                รายละเอียด
              </Button>
            </Link>
            <Link
              to={
                '/electric-bill-estimator/' +
                _electricConsumption.stationName +
                '/' +
                _electricConsumption._id
              }
            ></Link>{' '}
            <Button
              colorScheme="yellow"
              variant="solid"
              onClick={() => {
                printPDF(_electricConsumption, systemInfo)
              }}
            >
              พิมพ์
            </Button>
          </Td>
        </Tr>
      )
    )

  return (
    <div>
      <Table size="sm" variant="striped" className="whitespace-no-wrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t('dateTime.month')}</Th>
            <Th>{t('counter.unit')}</Th>
            <Th> {t('electricBill.maxEnergyOnPeak')}</Th>
            <Th>{t('electricBill.electricPowerTotalPeak')}</Th>
            <Th>{t('electricBill.electricTotalAddTax')}</Th>
          </Tr>
        </Thead>
        <Tbody>{genEstimateBillLists()}</Tbody>
      </Table>
    </div>
  )
}
