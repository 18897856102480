/* eslint-disable consistent-return */
/* eslint-disable curly */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import _, { each } from 'lodash'
import { Button, Switch, Select, Checkbox, Input } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
import FetchSubexpression from './Component/FetchSubexpression'

import 'katex/dist/katex.min.css'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../redux/actions'

export default function EditExpression() {
  const { t, i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [selectStation, setSelectStation] = useState()
  const [selectVariable, setSelectVariable] = useState()

  const params = useParams()
  const station = useSelector((state) => state.station)
  const summarize = useSelector((state) => state.summarize)
  const dispatch = useDispatch()
  const history = useHistory()

  console.log('sum', summarize)
  console.log('Id : ', params.id)

  const { watch, register, handleSubmit, reset, control, setValue } = useForm({
    defaultValues: {
      varComponent: summarize?.varComponent || [{}],
    },
  })

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'varComponent',
  })

  useEffect(() => {
    dispatch(actions.summarizeGet(params?.id))
    dispatch(actions.stationAll()).then(() => setIsLoading(true))
    return () => {}
  }, [params])

  useEffect(() => {
    if (summarize) {
      setValue('varComponent', summarize?.varComponent)
    }

    return () => {}
  }, [summarize])

  const findStation = (id) => {
    const data = _?.find(station?.arr, (each) => each?._id === id)
    setSelectStation(data)
  }

  const findData = (id) => {
    const data = _?.find(selectStation?.variables, (each) => each?._id === id)
    setSelectVariable(data)
  }

  const selectData = (id) => {
    return _.map(station.arr, (st, key) => {
      if (st._id === id) {
        return _.map(st.variables, (variable, key) => {
          return <option value={variable?._id}>{variable.variableName}</option>
        })
      }
    })
  }

  const onSubmit = async (data, e) => {
    let confirm = window.confirm('ยืนยันการเพิ่มการทำนาย')
    let payload = {}

    payload = {
      ...summarize,
      ...data,
      expression: true,
    }
    //console.log('Payload', payload)
    if (confirm) {
      dispatch(actions.summarizePut(params.id, data))
        .then(() => {
          history.goBack()
        })
        .catch((err) => {
          alert(err?.message)
        })
    }
  }

  if (station && isLoading) {
    return (
      <div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      {'แก้ไขนิพจน์'}
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      {'นิพจน์'}
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกสถานีของนิพจน์'}
                          </label>
                          <Select
                            {...register('station')}
                            size="sm"
                            name="station"
                            placeholder="เลือกสถานี"
                            defaultValue={summarize?.station}
                            onChange={(e) => findStation(e?.target?.value)}
                          >
                            {_.map(station?.arr, (name, key) => {
                              return (
                                <option value={name?._id}>{name.name}</option>
                              )
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกข้อมูลนิพจน์'}
                          </label>
                          {watch('station') && (
                            <Select
                              {...register('variable')}
                              size="sm"
                              name="variable"
                              placeholder="เลือกข้อมูล"
                              defaultValue={summarize?.variable?._id}
                              onChange={(e) => findData(e?.target?.value)}
                            >
                              {/* ใช้ตัวนี้เป็นการรับ Source ว่าเป็น Sensor หรือ Varible หรือ การกรอกข้อมูล */}
                              {selectData(watch('station'))}
                            </Select>
                          )}
                        </div>
                      </div>
                      {/* เพิ่มเซนเซอร์การทำนายที่เกี่ยวข้อง */}
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกข้อมูลพจน์ที่เกี่ยวข้อง'}
                          </label>

                          <FetchSubexpression
                            register={register}
                            fields={fields}
                            append={append}
                            remove={remove}
                            station={station}
                            control={control}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        {t('utility.save')}{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
