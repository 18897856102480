import React from 'react'
import { Box } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function CountingNumberGauge({
  staticData,
  unit = '',
  graphLabel = '',
  favoriteButton,
  link = null,
  size = 'md', // md or sm,
}) {
  const sizeSelector = {
    md: {
      mainData: 'text-4xl',
      label: 'text-2xl',
      weight: 'font-bold',
      unit: 'text-xl',
    },
    sm: {
      mainData: 'text-xl',
      label: 'text-normal',
      weight: 'font-bold',
      unit: 'text-normal',
    },
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-2 w-full  md:w-full  h-25"
    >
      {favoriteButton}
      {link ? (
        <Link
          to={link}
          className={`${sizeSelector[size]?.weight || 'font-bold'}  
          ${sizeSelector[size]?.label || 'text-2xl'}  ${
            favoriteButton ? 'px-2' : ''
          } hover:text-gray-600`}
        >
          {graphLabel}
        </Link>
      ) : (
        <h5
          className={`${sizeSelector[size]?.weight || 'font-bold'}  
          ${sizeSelector[size]?.label || 'text-2xl'}  ${
            favoriteButton ? 'px-2' : ''
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: graphLabel }}></div>
        </h5>
      )}
      <h5 className="font-bold text-2xl"> {graphLabel} </h5>
      <div>
        <h1 className=" font-bold text-4xl text-blue-500 mb-0 ">
          {parseFloat(staticData).toFixed(0)}
        </h1>
        <h3 className="font-semibold text-lg"> {unit}</h3>
      </div>
    </Box>
  )
}
