import api from '../../../util/api'
import {
  FORM_ALL,
  FORM_GET,
  FORM_PUT,
  FORM_DEL,
  FORM_LOADING,
  FORM_ERROR,
  FORM_POST,
} from '../types'

export const formAll =
  ({ station }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/form?station=${station}`
      )
      if (status === 200) {
        dispatch({ type: FORM_ALL, payload: data })
      }
    } catch (error) {
      console.error(error)
      dispatch({ type: FORM_ERROR })
      throw new Error(error)
    }
  }

export const formGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/form/${id}`
    )
    if (status === 200) {
      dispatch({ type: FORM_GET, payload: data })
    }
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_ERROR })
    throw new Error(error)
  }
}

export const formPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: FORM_LOADING })
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/form/${id}`,
      payload
    )
    dispatch({ type: FORM_PUT, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_ERROR })
    throw new Error(error)
  }
}

export const formPost = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FORM_LOADING })
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/form`,
      payload
    )
    dispatch({ type: FORM_POST, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_ERROR })
    throw new Error(error)
  }
}

export const formDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: FORM_LOADING })
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/form/${id}`
    )
    dispatch({ type: FORM_DEL, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: FORM_ERROR })
    throw new Error(error)
  }
}
