import axios from 'axios'
import { USER_ALL, USER_GET, USER_PUT, USER_DEL, USER_POST } from '../types'
import { setStorage, removeStorage } from '../../../util/localstorage'

export const userAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + '/user').then((res) => {
      console.log('Request Server to Get All Users')
      dispatch({ type: USER_ALL, payload: res.data })
    })
  }
}

export const userGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/user/' + payload)
      .then((res) => {
        console.log('Request Server to Get an Users')
        if (res.data) {
          dispatch({ type: USER_GET, payload: res.data })
        } else {
          dispatch({ type: USER_GET, payload: null })
        }
      })
  }
}

export const userPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + '/user/' + id, payload)
      .then((res) => {
        console.log('Request Server to put an Users')

        dispatch({ type: USER_PUT, payload: null })
      })
  }
}
export const userPost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + '/user/create', payload)
      .then((res) => {
        console.log('Request Server to post an Users')
        dispatch({ type: USER_POST, payload: null })
      })
      .catch((error) => {
        console.error('Error User Create', error)
        throw error
      })
  }
}
export const userDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + '/user/' + payload)
      .then((res) => {
        console.log('Request Server to Delete an Users')
        dispatch({ type: USER_DEL, payload: null })
      })
  }
}
export const userLogin = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + '/user/login', payload)
      .then((res) => {
        console.log('Request Server Login Users')
        const { data } = res
        console.log('Data', data)
        const eiot_remember = {
          uid: data._id,
          photoURL: data.photoURL,
          username: data.username,
        }

        setStorage('token', data.accessToken)
        setStorage('eiot_remember', JSON.stringify(eiot_remember))
        console.log('Set Remember')

        if (data) {
          console.log('Login Success')
          dispatch({ type: USER_GET, payload: data })
        } else {
          dispatch({ type: USER_GET, payload: null })
        }
      })
      .catch((error) => {
        console.error('Error From User Action', error.response.data.error)
        throw error.response.data.error
      })
  }
}
