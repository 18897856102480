import React from 'react'
import { FormControl, Input, Select } from '@chakra-ui/react'
import _ from 'lodash'
import { GaugeSource } from '../../../../../config/VariableTypeOfSource'

export default function EditingStaticBooleanDisplay({
  gaugeData,
  register,
  stationData,
  gaugeSource,
}) {
  const genRefStringList = () => {
    return _.map(stationData.sensors, (sensor) => (
      <option value={sensor.refString}>{sensor.refString}</option>
    ))
  }

  const genVariableList = () => {
    return _.map(stationData.variables, (variable) => (
      <option value={variable._id}>{variable.variableName}</option>
    ))
  }
  const colorList = () => (
    <>
      <option value="red">แดง</option>
      <option value="yellow">เหลือง</option>
      <option value="green">เขียว</option>
      <option value="blue">น้ำเงิน</option>
      <option value="purple">ม่วง</option>
      <option value="pink">ชมพู</option>
      <option value="gray">เทา</option>
    </>
  )
  return (
    <>
      {gaugeSource === GaugeSource.sensor ? (
        <FormControl>
          <label className="text-sm font-bold">
            {' '}
            เซ็นเซอร์ที่ต้องการเชื่อมต่อ{' '}
          </label>
          <Select
            defaultValue={gaugeData.sensorRefString}
            {...register('sensorRefString')}
            size="sm"
            name="sensorRefString"
          >
            {genRefStringList()}
          </Select>
        </FormControl>
      ) : (
        <FormControl>
          <label className="text-sm font-bold">
            ตัวแปรที่ต้องการจะเชื่อมต่อ
          </label>
          <Select
            defaultValue={gaugeData.variableId}
            {...register('variableId')}
            size="sm"
            name="variableId"
          >
            {genVariableList()}
          </Select>
        </FormControl>
      )}

      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          คำที่จะแสดงเมื่อเป็นค่า High{' '}
        </label>
        <Input
          type="text"
          name="valueLabel.high"
          size="sm"
          defaultValue={gaugeData.valueLabel?.high}
          {...register('valueLabel.high')}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          ค่าที่จะแสดงเมื่อเป็นค่า Low{' '}
        </label>
        <Input
          type="text"
          name="valueLabel.low"
          size="sm"
          defaultValue={gaugeData.valueLabel?.low}
          {...register('valueLabel.low')}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold"> สีเมื่อแสดงค่าเป็น High </label>
        <Select
          type="text"
          name="valueColor.high"
          size="sm"
          defaultValue={gaugeData.valueColor?.high}
          {...register('valueColor.high')}
        >
          {colorList()}
        </Select>
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold"> สีเมื่อแสดงค่าเป็น Low </label>
        <Select
          type="text"
          name="lowLevelColor"
          size="sm"
          defaultValue={gaugeData.valueColor?.low}
          {...register('lowLevelColor')}
        >
          {colorList()}
        </Select>
      </FormControl>
    </>
  )
}
