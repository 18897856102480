/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../redux/actions'
import { Link, useParams, useHistory } from 'react-router-dom'
import BackButton from 'components/Button/BackButton'
// components
import {
  Button,
  Collapse,
  Icon,
  Select,
  Link as LinkUI,
} from '@chakra-ui/react'
import DashboardMonitorComponent from '../Components/DashboardMonitorComponent'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import PlaceHolderLogo from '../../../assets/img/eiotlogo.png'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import DashboardMonitorComponentSlide from './DashboardMonitorComponentSlide'

export default function DashboardGroup({ sidebarStatus, setIsShowSidebar }) {
  const [selectType, setSelectType] = useState('slide01')
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [currentStation, setcurrentStation] = useState()
  const [currentGroup, setcurrentGroup] = useState()
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const [isStationInfoShow, setIsStationInfoShow] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const me = useSelector((state) => state.me)
  const history = useHistory()
  useEffect(() => {
    if (me && me._id && currentStation) {
      if (_.includes(me.duty, 'ALLAREA') || me.duty === currentStation._id) {
        console.log('ALLAREA')
      } else {
        console.log('ผู้ใช้ไม่มีสิทธิเข้าใช้')
      }
      console.log('current ', currentStation)
    }
    return () => {}
  }, [me, currentStation])

  useEffect(() => {
    if (isFetchNewOne === true) {
      dispatch(actions.stationAll())
      //FIXME: Uncommen to Enable Auto Refresh
      setisFetchNewOne(false)
    }
    return () => {}
  }, [isFetchNewOne])

  // ดึงข้อมูลสถานีทั้งหมดมา เพราะว่า จะต้องเอาไปโชว์ใน Sidebar
  // จากนั้นค่อยหาเอาตัวหนึ่งที่ไอดีมันถูก
  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.stationId
      )
      setcurrentStation(tempcurrentStatus)
    }
    if (currentStation) {
      const tempGroup = _.find(
        currentStation?.groups,
        (group) => group?._id === params?.groupId
      )
      setcurrentGroup(tempGroup)
    }

    console.log('Curent Station', currentStation)
    return () => {}
  }, [params, allStations, me])

  useEffect(() => {
    setIsLoading(true)
    return () => {}
  }, [])

  useEffect(() => {
    if (isFetchNewOne === false) {
      setTimeout(() => {
        setisFetchNewOne(true)
      }, 10000)
    }
    return () => {}
  }, [isFetchNewOne])

  const handleRefreshStation = () => {
    dispatch(actions.stataionRefresh(currentStation._id))
  }

  const handleEditUserFavorite = (data) => {
    const checkFavorite = _.find(
      me?.favorite?.gauge,
      (gauges) => gauges === data?._id
    )
    const arrayData = me?.favorite?.gauge
    //console.log('arrayData', arrayData)

    if (!_.isEmpty(checkFavorite)) {
      _.remove(arrayData, (e) => e === data?._id)
      const dataSubmit = {
        ...me,
        favorite: {
          gauge: arrayData,
          chart: me?.favorite?.chart,
        },
      }
      dispatch(actions.userPut(me?._id, dataSubmit))
      dispatch(actions.meGet({}))
      setisFetchNewOne(true)
    } else {
      arrayData.push(data?._id)
      const dataSubmit = {
        ...me,
        favorite: {
          gauge: arrayData,
          chart: me?.favorite?.chart,
        },
      }
      dispatch(actions.userPut(me?._id, dataSubmit))
      dispatch(actions.meGet({}))
      setisFetchNewOne(true)
    }
  }
  console.log('me', me)

  const findGroup = (ss) => {
    return _.map(currentStation?.groups, (group, index) => {
      console.log('ss', ss)
      if (ss === 'LEFT') {
        return (
          <Link
            to={
              '/dashboards/boardgroup/' + currentStation._id + '/' + group._id
            }
          >
            <h2 className="text-xl font-bold ">
              {' '}
              <LinkUI className="font-sans font-bold">
                {group.groupName}{' '}
              </LinkUI>{' '}
            </h2>{' '}
          </Link>
        )
        //  <Link to={'/dashboards/boardgroup/' +station?._id +'/' +group?._id}>
        //   <i className="fas fa-angle-double-left"></i>{' '}
        //   {t('dashboard.stationInfo')}
        // <Link/>
      }
    })
  }

  let orderedGauge = _.orderBy(currentStation?.gauges, 'orderLevel', 'asc')
  const Sg = []
  const searchGauge = () =>
    _.map(currentGroup?.gauge, (gaugeS) => {
      const GG = _.filter(orderedGauge, (gg, ind) => {
        let spin = gaugeS?.split('/')
        if (gg._id === spin[2]) {
          // return gg
          Sg.push(gg)
        }
      })
      //console.log('GG', GG)
      return GG
    })

  // console.log('gauge', orderedGauge, Sg)

  return isLoading && currentStation ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('dashboard.dashboardMonitor')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('dashboard.dashboard')} {currentStation.name}
            </h2>
          </div>
        </div>
        <Button>
          <BackButton />
        </Button>
      </div>
      <div className="flex justify-between p-4">
        {/* <div className="flex justify-items-start">
          <Select
            placeholder="รูปแบบการโชว์"
            type="text"
            name="electricType"
            defaultValue={selectType}
            onChange={handleSelectType}
          >
            <option value="slide01">เลื่อนโชว์</option>
            <option value="slide02">เลือกแผนก</option>
          </Select>
          <Button
            colorScheme="blue"
            variant="solid"
            size="sm"
            onClick={() => handleRefreshStation()}
          >
            {t('utility.refresh')}
          </Button>
        </div> */}
        <h2 className="text-gray-800 text-xl px-10 font-bold font-sans">
          กลุ่ม {currentGroup?.groupName}
        </h2>
        <div className="flex justify-items-end gap-1">
          {/* {findGroup('LEFT')} */}
          {/* {findGroup('RIGHT')} */}
          {/* <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsStationInfoShow(!isStationInfoShow)}
          >
            <i className="fas fa-angle-double-left"></i>{' '}
            {t('dashboard.stationInfo')}
          </Button>
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsShowSidebar(!sidebarStatus)}
          >
            {t('dashboard.toggleSidebar')}
            <i className="fas fa-angle-double-right"></i>
          </Button> */}
        </div>
        <div className="flex justify-items-end gap-1">
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsStationInfoShow(!isStationInfoShow)}
          >
            {t('dashboard.stationInfo')}
          </Button>
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsShowSidebar(!sidebarStatus)}
          >
            {t('dashboard.toggleSidebar')}
          </Button>
        </div>
      </div>

      <div className="p-4 w-full font-sans">
        <Collapse in={isStationInfoShow} animateOpacity>
          <div className="bg-gray-200 border rounded-md p-2">
            <div className="flex mx-2 ">
              <div className="w-1/6 flex justify-center">
                <img
                  src={
                    currentStation.image
                      ? currentStation.image
                      : PlaceHolderLogo
                  }
                  alt={currentStation.name}
                  className="h-24"
                />
              </div>
              <div className="w-2/6 my-2">
                <h3 className="text-gray-800 text-xl font-bold font-sans">
                  {currentStation.name}
                </h3>
                <p className="text-gray-800 text-base  font-sans">
                  {currentStation.description}
                </p>
                <h6 className="text-gray-800 text-base font-bold font-sans">
                  {currentStation.location}
                </h6>
              </div>
              <div className="w-2/6 my-2">
                <h6 className="text-gray-800 text-base font-bold font-sans">
                  {t('dashboard.stationMap')}
                </h6>
                <iframe
                  src={
                    'https://www.google.com/maps/embed/v1/place?key=AIzaSyCW6HXYlqqnvyEVcgjXfdFVZvHxz7l-btQ&q=' +
                    currentStation.location +
                    '&region=th&language=th'
                  }
                  width="380"
                  height="200"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </Collapse>
        {searchGauge()}
        {_.map(_.chunk(Sg, 20), (e) => {
          return (
            <div className="p-4 w-full font-sans">
              <DashboardMonitorComponentSlide
                currentStation={e}
                station={currentStation}
                handleEditUserFavorite={handleEditUserFavorite}
                user={me}
              />
            </div>
          )
        })}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
