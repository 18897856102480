import React, { useState, useEffect } from 'react'
import { Button, Box, Select, Input } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import CreateGroupPrediction from './CreateGroupPrediction'

export default function FetchGroupPrediction({
  register,
  append,
  remove,
  fields,
  station,
  control,
  setValue,
  watch,
}) {
  //const dispatch = useDispatch()
  // const prediction = useSelector((state) => state.prediction)
  const { t, i18n } = useTranslation()
  const handleRemove = (index) => {
    remove(index)
  }

  //console.log('stataion ', station)

  return (
    <div className="w-full  px-4">
      <div className="w-full  px-4">
        {console.log('field', fields)}
        {_.map(fields, (row, index) => (
          <Box
            key={row?.id}
            padding="4"
            borderRadius="lg"
            borderWidth="2"
            className="w-full text-sm font-semibold border my-2"
          >
            <div className="flex justify-between">
              <h5 className="mb-2">ชื่อกลุ่มที่ {index + 1} </h5>

              <Button
                colorScheme="red"
                size="sm"
                onClick={() => handleRemove(index)}
              >
                {t('utility.delete')}
              </Button>
            </div>
            <div>
              <Input
                placeholder="ชื่อกลุ่ม"
                name={`varDetail[${index}].groupName`}
                {...register(`varDetail[${index}].groupName`)}
                size="sm"
                defaultValue={row?.groupName}
              />
            </div>
            <CreateGroupPrediction
              register={register}
              control={control}
              index={index}
              station={station}
              setValue={setValue}
              watch={watch}
            />
          </Box>
        ))}
        <Button
          isFullWidth
          colorScheme="blue"
          size="sm"
          onClick={() => append({})}
        >
          เพิ่มกลุ่มใหม่
        </Button>
      </div>
    </div>
  )
}
