import StaticBoolean from './StaticBooleanDisplay'
import StaticMeter from './StaticMeter'
import StaticMeterClassic from './StaticMeterClassic'
import StaticMeterColors from './StaticMeterColors'
import StaticNumber from './StaticNumberDisplay'
import StaticString from './StaticStringDisplay'
import StaticBooleanWater from './StaticBooleanWaterDisplay'
import StaticCountingNumber from './StaticCountingNumberDisplay'

const StaticGauge = {
    StaticBoolean,
    StaticMeter,
    StaticMeterClassic,
    StaticMeterColors,
    StaticNumber,
    StaticString,
    StaticBooleanWater,
    StaticCountingNumber,
}
export default StaticGauge
