/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import _ from 'lodash'
import BackButton from 'components/Button/BackButton'
import { Box, Button, useDisclosure } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import 'katex/dist/katex.min.css'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'

export default function AddGroup() {
  const { t, i18n } = useTranslation()
  const [sensorType, setsensorType] = useState('BOOLEAN')
  const [isLoading, setIsLoading] = useState(false)
  const [select, setSelect] = useState('GAUGE')
  const params = useParams()
  const station = useSelector((state) => state.station)
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit, reset, control, setValue } = useForm()
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'group',
  })
  const handleRemove = (index) => {
    remove(index)
  }
  useEffect(() => {
    dispatch(actions.stationGet(params.stationId))
    setIsLoading(true)
    return () => {}
  }, [params, select])

  const onSubmit = async (data, e) => {
    let templateValue = station
    const searchGauge = _.filter(data?.group, (gg, ind) => {
      let spin = gg.split('/')
      if (spin[0] === 'GAUGE') {
        return gg
      }
    })
    const searchChart = _.filter(data?.group, (gg, ind) => {
      let spin = gg.split('/')
      if (spin[0] === 'CHART') {
        return gg
      }
    })
    const searchDiagram = _.filter(data?.group, (gg, ind) => {
      let spin = gg.split('/')
      if (spin[0] === 'DIAGRAM') {
        return gg
      }
    })
    // console.log('Data', data, searchGauge)
    const payload = {
      groupOrder: templateValue?.groups?.length,
      gauge: searchGauge,
      chart: searchChart,
      diagram: searchDiagram,
      ...data,
    }
    console.log('Data', data, payload)
    let confirm = window.confirm('ยืนยันการเพิ่มกลุ่ม')
    if (confirm) {
      e.preventDefault()

      if (templateValue && templateValue?.groups) {
        templateValue?.groups.push(payload)
        console.log('PUSHED')
      }
      console.log('New Added Value ', templateValue)
      dispatch(actions.stationPut(params.stationId, templateValue)).then(() => {
        dispatch(actions.stationAll()).then(() => {
          history.goBack()
        })
      })
    }
  }
  const genGauge = () =>
    _.map(station?.gauges, (gauge, index) => {
      return (
        <option value={'GAUGE' + '/' + gauge?.gaugeLabel + '/' + gauge?._id}>
          เกจ : {gauge?.gaugeLabel}
        </option>
      )
    })

  const genChart = () =>
    _.map(station?.charts, (chart, index) => {
      return (
        <option value={'CHART' + '/' + chart?.chartLabel + '/' + chart?._id}>
          กราฟ : {chart?.chartLabel}
        </option>
      )
    })
  const genDiagram = () =>
    _.map(station?.diagrams, (diagram, index) => {
      return (
        <option
          value={'DIAGRAM' + '/' + diagram?.diagramName + '/' + diagram?._id}
        >
          ไดอะแกรม : {diagram?.diagramName}
        </option>
      )
    })

  if (station && isLoading) {
    return (
      <div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      {'เพิ่มกลุ่มลงในสถานี\n'}
                      {station.name}
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <Button>
                    <BackButton />
                  </Button>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      {'กลุ่ม'}
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'ชื่อกลุ่ม'}
                          </label>
                          <input
                            name="groupName"
                            type="text"
                            required
                            {...register('groupName')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder={t('เช่น group 1 ')}
                          />
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เกี่ยวกับกลุ่ม'}
                          </label>
                          <input
                            name="description"
                            type="text"
                            {...register('description')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder={'เช่น ใช้คำนวณหาพื้นที่วงกลม'}
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            สิทธิการเข้าถึง
                          </label>
                          <select
                            name="role"
                            required
                            {...register('role')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value={'USER'}>USER</option>
                            <option value={'GENERAL'}>GENERAL</option>
                            <option value={'ADMIN'}>ADMIN</option>
                            <option value={'SUPERUSER'}>SUPERUSER</option>
                          </select>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 px-10">
                        <div
                          className={` ${
                            select === 'GAUGE' ? 'bg-gray-500' : 'bg-white'
                          } shadow rounded py-4 px-10 transition-transform transform text-center hover:scale-105`}
                          onClick={(event) => {
                            setSelect('GAUGE'), append({})
                          }}
                        >
                          <i className="fa fa-solid fa-plus text-gray-800 text-center "></i>
                          <p className=" text-gray-800 mb-0 text-center font-bold ">
                            เกจ
                          </p>
                        </div>
                        <div className="flex justify-start gap-1 ">
                          <div
                            className={` ${
                              select === 'CHART' ? 'bg-gray-500' : 'bg-white'
                            } shadow rounded py-4 px-10 transition-transform transform text-center hover:scale-105`}
                            onClick={(event) => {
                              setSelect('CHART'), append({})
                            }}
                          >
                            <i className="fa fa-solid fa-plus text-gray-800 text-center "></i>
                            <p className=" text-gray-800 mb-0 text-center font-bold ">
                              กราฟ
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-start gap-1 ">
                          <div
                            className={` ${
                              select === 'DIAGRAM' ? 'bg-gray-500' : 'bg-white'
                            } shadow rounded py-4 px-10 transition-transform transform text-center hover:scale-105`}
                            onClick={(event) => {
                              setSelect('DIAGRAM'), append({})
                            }}
                          >
                            <i className="fa fa-solid fa-plus text-gray-800 text-center "></i>
                            <p className=" text-gray-800 mb-0 text-center font-bold ">
                              ไดอะแกรม
                            </p>
                          </div>
                        </div>
                      </div>

                      {_.map(fields, (row, index) => (
                        <Box
                          key={row?.id}
                          padding="4"
                          borderRadius="lg"
                          borderWidth="2"
                          className=" text-sm font-semibold border mb-2"
                        >
                          <div className="flex justify-between">
                            <h5 className="mb-2">สมาชิกที่ {index + 1} </h5>
                            <Button
                              colorScheme="red"
                              size="sm"
                              onClick={() => handleRemove(index)}
                            >
                              {t('utility.delete')}
                            </Button>
                          </div>

                          <label>{t('utility.valueType')}</label>
                          <select
                            name={`group.${index}`}
                            required
                            {...register(`group.${index}`)}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            {select === 'GAUGE'
                              ? genGauge()
                              : select === 'CHART'
                              ? genChart()
                              : genDiagram()}
                          </select>
                        </Box>
                      ))}
                    </div>

                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        {t('utility.save')}{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
