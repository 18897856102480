/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link, useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// components
import { Box, Button, Flex, Select } from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import ChartRenderingComponent from '../../components/Graphs/Functions/ChartRendering'
import ChartRenderingComponentSlide from '../../components/Graphs/Functions/ChartRenderingSlide'
import InfiniteScroll from 'react-infinite-scroll-component'

// ! ตอนนี้กราฟ จะ Rander ได้เฉพาะข้อมูลที่เป็น Number ยังไม่สามารถ Rerender จากข้อมูลที่เป็น Boolean ได้
export default function StationGraph({ sidebarStatus, setIsShowSidebar }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [currentStation, setcurrentStation] = useState()
  const [currentStationNew, setcurrentStationNew] = useState()
  const [currentStationChart, setcurrentStationChart] = useState([])
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const dispatch = useDispatch()
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const allLogs = useSelector((state) => state.log)
  const allPredictionLogs = useSelector((state) => state.logPrediction)
  const [graphMin, setGraphMin] = useState(undefined)
  const [graphMax, setGraphMax] = useState(undefined)
  const [tickAmount, setTickAmount] = useState(8)
  const [valueFormat, setValueFormat] = useState('HH:mm')
  const [graph4, setGraph4] = useState('')
  const [selectType, setSelectType] = useState('slide01')

  const me = useSelector((state) => state.me)
  const history = useHistory()

  //console.log('PREDICTION LOGS - ', allPredictionLogs)
  //console.log('REALTIME LOGS - ', allLogs)

  useEffect(() => {
    if (me && me._id && currentStation) {
      if (_.includes(me.duty, 'ALLAREA') || me.duty === currentStation._id) {
      } else {
        console.log('ผู้ใช้ไม่มีสิทธิเข้าใช้')
      }
    }
    return () => {}
  }, [me, currentStation])

  useEffect(() => {
    dispatch(actions.stationAll())
    return () => {}
  }, [])

  const [testArray, setTestArray] = useState(5)

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    //console.log('fetchMoreData working')
    setTimeout(() => {
      setTestArray(testArray + 5)
    }, 2000)
  }

  // ? เราจำเป็นต้อง Fetch ค่าจากทุุกสถานีมาก่อน แล้วค่อยใช้ Use State แบ่งเอา
  // เพราะเราต้องโชว์ใน Sidebar ทุกอัน
  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.stationId
      )
      setcurrentStation(tempcurrentStatus)
      setcurrentStationChart(tempcurrentStatus?.charts.slice(0, testArray))
      console.log('Curent Station', currentStation)
    }
    return () => {}
  }, [params, allStations, testArray])

  // ! Increase Performance โดยการให้ตอนแรกดึงเฉพาะ 20 ค่าล่าสุดมาก่อน
  // จากนั้น ค่อยให้มันดึงข้อมูลทั้งหมด (จากตารางแนวโน้มแต่ละตาราง) มาอีกครั้ง
  useEffect(() => {
    if (currentStation && isFetchNewOne === true) {
      if (isFirstTime === true) {
        //dispatch(actions.logPredictionOnEachStation(currentStation._id))
        dispatch(actions.logLastestTwenty(currentStation._id)).then(() => {
          setIsLoading(true)
          setisFetchNewOne(false)
          dispatch(actions.logByHour(currentStation._id))
          setIsFirstTime(false)
        })
      } else {
        setTickAmount(10)
        setGraphMin(undefined)
        setGraphMax(undefined)
        dispatch(actions.logByHour(currentStation._id))
        setisFetchNewOne(false)
      }
    }
    return () => {}
  }, [isFetchNewOne, currentStation])

  useEffect(() => {
    if (isFetchNewOne === false) {
      setTimeout(() => {
        setisFetchNewOne(true)
      }, 300000)
      //ปรับกราฟใหม่ ทุกๆ 300 วินาที
    }
    return () => {}
  }, [isFetchNewOne])

  const handleEditUserFavorite = (data) => {
    const checkFavorite = _.find(
      me?.favorite?.chart,
      (gauges) => gauges === data?._id
    )
    const arrayData = me?.favorite?.gauge
    console.log('arrayData', arrayData)

    if (!_.isEmpty(checkFavorite)) {
      _.remove(arrayData, (e) => e === data?._id)
      const dataSubmit = {
        ...me,
        favorite: {
          gauge: arrayData,
          chart: me?.favorite?.chart,
        },
      }
      dispatch(actions.userPut(me?._id, dataSubmit))
      dispatch(actions.meGet({}))
      setisFetchNewOne(true)
    } else {
      arrayData.push(data?._id)
      const dataSubmit = {
        ...me,
        favorite: {
          gauge: arrayData,
          chart: me?.favorite?.chart,
        },
      }
      dispatch(actions.userPut(me?._id, dataSubmit))
      dispatch(actions.meGet({}))
      setisFetchNewOne(true)
    }
  }

  const settingsSlide = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    lazyLoad: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: 'linear',
  }
  const handleSelectType = (e) => {
    setSelectType(e.target.value)
  }
  // console.log('currentStation.charts', currentStation?.charts)

  const GroupChart = _.chunk(
    _.orderBy(currentStation?.charts, 'orderLevel', 'asc'),
    4
  )

  return isLoading && currentStation ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans ">
              {t('dashboard.dashboardMonitor')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('dashboard.graph')} {currentStation.name}
            </h2>
            <p className="text-base text-gray-500 mb-1  font-sans ">
              {currentStation.location}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex justify-items-start">
          <Select
            placeholder="รูปแบบการโชว์"
            type="text"
            name="electricType"
            defaultValue={selectType}
            onChange={handleSelectType}
          >
            <option value="slide01">สไลด์โชว์</option>
            <option value="slide02">ไม่สไลด์โชว์</option>
          </Select>
        </div>
        <div className="flex justify-items-end">
          <Button
            colorScheme="blue"
            variant="solid"
            size="sm"
            onClick={() => {
              if (graph4 === 'grid-cols-2') {
                setGraph4('')
              } else {
                setGraph4('grid-cols-2')
              }
            }}
          >
            แสดง 4 กราฟ
          </Button>
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsShowSidebar(!sidebarStatus)}
          >
            {t('dashboard.toggleSidebar')}
          </Button>
        </div>
      </div>

      {selectType === 'slide01' ? (
        <div>
          {graph4 === 'grid-cols-2' ? (
            <Slider {...settingsSlide}>
              {_.map(GroupChart, (chart, index) => {
                return (
                  <div className={`grid sm:${graph4} p-4 w-full `}>
                    <div className={`grid sm:${graph4} p-4 w-full`}>
                      {_.map(chart, (e) => (
                        <div className="">
                          <ChartRenderingComponent
                            key={index}
                            chartData={e}
                            data={allLogs}
                            dataPrediction={allPredictionLogs}
                            graphMax={graphMax}
                            graphMin={graphMin}
                            setGraphMax={setGraphMax}
                            setGraphMin={setGraphMin}
                            setTickAmount={setTickAmount}
                            setValueFormat={setValueFormat}
                            tickAmount={tickAmount}
                            valueFormat={valueFormat}
                            stationData={currentStation}
                            enableTimeSelect={true}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )
              })}
            </Slider>
          ) : (
            <Slider {...settingsSlide}>
              {_.map(
                _.orderBy(currentStation?.charts, 'orderLevel', 'asc'),
                (chart, index) => (
                  <div className="p-4 w-full">
                    <ChartRenderingComponent
                      key={index}
                      chartData={chart}
                      data={allLogs}
                      dataPrediction={allPredictionLogs}
                      graphMax={graphMax}
                      graphMin={graphMin}
                      setGraphMax={setGraphMax}
                      setGraphMin={setGraphMin}
                      setTickAmount={setTickAmount}
                      setValueFormat={setValueFormat}
                      tickAmount={tickAmount}
                      valueFormat={valueFormat}
                      stationData={currentStation}
                      enableTimeSelect={true}
                    />
                  </div>
                )
              )}
            </Slider>
          )}
        </div>
      ) : (
        <div className={`grid sm:${graph4} p-4 w-full`}>
          <InfiniteScroll
            dataLength={currentStationChart?.length}
            next={fetchMoreData}
            hasMore={true}
            loader={<h4>Loading...</h4>}
          >
            {/* {_.map(
              _.orderBy(currentStation.charts, 'orderLevel', 'asc'),
              (chart, index) => (
                <div>{index}</div>
              )
            )} */}
            {_.map(currentStationChart, (chart, index) => (
              <ChartRenderingComponent
                key={index}
                chartData={chart}
                data={allLogs}
                dataPrediction={allPredictionLogs}
                graphMax={graphMax}
                graphMin={graphMin}
                setGraphMax={setGraphMax}
                setGraphMin={setGraphMin}
                setTickAmount={setTickAmount}
                setValueFormat={setValueFormat}
                tickAmount={tickAmount}
                valueFormat={valueFormat}
                stationData={currentStation}
                enableTimeSelect={true}
              />
            ))}
            {/* <ChartRenderingComponent
                  key={index}
                  chartData={chart}
                  data={allLogs}
                  dataPrediction={allPredictionLogs}
                  graphMax={graphMax}
                  graphMin={graphMin}
                  setGraphMax={setGraphMax}
                  setGraphMin={setGraphMin}
                  setTickAmount={setTickAmount}
                  setValueFormat={setValueFormat}
                  tickAmount={tickAmount}
                  valueFormat={valueFormat}
                  stationData={currentStation}
                  enableTimeSelect={true}
                /> */}
          </InfiniteScroll>
        </div>
      )}
    </div>
  ) : (
    <SpinnerLoading />
  )
}
