import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as actions from '../../../../redux/actions'

export default function ArrangementStation() {
  const { t } = useTranslation()
  const stations = useSelector((state) => state.station)
  const [updatedStation, setUpdatedStation] = useState([])
  const dispatch = useDispatch()
  console.log('station:', stations)
  useEffect(() => {
    dispatch(actions.stationAll()).then(() => {})
    return () => {}
  }, [])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  useEffect(() => {
    if (stations?.arr) {
      console.log('Order sensor by order level')
      let updatedStation = _.orderBy(stations?.arr, 'orderLevel', 'asc')
      setUpdatedStation(updatedStation)
    }
  }, [stations?.arr])

  const onBeforeCapture = () => {
    /*...*/
  }

  const onBeforeDragStart = () => {
    /*...*/
  }

  const onDragStart = () => {
    /*...*/
  }
  const onDragUpdate = () => {
    /*...*/
  }
  const onDragEnd = (result) => {
    // the only one that is required
    if (!result.destination && !result.source) {
      console.log('Source Position ')
    } else {
      const dragableId = result.draggableId
      const sourcePosition = result.source?.index
      const destinationPosition = result.destination?.index
      console.log(
        'Source Position ' +
          sourcePosition +
          'Destination Position' +
          destinationPosition
      )

      let reOrderItems = reorder(
        updatedStation,
        sourcePosition,
        destinationPosition
      )
      console.log('Reorder Item', reOrderItems)
      setUpdatedStation(reOrderItems)
    }
  }

  const saveOrdered = () => {
    let confirm = window.confirm('ยืนยันการจัดเรียง')
    if (confirm) {
      let newStationOrder = Array.from(updatedStation)
      _.map(newStationOrder, (station, index) => {
        station.orderLevel = index
      })
      console.log('New Rendered ', newStationOrder)
      let removedOldOrderStation = stations
      delete removedOldOrderStation.arr
      let newStationData = _.merge(removedOldOrderStation, {
        stations: newStationOrder,
      })
      //console.log(newStationData)

      dispatch(
        actions.stationPut(0, {
          arr: _.map(newStationOrder, (eachStation) => ({
            _id: eachStation._id,
            orderLevel: eachStation.orderLevel,
          })),
        })
      ).then(() => {
        dispatch(actions.stationAll())
      })
    }
  }

  const genStationList = (provided, snapshot) => {
    return _.map(updatedStation, (station, index) => (
      <Draggable key={station?._id} draggableId={station?._id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="font-sans"
          >
            <div className="m-2 p-4 border-2 border-gray-200">
              <h3 className="font-semibold">{station?.name}</h3>
            </div>
          </div>
        )}
      </Draggable>
    ))
  }
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              จัดการระบบ
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              จัดการจัดเรียงสถานี
            </h2>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex justify-items-start">
          {' '}
          <Button
            colorScheme="green"
            variant="solid"
            size="sm"
            onClick={() => saveOrdered()}
          >
            {t('utility.saveArrangement')}
          </Button>
        </div>
        <div className="flex justify-items-end"></div>
      </div>
      <div className="p-4 w-full mb-16">
        <DragDropContext
          onBeforeCapture={onBeforeCapture}
          onBeforeDragStart={onBeforeDragStart}
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId="orderDropable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {genStationList(provided, snapshot)}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}
