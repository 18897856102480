const NumericOperation = {
    ADD: 'ADD',
    SUBTRACT: 'SUBTRACT',
    MULTIPLY: 'MULTIPLY',
    DIVIDE: 'DIVIDE',
}

const BooleanOperation = {
    AND: 'AND',
    OR: 'OR',
    NOR: 'NOR',
    NAND: 'NAND',
}

const BooleanNumericOperation = {
    GE: 'GREATER',
    GEQ: 'GREATER OR EQUAL',
    LE: 'LESS THAN',
    LEQ: 'LESS THAN OR EQUAL',
    EQ: 'EQUAL',
    NEQ: 'NOT EQUAL',
}

/** @deprecated since 2021/04/14 */
export { NumericOperation, BooleanNumericOperation, BooleanOperation }

export default { NumericOperation, BooleanNumericOperation, BooleanOperation }
