import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import currencyFormatter from 'currency-formatter'
import _, { each } from 'lodash'
import moment from 'moment'

import FooterReportSigleForm from '../FooterFile/FooterReportSigleForm'
import FooterReportSigleComment from '../FooterFile/FooterReportSigleComment'
import FooterReportWastWater from '../FooterFile/FooterReportWastWater'
import FooterReportQC from '../FooterFile/FooterReportQC'
import FooterReportBiomass from '../FooterFile/FooterReportBiomass'
import FooterReportBlank from '../FooterFile/FooterReportBlank'
import HeaderReportType1 from '../HeaderFile/HeaderReportType1'
import HeaderReportSteam from '../HeaderFile/HeaderReportSteam'
import HeaderReportType2 from '../HeaderFile/HeaderReportType2'
import FooterReportType2 from '../FooterFile/FooterReportType2'
import { auto } from '@popperjs/core'
import dayjs from 'dayjs'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const SteamReport2 = async (form, formInput, system, date) => {
  console.log(date)
  let docDefinition = {
    pageSize: 'A4',

    pageOrientation: 'portrait',
    pageMargins: [10, 90, 10, 190],
    defaultStyle: {
      font: 'Impac',
      bold: true,
      fontSize: '12',
    },

    content: [{ text: 'jjjj', bold: true }],
  }
  pdfMake.createPdf(docDefinition).open()
}
export default SteamReport2
