import React from 'react'
import { Button } from '@chakra-ui/react'

export default function Fallback({ error, resetErrorBoundary }) {
    return (
      <div className="">
        <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                  ระบบเกิดข้อผิดพลาด
                </h6>
                <h2 className="text-gray-800 text-2xl font-bold font-sans ">
                  ขออภัย
                </h2>
              </div>
            </div>
          </div>

          <div className="p-4 w-full">
            <div className=" grid place-content-center place-items-center w-full p-5 ">
              <div className="box-border  border border-gray-500 w-full p-12 text-center  rounded-lg ">
                <h2 className=" text-gray-700 text-xl font-sans font-semibold">
                  หน้าที่คุณต้องการค้นหาอาจเกิดเหตุขัดข้อง
                </h2>
                <p className="text-lg font-sans ">
                  {error?.message}
                </p>
                <Button
                  colorScheme="blue"
                  onClick={resetErrorBoundary}
                >
                  รีโหลด
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
