import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../redux/actions'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@chakra-ui/react'

import SpinnerLoading from '../../../components/Loading/SpinnerLoading'
import StationList from './Components/StationList'

export default function Diagrams() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const stations = useSelector((state) => state.station)

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('setting.systemManagement')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('setting.allStation')}
            </h2>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <Link to="/system/stations/create">
          <Button colorScheme="blue" variant="solid" size="sm">
            {t('utility.add')}
          </Button>
        </Link>
        <Link to={'/system/stations/ArrangementStations'}>
          <Button colorScheme="yellow" variant="solid" size="sm">
            จัดวางสถานี
          </Button>
        </Link>
      </div>
      <div className="p-4 w-full">
        {stations && stations.arr && !_.isEmpty(stations.arr) ? (
          <div>
            <div className="overflow-x-auto">
              <StationList stationArray={stations.arr} />
            </div>
          </div>
        ) : (
          <div>
            <p className=" text-base mb-6">{t('setting.noStation')}</p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
