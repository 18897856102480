import React from 'react'
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  HStack,
  Box,
  Badge,
  Stack,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { Link } from 'react-router-dom'
import exportFormJSON from 'export-from-json'
import { useTranslation } from 'react-i18next'

export default function StationList({ stationArray }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDeleteStation = (stationId) => {
    const confirm = window.confirm('ยืนยันการลบสถานี')
    if (confirm) {
      dispatch(actions.stationDelete(stationId)).then(() => {
        dispatch(actions.stationAll())
      })
    }
  }

  const handleExportJSON = (eachStation) => {
    const jsonData = exportFormJSON({
      data: {
        template: {
          ...eachStation,
          _id: undefined,
          templateName: `${eachStation?.name}-Template`,
          name: undefined,
        },
      },
      fileName: `${eachStation?.name}-Template`,
      exportType: 'json',
    })
    console.log('JSON Data', jsonData)
  }

  return _.map(stationArray, (station, index) => (
    <Box
      borderRadius="lg"
      padding="4"
      className="grid sm:grid-cols-4 lg:grid-cols-4 my-2 bg-white"
    >
      <h5 className="flex sm:col-span-4  lg:col-span-4 text-lg font-bold font-sans">
        {station.name}{' '}
        <Badge colorScheme="teal" size="sm" className="mx-4">
          TOPIC : {station.externalRef}{' '}
        </Badge>{' '}
      </h5>
      <div className="grid sm:col-span-4 lg:col-span-3  ">
        <h4 className="text-base font-sans">{station.location}</h4>
      </div>
      <div className="grid sm:col-span-4 lg:col-span-1">
        <Stack spacing={4} direction="row" align="right">
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => handleExportJSON(station)}
          >
            Export
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => handleDeleteStation(station._id)}
          >
            {t('utility.delete')}
          </Button>
        </Stack>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">
              แก้ไขสถานี
            </p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับสถานี
            </p>
          </div>
        </Link>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/sensors/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">
              {t('setting.sensor')}
            </p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับเซนเซอร์
            </p>
          </div>
        </Link>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/variables/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">
              ตัวแปร
            </p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับตัวแปร
            </p>
          </div>
        </Link>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/equations/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">
              สมการ
            </p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับสมการ
            </p>
          </div>
        </Link>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/group/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">
              กลุ่ม
            </p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับกลุ่ม
            </p>
          </div>
        </Link>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/gauge/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">เกจ</p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับเกจ
            </p>
          </div>
        </Link>
      </div>{' '}
      <div className="my-2  px-2">
        <Link to={'/system/stations/charts/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">กราฟ</p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับกราฟ
            </p>
          </div>
        </Link>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/diagram/' + station._id}>
          <div className="grid grid-cols-4  bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">
              ไดอะแกรม
            </p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับไดอะแกรม
            </p>
          </div>
        </Link>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/form/management/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">
              ฟอร์ม
            </p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับฟอร์ม
            </p>
          </div>
        </Link>
      </div>
      <div className="my-2  px-2">
        <Link to={'/system/stations/form/management/' + station._id}>
          <div className="grid grid-cols-4 bg-gray-500 shadow rounded py-2 transition-transform transform hover:scale-105">
            <i className="fa fa-solid fa-info fa-xl text-white text-center mt-4"></i>
            <p className="col-span-3 text-white mb-0 font-bold  text-l">
              ควบคุม
            </p>
            <p></p>
            <p className="col-span-3 text-white mb-0 text-left text-sm">
              สำหรับแก้ไขข้อมูลเกี่ยวกับควบคุม
            </p>
          </div>
        </Link>
      </div>
      {/* <Link to={'/system/stations/predictions/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                การทำนาย
              </Button>
            </Link> */}
      {/* <Link to={'/system/stations/arrange/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                จัดเรียงเกจ
              </Button>
            </Link> */}
      {/* <Link to={'/system/stations/charts/arrange/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                จัดเรียงกราฟ
              </Button>
            </Link> */}
      {/* <Link to={'/system/stations/diagram/arrange/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                จัดเรียงไดอะแกรม
              </Button>
            </Link> */}
    </Box>
  ))
}
