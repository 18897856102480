import React from 'react'
import _ from 'lodash'

import Config from '../../../../config'
import VariableMainRendering from '../../../Variables/Function/VariableMainRendering'

export default function DisplayStringAttribute({ stationData, attributeData }) {
  if (attributeData.dataSource === Config.TypeOfSource.GaugeSource.sensor) {
    const sensorSourceData = _.find(
      stationData?.sensors,
      (sensor) => sensor.refString === attributeData.sensorRef
    )
    return (
      <div className="text-sm">
        {attributeData?.label}
        {' : '}
        {sensorSourceData?.stringStatus}
      </div>
    )
  } else {
    const variableData = VariableMainRendering(
      stationData,
      attributeData?.variableId
    )
    return (
      <div className="text-sm">
        {attributeData?.label}
        {' : '}
        {variableData}
      </div>
    )
  }
}
