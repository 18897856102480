/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import {
  FormControl,
  Input,
  Select,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Button,
  Badge,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import 'katex/dist/katex.min.css'
import Latex from 'react-latex'

import * as actions from '../../../redux/actions'

import InputFormVariableType from '../Function/InputFormVariableType'
import { DataType } from '../../../config/DataType'

export default function VariableMakingComponent({
  index,
  stationData,
  variableData,
  setIsEditorMode,
}) {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: variableData,
  })

  const dispatch = useDispatch()
  const [showEquation, setShowEquation] = useState(variableData.latex)
  const [payload, setPayload] = useState({})
  const [isVariableData, setIsVariableData] = useState('')

  useEffect(() => {
    const eq = _.filter(stationData.equations, (member) => {
      return member.latex === showEquation
    })
    console.log('Show ', eq)
    if (_.size(eq) !== 0) {
      setPayload(...eq)
      let tempSym = eq[0].symbol
      console.log('ERROR: ', tempSym)
      setIsVariableData(tempSym)
    }
  }, [showEquation])

  //console.log('Watch Variable Data', watch())

  const getSelectInputOfVariable = (inputData, index, symbol) => {
    if (inputData === 'CONST' || variableData?.inputData[index] === 'CONST') {
      return (
        <Input
          defaultValue={
            variableData.value !== undefined || variableData.value !== ''
              ? Object.values(variableData.value)[index]
              : '0'
          }
          ref={register}
          key={index}
          name={`value[${index}.${symbol}]`}
          type="number"
        />
      )
    } else if (inputData === 'SENSOR') {
      return (
        <Select
          defaultValue={
            variableData.valueDetail[
              Object.keys(variableData.valueDetail)[index]
            ]
          }
          defaultChecked
          key={index}
          size="sm"
          {...register(`value[${index}.${symbol}]`)}
          ref={register}
          name={`value[${index}.${symbol}]`}
        >
          {_.map(stationData.sensors, (sensor, key) => (
            <option key={index} value={sensor.refString}>
              {sensor.name}
            </option>
          ))}
        </Select>
      )
    } else if (inputData === 'VARIABLE') {
      return (
        <Select
          defaultValue={
            variableData.valueDetail[
              Object.keys(variableData.valueDetail)[index]
            ]
          }
          key={index}
          size="sm"
          {...register(`value[${index}.${symbol}]`)}
          name={`value[${index}.${symbol}]`}
        >
          {_.map(stationData.variables, (variable) => {
            return (
              <option key={index} value={variable?.variableName}>
                {variable.variableName}
              </option>
            )
          })}
        </Select>
      )
    } else if (inputData === 'HUMANINPUT') {
      return (
        <InputFormVariableType
          stationData={stationData}
          register={register}
          index={index}
          symbol={symbol}
          variableData={variableData}
        />
      )
    } else if (inputData === 'FUNCTION') {
      return (
        <Input
          defaultValue={
            variableData.valueDetail !== undefined ||
            variableData.valueDetail !== ''
              ? Object.values(variableData.valueDetail)[index]
              : ''
          }
          {...register(`value[${index}.${symbol}]`)}
          key={index}
          name={`value[${index}.${symbol}]`}
          type="text"
        />
      )
    }
  }

  //console.log('station', stationData)

  // Merged the Sensor Data and Make new Station Data to Database
  const prepareUpdateDatabase = (data) => {
    console.log('Onsubmit Data: ', data)
    let prepareValue = {}
    _.map(data.value, (e) => {
      _.merge(prepareValue, e)
    })
    console.log('Prepare value', prepareValue)
    data.value = prepareValue
    data.valueDetail = prepareValue
    const selectedVariable = stationData.variables[index]
    // console.log('Variable Data: ', data)
    data = _.merge(data, payload)
    const selectedVariableIndex = index
    const mergedvariableData = _.merge(selectedVariable, data)
    console.log('Payload : ', mergedvariableData)
    let editedStation = stationData
    editedStation.variables[selectedVariableIndex] = mergedvariableData
    dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
      dispatch(actions.stationAll())
    })
    setIsEditorMode(false)
  }

  return (
    <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
      <div className="w-full flex">
        <div className="w-5/6">
          <h5 className="text-lg font-bold font-sans">
            {variableData.variableName}{' '}
            <Badge colorScheme="teal" size="sm">
              {variableData.wantedDataType}{' '}
            </Badge>{' '}
          </h5>
        </div>
        <div className="w-1/6">
          <Button
            size="sm"
            type="button"
            colorScheme="gray"
            onClick={() => setIsEditorMode(false)}
          >
            {t('utility.cancel')}
          </Button>{' '}
          <Button size="sm" colorScheme="green" type="submit">
            {t('utility.save')}
          </Button>
        </div>
      </div>
      <FormControl>
        <label className="text-sm font-bold"> {t('variable.name')}</label>
        <Input
          type="text"
          name="variableName"
          size="sm"
          key={index}
          defaultValue={variableData.variableName}
          {...register('variableName')}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold"> {t('variable.type')}</label>
        <Select
          defaultValue={variableData.wantedDataType}
          size="sm"
          key={index}
          {...register('wantedDataType')}
          name="wantedDataType"
        >
          <option value="">{t('variable.typeSelect')}</option>
          <option value={DataType.boolean}>Boolean</option>
          <option value={DataType.number}>Number</option>
        </Select>
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold"> {'เลือกสมการ'}</label>
        <Select
          defaultValue={variableData.latex}
          key={index}
          size="sm"
          {...register('latex')}
          name="latex"
          placeholder="เลือกสมการที่ต้องการใช้"
          onChange={(event) => setShowEquation(event.target.value)}
        >
          {_.map(stationData.equations, (equation, index) => {
            return (
              <option key={index} value={equation.latex}>
                {equation.name}
              </option>
            )
          })}
        </Select>
      </FormControl>
      <div className="flex justify-center mx-2 my-2 text-lg font-bold mb-2 bg-gray-100 shadow-md rounded-md">
        {showEquation !== undefined || showEquation !== '' ? (
          <Latex displayMode={true}>{'$$ f(x) =' + showEquation + '$$'}</Latex>
        ) : (
          <Latex displayMode={true}>{'$$ f(x) = $$'}</Latex>
        )}
      </div>
      <br />
      {/* ส่วนของการกำหนดตัวแปร */}
      <div className="">
        <div className="w-full">
          <label className="text-sm font-bold py-2">
            {t('variable.subVariableList')}{' '}
          </label>
          <Table>
            <Thead>
              <Tr>
                <Th>{t('variable.subName')}</Th>
                <Th>{t('sensor.dataSource')}</Th>
                <Th>{t('variable.value')}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {_.map(isVariableData, (symbol, index) => {
                return (
                  <Tr className="text-sm" key={index}>
                    <Td>
                      <Latex>{'$$' + symbol + ' : $$'}</Latex>{' '}
                    </Td>
                    <Td>
                      <Select
                        defaultValue={variableData.inputData[index]}
                        key={index}
                        size="sm"
                        {...register(`inputData[${index}]`)}
                        name={`inputData[${index}]`}
                        placeholder={'เลือกที่มาของข้อมูล'}
                      >
                        <option value={'SENSOR'}>เซนเซอร์</option>
                        <option value={'VARIABLE'}>ตัวแปร</option>
                        <option value={'HUMANINPUT'}>การป้อนข้อมูล</option>
                        <option value={'CONST'}>ค่าคงที่</option>
                        <option value={'FUNCTION'}>ฟังก์ชัน</option>
                      </Select>
                    </Td>
                    <Td>
                      {watch(`inputData[${index}]`) &&
                        getSelectInputOfVariable(
                          watch(`inputData[${index}]`),
                          index,
                          symbol
                        )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </div>
        <br />
      </div>
    </form>
  )
}
