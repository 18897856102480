/* eslint-disable consistent-return */
/* eslint-disable curly */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _, { each } from 'lodash'
import {
  Button,
  FormControl,
  FormLabel,
  Switch,
  Select,
  Checkbox,
  Input,
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { InlineMath, BlockMath } from 'react-katex'
import 'katex/dist/katex.min.css'
import HumenInputType from './HumenInputType'
import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import { id } from 'date-fns/locale'

export default function addPrediction() {
  const { t, i18n } = useTranslation()
  const { watch, register, handleSubmit, reset } = useForm()
  const [sensorType, setsensorType] = useState('BOOLEAN')
  const [isLoading, setIsLoading] = useState(false)
  const [selectStation, setSelectStation] = useState()
  const [selectSensor, setSelectSensor] = useState()
  const [selectVariable, setSelectVariable] = useState()
  const [isAllowEfficiency, setIsAllowEfficiency] = useState()
  const [fixedYAxis, setFixedYAxis] = useState()
  const params = useParams()
  const station = useSelector((state) => state.station)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => setIsLoading(true))
    return () => {}
  }, [params])

  const findStation = (id) => {
    const data = _?.find(station?.arr, (each) => each?._id === id)
    setSelectStation(data)
  }

  const findType = (id) => {
    if (id === 'SENSOR') {
      const data = _?.find(selectStation?.sensors, (each) => each?._id === id)
      console.log('selectsensor', data)
      console.log('station?.sensors', station?.sensors)
      setSelectSensor(data)
    } else if (id === 'VARIABLE') {
      const data = _?.find(selectStation?.variables, (each) => each?._id === id)
      console.log('selectvariable', data)
      console.log('station?.variable', station?.variables)
      setSelectVariable(data)
    }
  }

  const selectType = (id) => {
    console.log('id', id)
    if (id === 'SENSOR') {
      return _.map(station.arr, (st, key) => {
        if (st._id === selectStation?._id) {
          return _.map(st.sensors, (sensor, key) => {
            return (
              <option
                value={
                  sensor?._id + '/' + sensor?.name + '/' + sensor?.refString
                }
              >
                {sensor.name}
              </option>
            )
          })
        }
      })
    } else if (id === 'VARIABLE') {
      return _.map(station.arr, (st, key) => {
        if (st._id === selectStation?._id) {
          return _.map(st.variables, (variable, key) => {
            return (
              <option
                value={
                  variable?._id +
                  '/' +
                  variable?.name +
                  '/' +
                  variable?.variableName
                }
              >
                {variable.variableName}
              </option>
            )
          })
        }
      })
    } else if (id === 'HUMANINPUT') {
      return <HumenInputType stationData={selectStation} register={register} />
    }
  }

  const onSubmit = async (data, e) => {
    console.log('Sourcessss', data.typeSource)
    console.log('Data', data)
    let payload = {}
    let confirm = window.confirm('ยืนยันการเพิ่มการทำนาย')
    if (data.typeSource === 'SENSOR') {
      const NewSensor = data?.source.split('/')
      console.log('New Sensor', NewSensor)
      payload = {
        predictionName: NewSensor[1],
        stationName: selectStation?.name,
        sourceName: NewSensor[1],
        sourceId: NewSensor[0],
        sourceRef: NewSensor[2],
        ...data,
      }
    } else if (data.typeSource === 'VARIABLE') {
      const NewVar = data?.source.split('/')
      payload = {
        predictionName: NewVar[1],
        stationName: selectStation?.name,
        sourceName: NewVar[1],
        sourceId: NewVar[0],
        sourceRef: NewVar[2],
        ...data,
      }
      //console.log('Pay load variable', payload)
    } else if (data.typeSource === 'HUMANINPUT') {
      const NewInput = data?.source.split('/')
      payload = {
        stationName: selectStation?.name,
        sourceName: NewInput[1],
        sourceId: NewInput[0],
        sourceRef: data.source,
        ...data,
      }
    }

    if (confirm) {
      console.log('Confirm Post', payload)
      dispatch(actions.predictionPost(payload))
        .then(() => {
          history.goBack()
        })
        .catch((err) => {
          alert(err?.message)
        })
    }
  }

  if (station && isLoading) {
    return (
      <div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      {'เพิ่มตัวแปรที่ต้องการทำนาย'}
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      {'การทำนาย'}
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกสถานีที่ต้องการทำนาย'}
                          </label>
                          <Select
                            {...register('station')}
                            size="sm"
                            name="station"
                            placeholder="เลือกสถานี"
                            onChange={(e) => findStation(e?.target?.value)}
                          >
                            {_.map(station?.arr, (name, key) => {
                              return (
                                <option value={name?._id}>{name.name}</option>
                              )
                            })}
                          </Select>
                        </div>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกที่มาของข้อมูล'}
                          </label>
                          <Select
                            {...register('typeSource')}
                            size="sm"
                            name="typeSource"
                            placeholder="เลือกที่มาข้อมูล"
                          >
                            <option value="SENSOR">เซนเซอร์</option>
                            <option value="VARIABLE">ตัวแปร</option>
                            <option value="HUMANINPUT">การกรอกข้อมูล</option>
                          </Select>
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เลือกข้อมูล'}
                          </label>
                          <Select
                            {...register('source')}
                            size="sm"
                            name="source"
                            placeholder="เลือกข้อมูล"
                            onChange={(e) => findType(e?.target?.value)}
                          >
                            {/* ใช้ตัวนี้เป็นการรับ Source ว่าเป็น Sensor หรือ Varible หรือ การกรอกข้อมูล */}
                            {selectType(watch('typeSource'))}
                          </Select>
                        </div>
                      </div>

                      <FormControl>
                        <Checkbox
                          name="fixedYAxis.enable"
                          {...register('fixedYAxis.enable')}
                          onChange={(event) =>
                            setFixedYAxis(event.target.checked)
                          }
                        >
                          ตั้งค่าแกน Y เป็นค่าคงที่
                        </Checkbox>
                      </FormControl>
                      {fixedYAxis && (
                        <div>
                          <label className="text-sm font-bold">
                            เทรชโฮลด์บน
                          </label>
                          <Input
                            size="sm"
                            type="text"
                            name="fixedYAxis.upper"
                            {...register('fixedYAxis.upper')}
                          />
                          <label className="text-sm font-bold">
                            เทรชโฮลด์ล่าง
                          </label>
                          <Input
                            size="sm"
                            type="text"
                            name="fixedYAxis.lower"
                            {...register('fixedYAxis.lower')}
                          />
                        </div>
                      )}

                      <FormControl>
                        <Checkbox
                          name="efficiency.enable"
                          {...register('efficiency.enable')}
                          onChange={(event) =>
                            setIsAllowEfficiency(event.target.checked)
                          }
                        >
                          แสดงเส้นสำหรับ Efficiency
                        </Checkbox>
                      </FormControl>
                      {isAllowEfficiency && (
                        <div>
                          <label className="text-sm font-bold">
                            เทรชโฮลด์บน
                          </label>
                          <Input
                            size="sm"
                            type="text"
                            name="efficiency.upper"
                            {...register('efficiency.upper')}
                          />
                          <label className="text-sm font-bold">
                            เทรชโฮลด์ล่าง
                          </label>
                          <Input
                            size="sm"
                            type="text"
                            name="efficiency.lower"
                            {...register('efficiency.lower')}
                          />
                        </div>
                      )}
                    </div>

                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        {t('utility.save')}{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
