import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  FormControl,
  Input,
  Select,
  Switch,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import * as actions from '../../../../redux/actions'
import Config from '../../../../config'

export default function GroupEdit({ group }) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  //   const [selectedGagueType, setSelectedGagueType] = useState(
  //     gaugeData?.gaugeType
  //   )
  //   const [sensorType, setSensorType] = useState(gaugeData?.sensorType)
  //   const [gaugeSource, setGaugeSource] = useState(gaugeData?.gaugeSource)
  const [additionalInfo, setAdditionalInfo] = useState()
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()

  // Merged the Sensor Data and Make new Station Data to Database
  const prepareUpdateDatabase = (data) => {
    // console.log('PrepareUpdateGague ----------', data)
    // const selectedGauge = _.find(
    //   stationData.gauges,
    //   (gague) => gague?._id === gaugeData._id
    // )
    // const selectedGaugeIndex = _.findIndex(
    //   stationData.gauges,
    //   (gauge) => gauge?._id === gaugeData._id
    // )
    // console.log('Additional info', additionalInfo)
    // const mergedgaugeData = _.merge(selectedGauge, data, additionalInfo)
    // const editedStation = stationData
    // editedStation.gauges[selectedGaugeIndex] = mergedgaugeData
    // //console.log('This is Station')
    // //console.log('Data', data)
    // dispatch(actions.stationPut(stationData?._id, editedStation)).then(() => {
    //   dispatch(actions.stationAll())
    // })
    // setIsEditorMode(false)
  }

  //   const handleChangeSwitch = async (data) => {
  //     console.log('enableHidden ----------', data)
  //     const selectedGauge = _.find(
  //       stationData.gauges,
  //       (gague) => gague?._id === gaugeData._id
  //     )
  //     const selectedGaugeIndex = _.findIndex(
  //       stationData.gauges,
  //       (gauge) => gauge?._id === gaugeData._id
  //     )
  //     //console.log('Additional info', additionalInfo)
  //     const payload = { hiddenEnable: !data }
  //     const mergedgaugeData = _.merge(selectedGauge, payload, additionalInfo)
  //     const editedStation = stationData
  //     editedStation.gauges[selectedGaugeIndex] = mergedgaugeData

  //     dispatch(actions.stationPut(stationData?._id, editedStation)).then(() => {
  //       dispatch(actions.stationAll())
  //     })
  //     setIsEditorMode(false)
  //   }

  //   const prepateDeleteSensor = (gaugeId) => {
  //     const confirm = window.confirm('ยืนยันการลบเกจ')
  //     if (confirm) {
  //       const selectedSensorIndex = _.findIndex(
  //         stationData?.gauges,
  //         (gauge) => gauge?._id === gaugeId
  //       )
  //       const editedStation = stationData
  //       if (editedStation?.gauges) {
  //         console.log('Original Sensor Stack', editedStation?.gauges)
  //         editedStation.gauges.splice(selectedSensorIndex, 1)
  //         console.log('SPLICED')
  //         console.log('New Sensor Stack', editedStation?.gauges)
  //       }
  //       console.log('This is Station')
  //       dispatch(actions.stationPut(stationData?._id, editedStation)).then(() => {
  //         dispatch(actions.stationAll())
  //       })
  //       setIsEditorMode(false)
  //     }
  //   }

  return (
    <Box
      borderRadius="lg"
      padding="4"
      className="bg-white my-2 overflow-x-auto"
    >
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {group?.groupName}
              <Badge colorScheme="teal" size="sm">
                {/* {gaugeData?.sensorType} */}
              </Badge>
            </h5>
          </div>

          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <div>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsEditorMode(true)}
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepateDeleteSensor(group._id)}
                >
                  {t('utility.delete')}
                </Button>

                {/* <Switch
                  size="lg"
                  defaultChecked={!gaugeData?.hiddenEnable}
                  onChange={(e) => {
                    handleChangeSwitch(e.target.checked)
                  }}
                /> */}
              </div>
            )}
          </div>
        </div>

        <div className="flex gap-3">
          <div className="w-full lg:w-1/3 font-sans mx-4"></div>
          <div className="w-full lg:w-2/3">
            {isEditorMode === true ? (
              <>
                <FormControl>
                  <label className="text-sm font-bold">
                    {t('sensor.gaugeName')}
                  </label>
                  <Input
                    type="text"
                    name="gaugeLabel"
                    size="sm"
                    // defaultValue={gaugeData.gaugeLabel}
                    {...register('gaugeLabel')}
                  />
                </FormControl>

                <FormControl>
                  <label className="text-sm font-bold">
                    {t('graph.displayType')}
                  </label>
                  <Select
                    defaultValue={gaugeData?.sensorType}
                    {...register('sensorType')}
                    size="sm"
                    name="sensorType"
                    onChange={(event) => setSensorType(event.target.value)}
                  >
                    <option value="">{t('graph.displayTypeSelect')}</option>
                    <option value={Config.DataType.DataType.boolean}>
                      Boolean
                    </option>
                    <option value={Config.DataType.DataType.number}>
                      Number
                    </option>
                    <option value={Config.DataType.DataType.string}>
                      String
                    </option>
                  </Select>
                </FormControl>

                <FormControl>
                  <label className="text-sm font-bold">
                    {t('sensor.gaugeType')}
                  </label>
                  <Select
                    defaultValue={gaugeData?.gaugeType}
                    {...register('gaugeType')}
                    size="sm"
                    name="gaugeType"
                    onChange={(event) => {
                      setSelectedGagueType(event.target.value)
                    }}
                  >
                    <option value={Config.ComponentType.GaugeType.NoDisplay}>
                      {t('sensor.noDisplay')}
                    </option>
                    <option
                      value={Config.ComponentType.GaugeType.StaticBoolean}
                    >
                      {t('sensor.displayBoolean')}
                    </option>
                    <option
                      value={Config.ComponentType.GaugeType.BooleanWaterDisplay}
                    >
                      {t('sensor.displayBooleanWater')}
                    </option>
                    <option value={Config.ComponentType.GaugeType.StaticMeter}>
                      {t('sensor.displayMeter')}
                    </option>
                    <option
                      value={Config.ComponentType.GaugeType.StaticMeterClassic}
                    >
                      {t('sensor.displayClassicMeter')}
                    </option>
                    <option
                      value={Config.ComponentType.GaugeType.StaticMeterColors}
                    >
                      {t('sensor.displayColorMeter')}
                    </option>
                    <option value={Config.ComponentType.GaugeType.StaticNumber}>
                      {t('sensor.displayNumber')}
                    </option>
                    <option
                      value={Config.ComponentType.GaugeType.CountingNumber}
                    >
                      {t('sensor.displayNumberCounter')}
                    </option>
                    <option value={Config.ComponentType.GaugeType.StaticString}>
                      {t('sensor.displayImage')}
                    </option>
                    <option
                      value={
                        Config.ComponentType.GaugeType
                          .MultiStepBooleanWaterDisplay
                      }
                    >
                      {t('sensor.displayBooleanWaterTwo')}
                    </option>
                  </Select>
                </FormControl>

                <FormControl>
                  <label className="text-sm font-bold">
                    {t('sensor.dataSource')}
                  </label>
                  <Select
                    defaultValue={gaugeData?.gaugeSource}
                    {...register('gaugeSource')}
                    size="sm"
                    name="gaugeSource"
                    onChange={(event) => setGaugeSource(event.target.value)}
                  >
                    <option value="">{t('sensor.dataSourceSelect')}</option>
                    <option value={Config.TypeOfSource.GaugeSource.sensor}>
                      {t('setting.sensor')}
                    </option>
                    <option value={Config.TypeOfSource.GaugeSource.variable}>
                      {t('setting.variable')}
                    </option>
                  </Select>
                </FormControl>

                {/* <EditingGeneralGagueSelector
                  gaugeData={gaugeData}
                  register={register}
                  gaugeType={selectedGagueType}
                  stationData={stationData}
                  setAdditionalInfo={setAdditionalInfo}
                  sensorType={sensorType}
                  gaugeSource={gaugeSource}
                /> */}
              </>
            ) : (
              <>
                <p className="text-sm ">
                  {' '}
                  <span className="text-sm font-semibold text-gray-700">
                    {t('sensor.gaugeName')}
                  </span>{' '}
                  {group?.groupName}{' '}
                </p>
                <ul>
                  <li>
                    <span className="text-sm font-semibold text-gray-700">
                      {t('sensor.sensorType')} :{' '}
                    </span>
                    <span className="text-sm font-semibold text-blue-700">
                      {/* {gaugeData?.sensorType} */}
                    </span>
                  </li>

                  <li>
                    <span className="text-sm font-semibold text-gray-700">
                      {t('sensor.gaugeType')} :{' '}
                    </span>
                    <span className="text-sm font-semibold text-blue-700">
                      {/* {gaugeData?.gaugeType} */}
                    </span>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </form>
    </Box>
  )
}
