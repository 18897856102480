import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import GeneralFormSelector from './GeneralFormSelector'
import _ from 'lodash'
import { Box, Button } from '@mui/material'

import GeneralEditFormSelector from './GeneralEditFormSelector'

export default function GeneratEditForm({ form, formInput, handleEdit }) {
  const { register, control, setValue, watch, handleSubmit } = useForm({
    defaultValues: formInput,
  })
  //const [date, setDate] = useState(new Date())
  //console.log('date ', formInput)

  const renderForm = () =>
    _.map(form?.components, (formData, index) => (
      <div key={index}>
        <GeneralEditFormSelector
          formData={formData}
          watch={watch}
          formInput={formInput}
          register={register}
        />
      </div>
    ))

  return (
    <div>
      <form onSubmit={handleSubmit(handleEdit)}>
        <div className="md:grid lg:grid-cols-4 md:grid-cols-2">
          {renderForm()}
        </div>

        <div className="flex justify-center py-2 ">
          <Button type="submit" variant="contained">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  )
}
