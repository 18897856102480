import {
  FORM_INPUT_ALL,
  FORM_INPUT_GET,
  FORM_INPUT_PUT,
  FORM_INPUT_DEL,
  FORM_INPUT_POST,
  FORM_INPUT_LOADING,
  FORM_INPUT_ERROR,
} from '../../actions/types'

const initialState = {
  formInput: null,
  isLoading: true,
  isCompleted: true,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case FORM_INPUT_ALL:
      return {
        arr: [...action.payload],
        isLoading: false,
        isCompleted: true,
      }
    case FORM_INPUT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      }
    case FORM_INPUT_POST:
      return { isLoading: false, isCompleted: true }
    case FORM_INPUT_PUT:
      return { isLoading: false, isCompleted: true }
    case FORM_INPUT_DEL:
      return { isLoading: false, isCompleted: true }
    case FORM_INPUT_LOADING:
      return { isLoading: true, isCompleted: false }
    case FORM_INPUT_ERROR:
      return { isLoading: false, isCompleted: false }
    default:
      return state
  }
}
