import React, { useState, useEffect } from 'react'
import { Badge, Box, Button } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import GeneralFormSelector from './GeneralFormSelector'

import * as actions from '../../redux/actions'
import EditMultiinputForm from './StationForm/EditMultiinputForm'

export default function FormComponent({
  componentData,
  selectedForm,
  subForm,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [inputType, setInputType] = useState('')
  const [isEditorMode, setIsEditorMode] = useState(false)
  const [inputSensor, setInputSensor] = useState(false)
  const { register, handleSubmit, watch, setValue, control } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attributeArray',
  })

  useEffect(() => {
    setValue('attributeArray', componentData?.attribute)
    setValue('name', componentData?.name)
    // dispatch(actions)

    return () => {}
  }, [componentData])
  // Merged the Sensor Data and Make new Station Data to Database
  const prepareUpdateDatabase = async (data) => {
    // console.log('data', data)
    // console.log('watch', watch('attributeArray'))

    const datasubmit = {
      ...componentData,
      ...data,
      attribute: data?.attributeArray,
      _id: componentData?._id,
    }
    console.log('data', datasubmit)
    const foundedCompoenent = _.find(
      selectedForm?.components,
      (eachComponent) => eachComponent?._id === componentData?._id
    )
    const foundedCompoenentIndex = _.findIndex(
      selectedForm?.components,
      (eachComponent) => eachComponent?._id === componentData?._id
    )

    // const findIndexAttribute

    const mergedFormComponentData = _.merge(foundedCompoenent, datasubmit)
    console.log('merged', mergedFormComponentData)

    selectedForm.components[foundedCompoenentIndex] = datasubmit
    // console.log('selectedForm', selectedForm)
    if (subForm) {
      await dispatch(actions.subFormPut(selectedForm._id, selectedForm))
      await dispatch(actions.subFormGet(selectedForm._id))
    }
    await dispatch(actions.formPut(selectedForm._id, selectedForm))
    await dispatch(actions.formGet(selectedForm._id))

    setIsEditorMode(false)
  }

  const prepateDeleteForm = async () => {
    const confirm = window.confirm('ยืนยันการลบฟอร์มย่อย')

    if (confirm) {
      const foundedCompoenent = _.find(
        selectedForm?.components,
        (eachComponent) => eachComponent?._id === componentData?._id
      )
      const foundedCompoenentIndex = _.findIndex(
        selectedForm?.components,
        (eachComponent) => eachComponent?._id === componentData?._id
      )

      const editedForm = selectedForm
      if (editedForm.components) {
        editedForm.components.splice(foundedCompoenentIndex, 1)
        console.log('editedForm', editedForm)
        if (subForm) {
          await dispatch(actions.subFormPut(selectedForm._id, editedForm))
          await dispatch(actions.subFormGet(selectedForm._id))
        }
        await dispatch(actions.formPut(selectedForm._id, editedForm))
        await dispatch(actions.formGet(selectedForm._id))
      }
    }
  }

  return (
    <Box
      borderRadius="lg"
      padding="4"
      className="bg-white my-2 overflow-x-auto"
    >
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {' '}
              {componentData?.name}{' '}
              <Badge colorScheme="teal" size="sm">
                {componentData?.formType}
              </Badge>{' '}
            </h5>
          </div>

          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <div>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>{' '}
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsEditorMode(true)}
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepateDeleteForm()}
                >
                  {t('utility.delete')}
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="flex gap-3">
          <div className="w-full lg:w-2/3 font-sans mx-4">
            <GeneralFormSelector
              formData={componentData}
              //stationData={stationData}
              register={register}
              staticValue={0}
              inUsed={false}
              watch={watch}
            />
          </div>
          <div className="w-full lg:w-1/3">
            {isEditorMode === true ? (
              <div>
                {componentData?.formType === 'SINGLE_INPUT' && (
                  <div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        ชื่อฟอร์ม
                      </label>
                      <input
                        name="name"
                        type="text"
                        defaultValue={componentData?.name}
                        required
                        {...register('name')}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                        placeholder="Meter"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        ชื่อหัวข้อ
                      </label>
                      <input
                        name="topic"
                        type="text"
                        required
                        defaultValue={componentData?.topic}
                        {...register('topic')}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                        placeholder="มิเตอร์"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        คำอธิบาย
                      </label>
                      <input
                        name="description"
                        type="text"
                        {...register('description')}
                        defaultValue={componentData?.description}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                        placeholder="ใช้สำหรับมิเตอร์"
                      />
                    </div>

                    <div className="relative w-full  mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        ชนิดของอินพุต
                      </label>
                      <select
                        name="inputType"
                        required
                        {...register('inputType')}
                        defaultValue={componentData?.inputType}
                        onChange={(event) => setInputType(event.target.value)}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                      >
                        <option value="DATE">Date</option>
                        <option value="TIME">Time</option>
                        <option value="NUMBER">Number</option>
                        <option value="TEXT">Text</option>
                        <option value="USERNAME">Username</option>
                      </select>
                    </div>
                    <div className="relative w-full  mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Required
                      </label>
                      <select
                        name="required"
                        {...register('required')}
                        defaultValue={componentData?.required}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                      >
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    </div>
                  </div>
                )}
                {componentData?.formType === 'CHECKBOX' && (
                  <EditMultiinputForm
                    fields={fields}
                    append={append}
                    remove={remove}
                    register={register}
                    control={control}
                    inputSensor={inputSensor}
                    setInputSensor={setInputSensor}
                    componentData={componentData}
                  />
                )}
                {componentData?.formType === 'SELECTOR' && (
                  <EditMultiinputForm
                    fields={fields}
                    append={append}
                    remove={remove}
                    register={register}
                    control={control}
                    componentData={componentData}
                  />
                )}
                {componentData?.formType === 'MULTIPLE_INPUT' && (
                  <EditMultiinputForm
                    fields={fields}
                    append={append}
                    remove={remove}
                    register={register}
                    Multiinput={true}
                    control={control}
                    componentData={componentData}
                  />
                )}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </form>
    </Box>
  )
}
