import { DIAGRAM_PUT } from '../../actions/types'

const initialState = {
  diagram: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case DIAGRAM_PUT:
      return action.payload
    default:
      return state
  }
}
