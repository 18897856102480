import React, { useState, useEffect } from 'react'
import {
  Badge,
  Button,
  Code,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  FormControl,
  Input,
} from '@chakra-ui/react'
import _ from 'lodash'
import GenerateMathermaticSymbol from '../../../../../util/GenerateMathematicSymbol'
import VariableAddingComponent from '../BackwardCompatible/Components/VariableAddingComponent'
import OperationListAddingComponent from '../BackwardCompatible/Components/OperationListAddingComponent'

export default function EditMultiStepNumberDisplay({
  gaugeData,
  register,
  stationData,
  setAdditionalInfo,
  sensorType,
}) {
  const originalVariableSize = _.size(gaugeData.multiLevelVariables)
  console.log('Former Variable Size' + originalVariableSize)

  //State Setting
  const [rendered, setRendered] = useState(false)
  const [gaugeVariables, setGagueVariables] = useState(
    gaugeData.multiLevelVariables
  )
  const [operationList, setOperationList] = useState(
    gaugeData.multiLevelOperations
  )

  const handleDeleteOperator = (operatorIndex) => {
    let tempOperatorArray = operationList
    tempOperatorArray.splice(operatorIndex, 1)
    setOperationList(tempOperatorArray)
    setRendered(!rendered)
  }

  const handleDeleteVariable = (variableIndex) => {
    console.log('Delete Variable Index', variableIndex)
    let tempArrayVariables = gaugeVariables
    tempArrayVariables.splice(variableIndex, 1)
    console.log('Deleted Array', tempArrayVariables)
    setGagueVariables(tempArrayVariables)
    setRendered(!rendered)
  }

  useEffect(() => {
    const tempAdditonalInfo = {
      multiLevelVariables: gaugeVariables,
      multiLevelOperations: operationList,
    }
    setAdditionalInfo(tempAdditonalInfo)
    return () => {}
  }, [gaugeVariables, operationList])

  return (
    <div>
      <div>
        <FormControl>
          <label className="text-sm font-bold">หน่วย</label>
          <Input
            type="text"
            name="valueUnit"
            size="sm"
            defaultValue={gaugeData.valueUnit}
            {...register('valueUnit')}
          />
        </FormControl>
        <label className="text-sm font-bold">รายการตัวแปร</label>{' '}
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>ชื่อตัวแปร</Th>
              <Th>ค่าจาก</Th>
              <Th>ค่า</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(gaugeVariables, (variable, index) => (
              <Tr key={index}>
                <Td>{variable.variableName}</Td>
                <Td>
                  {variable.useFromSensorNotVariable === true ? (
                    <Badge colorScheme="green" size="sm">
                      จากเซ็นเซอร์
                    </Badge>
                  ) : (
                    <Badge colorScheme="yellow" size="sm">
                      ค่าคงที่
                    </Badge>
                  )}
                </Td>
                <Td>
                  {variable.useFromSensorNotVariable === true ? (
                    <Code>{variable.sensorRef}</Code>
                  ) : (
                    variable.constantValue
                  )}
                </Td>
                <Td>
                  {' '}
                  <Button
                    colorScheme="red"
                    type="button"
                    onClick={() => handleDeleteVariable(index)}
                    size="sm"
                  >
                    ลบ
                  </Button>{' '}
                </Td>
              </Tr>
            ))}
            <VariableAddingComponent
              gaugeVariables={gaugeVariables}
              setGaugeVariables={setGagueVariables}
              stationData={stationData}
              rendered={rendered}
              setRendered={setRendered}
            />
          </Tbody>
        </Table>
        <br />
        <label className="text-sm font-bold">การดำเนินการ</label>{' '}
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>ลำดับที่</Th>
              <Th>ชื่อตัวแปร</Th>
              <Th>การดำเนินการ</Th>
              <Th>ชื่อตัวแปร</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(operationList, (eachOperation, index) => (
              <Tr key={index}>
                <Td>{index + 1} </Td>
                <Td>
                  {eachOperation.leftVariable === null
                    ? 'ผลจากส่วนที่ ' + index
                    : eachOperation.leftVariable}
                </Td>
                <Td>{GenerateMathermaticSymbol(eachOperation.operation)}</Td>
                <Td>{eachOperation.rightVariable}</Td>
                <Td>
                  {' '}
                  <Button
                    colorScheme="red"
                    type="button"
                    size="sm"
                    onClick={() => handleDeleteOperator(index)}
                  >
                    ลบ
                  </Button>{' '}
                </Td>
              </Tr>
            ))}

            <OperationListAddingComponent
              gaugeVariables={gaugeVariables}
              operationList={operationList}
              setOperationList={setOperationList}
              rendered={rendered}
              setRendered={setRendered}
            />
          </Tbody>
        </Table>
      </div>
    </div>
  )
}
