/* eslint-disable no-nested-ternary */
/* eslint-disable operator-assignment */
/* eslint-disable no-bitwise */
/* eslint-disable no-lonely-if */
import _ from 'lodash'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

export default function CalAVG(dataArr, name) {
  let tempValueStack = []
  _.map(dataArr, (dataItem, index) => {
    //console.log('dataaa', indexDate)
    if (name === 'OilLossFiber') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push(
        dataItem?.OilLossFiber === null
          ? 0
          : dataItem?.OilLossFiber >= 2000
          ? 0
          : parseFloat(dataItem?.OilLossFiber).toFixed(2)
      )
    } else if (name === 'OilLossOverFiber') {
      //tempValueStack.push(dataItem.OilLossFiber).
      if (dataItem?.y > 0) {
        tempValueStack.push(
          dataItem?.y === null
            ? 0
            : dataItem?.y >= 2000
            ? 0
            : parseFloat(dataItem?.y).toFixed(2)
        )
      }
    } else if (name === 'OilLossDecanter') {
      //tempValueStack.push(dataItem.OilLossFiber).
      tempValueStack.push(
        dataItem?.OilLossDecanter === null
          ? 0
          : dataItem?.OilLossDecanter >= 2000
          ? 0
          : parseFloat(dataItem?.OilLossDecanter).toFixed(2)
      )
    } else if (name === 'OilLossOverDecanter') {
      //tempValueStack.push(dataItem.OilLossFiber).
      if (dataItem?.y > 0) {
        tempValueStack.push(
          dataItem?.y === null
            ? 0
            : dataItem?.y >= 2000
            ? 0
            : parseFloat(dataItem?.y).toFixed(2)
        )
      }
    } else if (name === 'OilLossWastewater') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push(
        dataItem?.OilLossWastewater === null
          ? 0
          : dataItem?.OilLossWastewater >= 2000
          ? 0
          : parseFloat(dataItem?.OilLossWastewater).toFixed(2)
      )
    } else if (name === 'OilLossOverWastewater') {
      //tempValueStack.push(dataItem.OilLossFiber)
      if (dataItem?.y > 0) {
        tempValueStack.push(
          dataItem?.y === null
            ? 0
            : dataItem?.y >= 2000
            ? 0
            : parseFloat(dataItem?.y).toFixed(2)
        )
      }
    } else if (name === 'PalmUsed') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push({
        x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
        y:
          dataItem?.PalmUsed === null
            ? 0
            : dataItem?.PalmUsed >= 2000
            ? 0
            : parseFloat(dataItem?.PalmUsed).toFixed(2),
      })
    } else if (name === 'CPO') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push({
        x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
        y:
          dataItem?.CPO === null
            ? 0
            : dataItem?.CPO >= 2000
            ? 0
            : parseFloat(dataItem?.CPO).toFixed(2),
      })
    } else if (name === 'Yield') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push(
        dataItem?.Yield === null
          ? 0
          : dataItem?.Yield >= 2000
          ? 0
          : parseFloat(dataItem?.Yield).toFixed(2)
      )
    } else if (name === 'Total 1,2') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push(
        dataItem?.GenMeter['Total 1,2'].length === 0
          ? 0
          : parseFloat(dataItem?.GenMeter['Total 1,2']).toFixed(2)
      )
    } else if (name === 'มิเตอร์ก๊าซที่ใช้ผลิต') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push(
        dataItem?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต.length === 0
          ? 0
          : parseFloat(dataItem?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(2)
      )
    } else if (name === 'QuantityPalm') {
      //console.log('fuel ')
      tempValueStack.push(
        dataItem?.QuantityPalm.length === 0 ||
          dataItem?.QuantityFiber.length === 0
          ? 0
          : parseFloat(
              parseFloat(dataItem?.QuantityPalm) +
                parseFloat(dataItem?.QuantityFiber)
            ) || 0
      )
      //console.log('fuel ', dataItem?.date, dataItem?.QuantityFiber)
    } else if (name === 'ProductE') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push(
        dataItem?.ProductE.length === 0
          ? 0
          : parseFloat(dataItem?.ProductE).toFixed(2)
      )
    } else if (name === 'SumSteam') {
      //tempValueStack.push(dataItem.OilLossFiber)
      tempValueStack.push(
        dataItem?.OilBasis.length === 0
          ? 0
          : parseFloat(dataItem?.OilBasis).toFixed(2)
      )
    }
  })

  let total = 0
  _.map(tempValueStack, (value) => {
    total = total + parseFloat(value)
  })
  let AVG = total / tempValueStack?.length
  // console.log('TEMP ', tempValueStack, AVG, total)
  return AVG || 0
}
