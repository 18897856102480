import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { firebaseAuth } from 'contexts/AuthContext'
import { useForm, useFieldArray } from 'react-hook-form'
import * as UserRoles from 'config/UserRoles'
import {
  Checkbox,
  CheckboxGroup,
  Stack,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Select,
} from '@chakra-ui/react'
import _ from 'lodash'
import SpinnerComponent from '../../components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'

export default function CreateUser() {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, errors, setValue, control } = useForm()
  const { fields, remove, append } = useFieldArray({
    name: 'duty',
    control,
  })
  const [rerender, setRerender] = useState(false)
  const { handleSignupByHRMS } = useContext(firebaseAuth)
  const user = useSelector((state) => state.user)
  const stations = useSelector((state) => state.station)
  const dispatch = useDispatch()
  const [selectedStation, setSelectedStation] = useState([])
  useEffect(() => {
    dispatch(actions.stationAll())
    dispatch(actions.userAll())
    return () => {}
  }, [])

  const { isOpen, onOpen, onClose } = useDisclosure()
  // console.log('USER', user)

  const onSubmit = async (data, e) => {
    if (data.password === data.confirm_password) {
      e.preventDefault()

      console.log('Selected station', selectedStation)
      const payload = {
        ...data,
        duty: selectedStation,
      }
      console.log('Payload', data, payload)
      // await handleSignupByHRMS(data.email, data.password, {
      //   ...data,
      //   duty: selectedStation,
      // })
      dispatch(actions.userPost(payload))
      alert('ลงทะเบียนสำเร็จ')
      reset()
    } else {
      alert('รหัสผ่านไม่ตรงกัน')
    }
  }
  const genPositionOption = () => (
    <>
      <option value={UserRoles.ROLES.USER}>{UserRoles.ROLES.USER}</option>
      <option value={UserRoles.ROLES.GENERAL}>{UserRoles.ROLES.GENERAL}</option>
      <option value={UserRoles.ROLES.AMDIN}>{UserRoles.ROLES.ADMIN}</option>
      <option value={UserRoles.ROLES.SUPER_USER}>
        {UserRoles.ROLES.SUPER_USER}
      </option>
    </>
  )

  const setSelect = (index, id) => {
    console.log('index = ', index, id)
    // ดูว่าใน Select Station มี Station ID ตัวนี้มั้ย
    if (selectedStation?.[index] === id) {
      // ลบ station ตัวนี้ออกจาก Array
      selectedStation[index] = null
    } else {
      // เพิ่มลงใน Array
      selectedStation[index] = id
    }
    setSelectedStation(selectedStation)
    setRerender(!rerender)
  }

  const renderStationSelect = () => {
    return _.map(stations.arr, (station, index) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        key={index}
        className={`${
          selectedStation?.[index] === station?._id
            ? 'bg-green-400'
            : 'bg-gray-200'
        } p-2 px-4 rounded-md cursor-pointer`}
        onClick={(event) => {
          setSelect(index, station._id)
        }}
      >
        {station.name}
      </div>
    ))
  }

  if (stations.arr) {
    return (
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-base font-bold font-sans">
                    {t('system.addUser')}
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans">
                    {t('system.userInfo')}
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          name="username"
                          required
                          {...register('username')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="example@email.com"
                        />
                      </div>
                    </div>
                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('connection.password')}
                        </label>
                        <input
                          name="password"
                          type="password"
                          required
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                          {...register('password')}
                        />
                      </div>
                    </div>
                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('connection.confirmPassword')}
                        </label>
                        <input
                          name="confirm_password"
                          type="password"
                          required
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="Confirm Password"
                          {...register('confirm_password')}
                        />
                      </div>
                    </div>
                    <div className="w-full  px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('utility.name')}
                        </label>
                        <input
                          name="name"
                          type="text"
                          required
                          {...register('name')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="ชื่อสกุล"
                        />
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('utility.tel')}
                        </label>
                        <input
                          name="tel"
                          type="text"
                          required
                          {...register('tel')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="เบอร์โทรศัพท์"
                        />
                      </div>
                    </div>
                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('system.address')}
                        </label>
                        <textarea
                          name="address"
                          required
                          {...register('address')}
                          rows="3"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="ที่อยู่"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full  px-4">
                    <div className="relative w-full  mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        {t('utility.role')}
                      </label>
                      <select
                        name="role"
                        required
                        {...register('role')}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      >
                        {genPositionOption()}
                      </select>
                    </div>
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full  mb-3 ">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        {t('utility.duty')}
                      </label>
                      <div className=" w-full grid justify-items-center">
                        <div className=" self-center">
                          <Button
                            variant="outline"
                            colorScheme="blue"
                            onClick={onOpen}
                          >
                            {' '}
                            เลือกสถานีที่ต้องการ{' '}
                          </Button>

                          <Modal isOpen={isOpen} onClose={onClose} size={'xs'}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>
                                <div className="w-full grid justify-items-center">
                                  เลือกสถานี
                                </div>
                              </ModalHeader>
                              <ModalBody>
                                <CheckboxGroup colorScheme="blue">
                                  <Stack
                                    spacing={[3, 5]}
                                    direction={['column', 'column']}
                                  >
                                    {renderStationSelect()}
                                  </Stack>
                                </CheckboxGroup>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  colorScheme="red"
                                  mr={3}
                                  variant="outline"
                                  onClick={onClose}
                                >
                                  {t('utility.cancel')}
                                </Button>
                                <Button
                                  colorScheme="green"
                                  onClick={onClose}
                                  type="button"
                                >
                                  {t('utility.save')}
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center px-4 gap-0 py-3 ">
                    <Button
                      isFullWidth
                      colorScheme="blue"
                      variant="solid"
                      type="submit"
                    >
                      Create Account
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerComponent />
  }
}
