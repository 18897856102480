import React from 'react'
import PropTypes from 'prop-types'

function FooterReportBlank() {
  return {
    margin: [20, 100, 20, 0],
    style: 'tableExample',
    table: {
      widths: ['100%'],
      body: [
        [
          {
            text: ' ',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
      ],
    },
  }
}

FooterReportBlank.propTypes = {}

export default FooterReportBlank
