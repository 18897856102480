import React from 'react'
import { Checkbox, FormControl, Input, Select, Badge } from '@chakra-ui/react'
import _ from 'lodash'

export default function EditBooleanWaterDisplay({
  gaugeData,
  register,
  stationData,
}) {
  const genRefStringList = () => {
    return _.map(stationData.sensors, (sensor) => (
      <option value={sensor.refString}>{sensor.refString}</option>
    ))
  }

  return (
    <>
      <Badge colorScheme="yellow" size="sm">
        ระบบเก่า
      </Badge>{' '}
      <FormControl className="hidden">
        <label className="text-sm font-bold">
          {' '}
          เซ็นเซอร์ที่ต้องการเชื่อมต่อ{' '}
        </label>
        <Select
          defaultValue={gaugeData.sensorRefString}
          {...register('sensorRefString')}
          size="sm"
          name="sensorRefString"
        >
          {genRefStringList()}
        </Select>
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold"> เซ็นเซอร์ตัวที่ 1 (ตัวบน)</label>
        <Select
          defaultValue={gaugeData.onMultiStateBoolean?.highStateRef}
          {...register('onMultiStateBoolean.highStateRef')}
          size="sm"
          name="onMultiStateBoolean.highStateRef"
        >
          {genRefStringList()}
        </Select>
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          เซ็นเซอร์ตัวที่ 2 (ตัวล่าง)
        </label>
        <Select
          defaultValue={gaugeData.onMultiStateBoolean?.lowStateRef}
          {...register('onMultiStateBoolean.lowStateRef')}
          size="sm"
          name="onMultiStateBoolean.lowStateRef"
        >
          {genRefStringList()}
        </Select>
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          คำที่จะแสดงเมื่อเป็นค่า High{' '}
        </label>
        <Input
          type="text"
          name="valueLabel.high"
          size="sm"
          defaultValue={gaugeData.valueLabel?.high}
          {...register('valueLabel.high')}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          ค่าที่จะแสดงเมื่อเป็นค่า Low{' '}
        </label>
        <Input
          type="text"
          name="valueLabel.low"
          size="sm"
          defaultValue={gaugeData.valueLabel?.low}
          {...register('valueLabel.low')}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          ค่าที่จะแสดงเมื่อเป็นค่าอยู่ระหว่าง High กับ Low{' '}
        </label>
        <Input
          type="text"
          name="valueLabel.swing"
          size="sm"
          defaultValue={gaugeData.valueLabel?.swing}
          {...register('valueLabel.swing')}
        />
      </FormControl>
      <FormControl>
        <Checkbox
          name="onMultiStateBoolean.highStateActiveHigh"
          size="sm"
          defaultChecked={gaugeData.onMultiStateBoolean?.highStateActiveHigh}
          {...register('onMultiStateBoolean.highStateActiveHigh')}
        >
          <label className="text-sm font-bold"> ค่า High Active High </label>
        </Checkbox>
      </FormControl>
      <FormControl>
        <Checkbox
          name="onMultiStateBoolean.lowStateActiveHigh"
          size="sm"
          defaultChecked={gaugeData.onMultiStateBoolean?.lowStateActiveHigh}
          {...register('onMultiStateBoolean.lowStateActiveHigh')}
        >
          <label className="text-sm font-bold"> ค่า Low Active High </label>
        </Checkbox>
      </FormControl>
    </>
  )
}
