import React from 'react'
import PropTypes from 'prop-types'

function FooterReportBiomass() {
  return {
    margin: [20, 70, 20, 0],
    style: 'tableExample',
    table: {
      widths: ['50%', '50%'],
      body: [
        [
          {
            text: 'หมายเหตุ ...................................................................................................................................',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: 'ผู้ตรวจสอบ',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: '....................................................................................................................................................',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: '...........................................................',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: '....................................................................................................................................................',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: 'หัวหน้าแผนก Biomass',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
      ],
    },
  }
}

FooterReportBiomass.propTypes = {}

export default FooterReportBiomass
