/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import IndexNavbar from 'components/common/Navbars/IndexNavbar'
import Footer from '../components/common/Footers/Footer'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../redux/actions'
import SpinnerComponent from '../components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Index() {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const system = useSelector((state) => state.system)
  const me = useSelector((state) => state.me)
  const storage_remember = JSON.parse(window.localStorage.eiot_remember)
  const { t } = useTranslation()
  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.meGet(storage_remember.uid))
      dispatch(actions.meGet(storage_remember.uid)).then(() => {
        setIsLoading(true)
      })
    })
    return () => {}
  }, [])
  if (isLoading) {
    return (
      <div className="min-h-screen bg-white">
        <IndexNavbar />
        <div className="min-h-screen">
          <section className="container pt-40 mr-auto ml-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
              {system.enable_dashboard && (
                <div className="my-4  px-4">
                  <Link to={'/dashboards'}>
                    <div
                      className={
                        '  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 bg-blue-500'
                      }
                    >
                      <i className="fa fa-solid fa-chart-line fa-3x text-white"></i>
                      <p className="text-white mt-4 ">
                        {t('dashboard.dashboardMonitor')}
                      </p>
                    </div>
                  </Link>
                </div>
              )}

              {system.enable_statistics && (
                <div className="my-4  px-4">
                  <Link to={'/statistics'}>
                    <div className="bg-blue-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                      <i className="fa fa-database fa-3x text-white"></i>
                      <p className="text-white mt-4 ">
                        {t('dataAndStat.dataAndStat')}
                      </p>
                    </div>
                  </Link>
                </div>
              )}

              {system.enable_controls && (
                <div className="my-4  px-4">
                  <Link to={'/controls'}>
                    <div className="bg-yellow-500 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                      <i className="fa fa-solid fa-gamepad fa-3x text-white"></i>
                      <p className="text-white mt-4 ">{t('control.control')}</p>
                    </div>
                  </Link>
                </div>
              )}

              {system.enable_ebe && (
                <div className="my-4  px-4">
                  <Link to={'/electric-bill-estimator'}>
                    {' '}
                    {/* Electric Bill Estimator  */}
                    <div className="bg-purple-500 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                      <i className="fa fa-file-invoice fa-3x text-white"></i>
                      <p className="text-white mt-4 ">
                        {t('electricBill.electricBill')}
                      </p>
                    </div>
                  </Link>
                </div>
              )}

              {system.enable_counting_summary && (
                <div className="my-4  px-4">
                  <Link to={'/counting-summary'}>
                    {' '}
                    {/* Counting Summary  */}
                    <div className="bg-red-500 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                      <i className="fa fa-industry fa-3x text-white"></i>
                      <p className="text-white mt-4 ">{'สรุปผลการผลิต'}</p>
                    </div>
                  </Link>
                </div>
              )}

              {system.enable_notify_module && (
                <div className="my-4  px-4">
                  <Link to={'/notify'}>
                    {' '}
                    <div className="bg-green-500 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                      <i className="fa fa-solid fa-bell fa-3x text-white"></i>
                      <p className="text-white mt-4 ">
                        {t('notification.notification')}
                      </p>
                    </div>
                  </Link>
                </div>
              )}

              {system.enable_human_input && (
                <div className="my-4  px-4">
                  <Link to={'/humaninput'}>
                    {' '}
                    <div className="bg-pink-600 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                      <i className="fa fa-regular fa-clipboard fa-3x text-white"></i>
                      <p className="text-white mt-4 ">{'ฟอร์ม'}</p>
                    </div>
                  </Link>
                </div>
              )}

              {me.role === 'SUPERUSER' && (
                <div className="my-4  px-4">
                  {' '}
                  <Link to={'/system'}>
                    <div className=" bg-gray-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                      <i className="fa  fa-cogs fa-3x text-white"></i>
                      <p className="text-white mt-4 ">
                        {t('setting.systemManagement')}
                      </p>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </section>
        </div>
        <div className="w-full">
          <Footer />
        </div>
      </div>
    )
  } else {
    return <SpinnerComponent />
  }
}
