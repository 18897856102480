import React, { useState } from 'react'
import { Chart as ChartClassic } from 'react-google-charts'
import { Box } from '@chakra-ui/react'
import { min } from 'lodash'

export default function DisplayMeterClassicAttribute({
  staticData,
  unit,
  highResolution,
  lowResolution,
  valueLabel,
}) {
  // const series = [(staticData * 100) / (highResolution - lowResolution)]
  const rangeResolution = {
    lowHalf: lowResolution + (highResolution - lowResolution) * 0.25,
    half: lowResolution + (highResolution - lowResolution) * 0.5,
    hightHalf: lowResolution + (highResolution - lowResolution) * 0.75,
  }
  const options = {
    plotOptions: {
      greenColor: '#0084ff',
      greenFrom: lowResolution,
      greenTo: lowResolution + (highResolution - lowResolution) * 0.2,
      redFrom: lowResolution + (highResolution - lowResolution) * 0.9,
      redTo: highResolution,
      yellowFrom: lowResolution + (highResolution - lowResolution) * 0.75,
      yellowTo: lowResolution + (highResolution - lowResolution) * 0.9,
      max: highResolution,
      min: lowResolution,
      majorTicks: [
        lowResolution,
        rangeResolution.lowHalf,
        rangeResolution.half,
        rangeResolution.hightHalf,
        highResolution,
      ],
      minorTicks: (rangeResolution.half - rangeResolution.lowHalf) / 5,
    },
    dataLabels: {
      name: {
        show: true,
      },
      value: {
        show: true,
        formatter: (val) => parseFloat(staticData).toFixed(2) + ' ' + unit,
        offsetY: 5,
      },
    },
  }

  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" className="w-full ">
      {' '}
      <ChartClassic
        width={150}
        height={170}
        chartType="Gauge"
        className="mx-10 font-semibold text-xl"
        loader={<div>Loading Gauge</div>}
        data={[
          ['Label', 'Value'],
          ['', staticData],
        ]}
        options={options.plotOptions}
      />
    </Box>
  )
}
