/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-typos */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  FormControl,
  FormLabel,
  Switch,
  Box,
  Select,
} from '@chakra-ui/react'
import _ from 'lodash'

function SensorRequireAttribute({
  inputSensor,
  setInputSensor,
  register,
  station,
  index,
}) {
  // console.log('station', station)
  return (
    <div className="px-4">
      <label
        className="block uppercase text-gray-700 text-xs font-bold mb-2"
        htmlFor="grid-password"
      >
        รับค่าจาก sensor
      </label>

      <Switch
        name={`attributeArray.${index}.inputSensor.sensor`}
        {...register(`attributeArray.${index}.inputSensor.sensor`)}
        onChange={(e) => setInputSensor(e.target.checked)}
      />

      {inputSensor && (
        <div className="py-1">
          <Select
            name={`attributeArray.${index}.inputSensor.sensorId`}
            required
            {...register(`attributeArray.${index}.inputSensor.sensorId`)}
            //onChange={(event) => setInputType(event.target.value)}
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          >
            {_.map(station?.sensors, (eachSensor) => (
              <option value={eachSensor?._id}>{eachSensor?.name}</option>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}

SensorRequireAttribute.propTypes = {
  inputSensor: PropTypes.boolean,
  setInputSensor: PropTypes.object,
  register: PropTypes.object,
  station: PropTypes.object,
}

export default SensorRequireAttribute
