import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import _ from 'lodash'
import { Button, FormControl, FormLabel, Switch, Box } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from '../../../../components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import MultiInputForm from '../../../../components/Form/StationForm/MultiInputForm'
import CheckBoxForm from '../../../../components/Form/StationForm/CheckBoxForm'
import SelectorForm from '../../../../components/Form/StationForm/SelectorForm'
import CalculateForm from '../../../../components/Form/StationForm/CalculateForm'
import SensorRequire from '../Components/SensorRequire'

export default function AddForm() {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const station = useSelector((state) => state.station)
  const form = useSelector((state) => state.form)
  const { t, i18n } = useTranslation()
  const [formType, setFormType] = useState('NULL')
  const [inputType, setInputType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [inputSensor, setInputSensor] = useState(false)
  const { register, handleSubmit, reset, control, setValue } = useForm()

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'attributeArray',
  })

  useEffect(() => {
    dispatch(actions.stationGet(params.stationId))
    dispatch(actions.formGet(params.formId))

    return () => {}
  }, [params])

  const dataComponent = form?.components

  useEffect(() => {
    setIsLoading(true)
    return () => {}
  }, [])
  //console.log('form', form)
  const onSubmit = async (data, e) => {
    const confirm = window.confirm('ยืนยันการเพิ่มฟอร์ม')
    if (confirm) {
      console.log('data', data)
      const dataSet = { ...data, attribute: data?.attributeArray }
      dataComponent.push(dataSet)
      console.log('dataComponent', dataComponent)
      //console.log('datasubmit', dataSubmit)
      await dispatch(
        actions.formPut(params.formId, { components: dataComponent })
      )
      await dispatch(actions.formGet(params.formId))
      history.goBack()
    }
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
            <div className="rounded-t mb-0 px-6 py-6">
              <div className="text-center mb-3">
                <h6 className="text-gray-600 text-base font-bold font-sans">
                  เพิ่มแบบฟอร์มลงในสถานี {station?.name}
                </h6>
              </div>
              <hr className="mt-6 border-b-1 border-gray-400" />
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                  ข้อมูลแบบฟอร์ม
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full  px-4">
                    <div className="relative w-full  mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        ชนิดของแบบฟอร์ม
                      </label>
                      <select
                        name="formType"
                        required
                        {...register('formType')}
                        onChange={(event) => setFormType(event.target.value)}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      >
                        <option value="NULL">เลือกชนิดของแบบฟอร์ม</option>
                        <option value="SINGLE_INPUT">Single Input</option>
                        <option value="MULTIPLE_INPUT">Multiple Input</option>
                        <option value="CHECKBOX">Check Box</option>
                        <option value="SELECTOR">Selector</option>
                        <option value="CALCULATE">Calculate</option>
                      </select>
                    </div>
                  </div>
                  {formType === 'SINGLE_INPUT' && (
                    <>
                      <div className="w-full  px-4">
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              ชื่อฟอร์ม
                            </label>
                            <input
                              name="name"
                              type="text"
                              required
                              {...register('name')}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              placeholder="Meter"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              ชื่อหัวข้อ
                            </label>
                            <input
                              name="topic"
                              type="text"
                              required
                              {...register('topic')}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              placeholder="มิเตอร์"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              คำอธิบาย
                            </label>
                            <input
                              name="description"
                              type="text"
                              {...register('description')}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              placeholder="ใช้สำหรับมิเตอร์"
                            />
                          </div>
                        </div>
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชนิดของอินพุต
                          </label>
                          <select
                            name="inputType"
                            required
                            {...register('inputType')}
                            onChange={(event) =>
                              setInputType(event.target.value)
                            }
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value="DATE">Date</option>
                            <option value="TIME">Time</option>
                            <option value="NUMBER">Number</option>
                            <option value="TEXT">Text</option>
                            <option value="USERNAME">Username</option>
                          </select>
                        </div>
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Required
                          </label>
                          <select
                            name="required"
                            {...register('required')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </select>
                        </div>
                        {inputType === 'DATE' && (
                          <div>
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              ตัวอย่างฟอร์ม
                            </label>
                            <Box
                              maxW="sm"
                              borderWidth="1px"
                              borderRadius="lg"
                              className=" ml-4 my-2 p-4 w-full h-32"
                            >
                              <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                วันที่
                              </label>
                              <input
                                readOnly
                                type="date"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                              />
                            </Box>
                          </div>
                        )}
                        {inputType === 'TIME' && (
                          <div>
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              ตัวอย่างฟอร์ม
                            </label>
                            <Box
                              maxW="sm"
                              borderWidth="1px"
                              borderRadius="lg"
                              className=" ml-4 my-2 p-4 w-full h-32"
                            >
                              <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                เวลา
                              </label>
                              <input
                                readOnly
                                type="time"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                              />
                            </Box>
                          </div>
                        )}
                        {inputType === 'NUMBER' && (
                          <div>
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              ตัวอย่างฟอร์ม
                            </label>
                            <Box
                              maxW="sm"
                              borderWidth="1px"
                              borderRadius="lg"
                              className=" ml-4 my-2 p-4 w-full h-32"
                            >
                              <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                ตัวเลข
                              </label>
                              <input
                                readOnly
                                type="number"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                              />
                            </Box>
                          </div>
                        )}
                        {inputType === 'TEXT' && (
                          <div>
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              ตัวอย่างฟอร์ม
                            </label>
                            <Box
                              maxW="sm"
                              borderWidth="1px"
                              borderRadius="lg"
                              className=" ml-4 my-2 p-4 w-full h-32"
                            >
                              <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                มิเตอร์
                              </label>
                              <input
                                readOnly
                                type="text"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                                placeholder="ใช้สำหรับมิเตอร์"
                              />
                            </Box>
                          </div>
                        )}
                        <SensorRequire
                          inputSensor={inputSensor}
                          setInputSensor={setInputSensor}
                          register={register}
                          station={station}
                        />
                      </div>
                    </>
                  )}
                  {formType === 'MULTIPLE_INPUT' && (
                    <MultiInputForm
                      register={register}
                      inputType={inputType}
                      setInputType={setInputType}
                      fields={fields}
                      append={append}
                      remove={remove}
                      station={station}
                      inputSensor={inputSensor}
                      setInputSensor={setInputSensor}
                    />
                  )}
                  {formType === 'CHECKBOX' && (
                    <CheckBoxForm
                      register={register}
                      inputType={inputType}
                      setInputType={setInputType}
                      fields={fields}
                      append={append}
                      remove={remove}
                      station={station}
                      inputSensor={inputSensor}
                      setInputSensor={setInputSensor}
                    />
                  )}
                  {formType === 'SELECTOR' && (
                    <SelectorForm
                      register={register}
                      inputType={inputType}
                      setInputType={setInputType}
                      fields={fields}
                      append={append}
                      remove={remove}
                      station={station}
                      inputSensor={inputSensor}
                      setInputSensor={setInputSensor}
                    />
                  )}
                  {formType === 'CALCULATE' && (
                    <CalculateForm
                      register={register}
                      inputType={inputType}
                      setInputType={setInputType}
                      fields={fields}
                      append={append}
                      remove={remove}
                      station={station}
                      inputSensor={inputSensor}
                      setInputSensor={setInputSensor}
                      formComponent={dataComponent}
                    />
                  )}
                </div>

                <div className="text-center px-4 gap-0 py-3 ">
                  <Button
                    isFullWidth
                    colorScheme="blue"
                    variant="solid"
                    type="submit"
                  >
                    {t('utility.save')}{' '}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
