import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from '../../../../components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
import GroupEdit from '../Components/GroupEdit'
import BackButton from 'components/Button/BackButton'
import { useParams, useHistory, Link } from 'react-router-dom'
import * as actions from '../../../../redux/actions'

export default function GroupList() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const [currentStation, setCurrentStation] = useState()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => {})
    return () => {}
  }, [params])

  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.id
      )
      setCurrentStation(tempcurrentStatus)
      setIsLoading(true)
      console.log('Curent Station', currentStation)
    }
    return () => {}
  }, [params, allStations])

  const genGroupList = () => {
    return _.map(currentStation?.groups, (group, index) => (
      <GroupEdit group={group} />
    ))
  }

  if (currentStation && isLoading) {
    return (
      <div>
        <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                  {t('setting.systemManagement')}
                </h6>
                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                  จัดการกลุ่มใน {currentStation.name}
                </h2>
              </div>
            </div>
            <Button>
              <BackButton />
            </Button>
          </div>
          <div className="flex justify-start gap-1 px-10 py-4">
            <Link to={'/system/stations/group/add/' + params.id}>
              <div className=" bg-white shadow rounded py-10 px-16 transition-transform transform text-center hover:scale-105">
                <i className="fa fa-solid fa-plus text-gray-800 text-center "></i>
                <p className=" text-gray-800 mb-0 text-center font-bold ">
                  สร้างกลุ่ม
                </p>
              </div>
            </Link>
          </div>
          <div className="p-4 w-full">{genGroupList()}</div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
