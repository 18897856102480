import React, { useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
// components
import _ from 'lodash'
import BackButton from 'components/Button/BackButton'
import GeneratEditForm from '../../components/Form/GeneratEditForm'
import Spinners from 'components/Loading/SpinnerLoading'
import { async } from 'pdfmake-thaifont-2/build/pdfmake'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'

export default function EditHistoryFrom() {
  const { t } = useTranslation()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const formInput = useSelector((state) => state.formInput)
  const form = useSelector((state) => state.form)
  const subForm = useSelector((state) => state.subForm)
  const station = useSelector((state) => state.station)
  const [date, setDate] = useState()

  useEffect(() => {
    dispatch(actions.formInputGet(params.form, params.id))
    dispatch(actions.formGet(params.form))
    dispatch(actions.subFormGet(params.formId))
    setDate(dayjs(formInput?.date).toDate())
    return () => {}
  }, [params])
  console.log('form', params)
  if (formInput?.isLoading || formInput?.arr) {
    return <Spinners />
  }

  if (formInput?.isLoading && formInput?.isCompleted) {
    return <div>Error</div>
  }

  const handleEdit = async (data) => {
    const confirm = window.confirm('ยืนยันการแก้ไขข้อมูล')
    if (confirm) {
      try {
        console.log('data ', data)
        const payload = {
          ...data,
          date: dayjs(date).format('YYYY-MM-DDT08:mm:ssZ'), //.format('YYYY-MM-DDTHH:mm:ssZ'),
        }
        console.log('payload', payload)
        await dispatch(actions.formInputPut(params.form, params.id, payload))
        await dispatch(actions.formInputAll(params.formId, params.id))

        history.goBack()
      } catch (error) {
        console.log('ERROR')
      }
    }
  }
  if (!formInput?.isLoading && formInput?.isCompleted) {
    return (
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                แบบฟอร์มในสถานี {station?.name || ''}
              </h6>

              <h2 className="text-gray-800 text-2xl font-bold font-sans ">
                แก้ไขข้อมูล {formInput?.name || ''}
              </h2>
              <BackButton />

              {formInput?.isCompleted && (
                <div>
                  <Box
                    maxW="sm"
                    borderWidth="1px"
                    borderRadius="lg"
                    className=" ml-4 my-2 p-4 w-auto h-36"
                  >
                    <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                      วันที่บันทึก
                    </label>
                    <SingleDatepicker
                      name="date-input"
                      date={date}
                      onDateChange={setDate}
                    />
                  </Box>
                  <GeneratEditForm
                    form={form?.subForm_status ? subForm : form}
                    formInput={formInput}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div></div>
}
