import { Button, Link as LinkUI, Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../../redux/actions'
import _ from 'lodash'

export default function SubFormList({ form, prepateDeleteForm }) {
  const dispatch = useDispatch()
  const params = useParams()
  const subForm = useSelector((state) => state.subForm)
  useEffect(() => {
    dispatch(actions.subFormAll({ form: form?._id }))
    return () => {}
  }, [form])

  const hendleDelete = async (data) => {
    const confirm = window.confirm(`ยืนยันลบฟอร์มย่อย ${data?.name} `)
    if (confirm) {
      await dispatch(actions.subFormDelete(data?._id))
      await dispatch(actions.subFormAll({ form: form?._id }))
    }
  }

  const renderButton = () => (
    <div className="py-1 ">
      <div className="flex justify-start gap-3 ">
        <Link link to={`/system/stations/subform/create/${form?._id}`}>
          <Button colorScheme="blue" variant="solid" size="sm">
            เพิ่ม
          </Button>
        </Link>
        <Link
          to={`/system/stations/report/${params.stationId}/${params.formId}`}
        >
          <Button colorScheme="green" variant="solid" size="sm">
            จัดการรูปแบบรายงาน
          </Button>
        </Link>
        <div>
          <Button
            colorScheme="red"
            variant="solid"
            size="sm"
            onClick={() => prepateDeleteForm(form?._id)}
          >
            ลบรายการฟอร์ม
          </Button>
        </div>
      </div>
    </div>
  )
  const renderSubform = () => (
    <div className="py-1 px-2">
      {_.map(subForm?.arr, (row, index) => (
        <Box
          borderRadius="lg"
          borderWidth="1px"
          padding="4"
          className="mb-4"
          key={index}
        >
          <div className="flex justify-between ">
            <div className="">
              <Link to={`/system/stations/subform/edit/${row?._id}`}>
                <h2 className="text-xl font-bold font-sans">
                  <LinkUI>
                    <i className="fas fa-search text-sm "> </i> {row?.name}
                  </LinkUI>
                </h2>
              </Link>
              <h5 className="text-base text-gray-700 font-sans">
                {row?.description}
              </h5>
            </div>
            <div>
              <Button
                colorScheme="red"
                variant="solid"
                size="sm"
                onClick={() => hendleDelete(row)}
              >
                ลบ
              </Button>
            </div>
          </div>
        </Box>
      ))}
    </div>
  )

  if (!subForm?.isLoading && subForm?.isCompleted) {
    return (
      <div className="pl-4 pb-2">
        <div>
          {renderButton()}
          {renderSubform()}
        </div>
      </div>
    )
  }
  return <div>404</div>
}
