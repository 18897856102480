import React from 'react'
import { auth, signInAuthProvider } from './firebase-client'
import axios from 'axios'
import { setStorage, removeStorage } from 'util/localstorage'

export const authMethods = {
  // firebase helper methods go here...
  signup: async (email, password, data, setErrors, setToken) => {
    await auth
      .createUserWithEmailAndPassword(email, password)
      //make res asynchronous so that we can make grab the token before saving it.
      .then(async (res) => {
        const eiot_remember = {
          uid: res.user.uid,
          photoURL: res.user.photoURL,
          email: res.user.email,
        }
        const token = await Object.entries(res.user)[5][1].b
        await axios
          .post(process.env.REACT_APP_API_URL + '/user', data)
          .then(async (res) => {
            console.log('Request Server to Generate Employee')
            //set token to localStorage

            // await localStorage.setItem("token", token);
            await setStorage('token', token)
            await setStorage('eiot_remember', JSON.stringify(eiot_remember))

            //grab token from local storage and set to state.
            setToken(window.localStorage.token)
          })
          .catch((e) => {
            if (e) {
              console.log(e)
            }
          })
        console.log(res)
      })
      .catch((err) => {
        if (err.code === 'auth/email-already-in-use') {
          alert('อีเมลนี้ถูกใช้เเล้ว')
        } else if (err.code === 'auth/weak-password') {
          alert('กรุณาตั้งรหัสผ่านอย่างน้อย 6 ตัวอักษร')
        }
        setErrors((prev) => [...prev, err.message])
      })
  },
  signupByHRMS: async (email, password, data, setErrors, setToken) => {
    await auth
      .createUserWithEmailAndPassword(email, password)
      //make res asynchronous so that we can make grab the token before saving it.
      .then(async (res) => {
        data.id = res.user.uid
        await axios
          .post(process.env.REACT_APP_API_URL + '/user', data)
          .then(async (res) => {
            console.log('Request Server to Generate User')
            //set token to localStorage
            // await localStorage.setItem("token", token);
            //grab token from local storage and set to state.
            // setToken(window.localStorage.token);
          })
          .catch((e) => {
            if (e) {
              console.log(e)
            }
          })
        console.log(res)
      })
      .catch((err) => {
        if (err.code === 'auth/email-already-in-use') {
          alert('อีเมลนี้ถูกใช้เเล้ว')
        } else if (err.code === 'auth/weak-password') {
          alert('กรุณาตั้งรหัสผ่านอย่างน้อย 6 ตัวอักษร')
        }
        setErrors((prev) => [...prev, err.message])
      })
  },
  signin: async (email, password, setErrors, setToken) => {
    //change from create users to...
    await auth
      .signInWithEmailAndPassword(email, password)
      //everything is almost exactly the same as the function above
      .then(async (res) => {
        const data = res.user
        const eiot_remember = {
          uid: res.user.uid,
          photoURL: res.user.photoURL,
          email: res.user.email,
        }
        await axios
          .get(process.env.REACT_APP_API_URL + '/user/' + res.user.uid)
          .then(async (res) => {
            console.log('Request Server to Sigin and Found User')
            //   const token = await Object.entries(data)[5][1].b;

            await setStorage('token', 'll')
            await setStorage('eiot_remember', JSON.stringify(eiot_remember))

            setToken(window.localStorage.token)
            console.log(res)
          })
          .catch((e) => {
            if (e) {
              alert('บัญชีผู้ใช้ไม่ถูกต้อง')
              console.log(e)
            }
          })
      })
      .catch((err) => {
        // console.log(err.message);
        if (err.code === 'auth/user-not-found') {
          alert('ไม่พบบัญชีผู้ใช้ในระบบ')
        } else if (err.code === 'auth/wrong-password') {
          alert('รหัสผ่านไม่ถูกต้อง')
        } else if (err.code === 'auth/invalid-email') {
          alert('รูปเเบบอีเมลไม่ถูกต้อง')
        }
        setErrors((prev) => [...prev, err])
      })
  },
  siginWithProvider: (setToken) => {
    const uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'redirect',
      signInSuccessUrl: '/admin',
      // We will display Google and Facebook as auth providers.
      signInOptions: signInAuthProvider,
      tosUrl: 'https://eonlineshop.net/policy/docs/condition',
      privacyPolicyUrl: 'https://eonlineshop.net/policy/docs/',
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: async (res) => {
          const eiot_remember = {
            uid: res.user.uid,
            photoURL: res.user.photoURL,
            email: res.user.email,
          }
          const token = await Object.entries(res.user)[5][1].b
          await setStorage('token', token)
          await setStorage('eiot_remember', JSON.stringify(eiot_remember))
          setToken(window.localStorage.token)
          console.log(res)
        },
      },
    }
    return <div></div>
  },
  signout: async (setErrors, setToken) => {
    // signOut is a no argument function
    await auth
      .signOut()
      .then((res) => {
        //remove the token
        //set the token back to original state
        setToken(null)
      })
      .catch((err) => {
        //there shouldn't every be an error from firebase but just in case
        setErrors((prev) => [...prev, err.message])
        //whether firebase does the trick or not i want my user to do there thing.
        removeStorage('token')
        removeStorage('eiot_remember')

        setToken(null)
        console.error(err.message)
      })
  },
  forgotenPassword: async (email, setErrors, setToken) => {
    await auth
      .sendPasswordResetEmail(email)
      .then((res) => {
        //remove the token
        removeStorage('eiot_remember')
        // localStorage.removeItem("token");
        //set the token back to original state
        setToken(null)
      })
      .catch((err) => {
        //there shouldn't every be an error from firebase but just in case
        setErrors((prev) => [...prev, err.message])
        //whether firebase does the trick or not i want my user to do there thing.
        removeStorage('token')
        removeStorage('eiot_remember')

        setToken(null)
        console.error(err.message)
      })
  },
}

export default authMethods
