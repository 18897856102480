import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Switch,
  Input,
  FormControl,
  FormLabel,
  NumberInput,
} from '@chakra-ui/react'

export default function NumberControl({ sensor, enableSwitchNumber }) {
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const submitControlNumber = (data) => {
    console.log('Submit Number : ', data)
    enableSwitchNumber(sensor.controlRefString, data)
  }
  return (
    <div className="w-full">
      <div className="lg:flex border-2 rounded-md">
        <div className="bg-gray-200 md:w-1/3">
          <div className="items-center text-center px-4 py-6">
            {sensor.name}
          </div>
        </div>
        <div className="w-full flex flex-center md:w-2/3">
          <div className="flex flex-row flex-center text-center w-2/3">
            <div className="flex flex-row flex-center px-2 text-center">
              <FormControl>
                <label className="text-sm font-sans mx-2">ค่าควบคุม</label>
                <Input
                  type="number"
                  name="value"
                  size="sm"
                  {...register('value')}
                  defaultValue={sensor?.controlNumber?.value}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-center self-center w-1/3">
            <div className="justify-between text-center px-4 m-2">
              <FormControl display="flex">
                <Switch
                  size="md"
                  colorScheme="blue"
                  name="enable"
                  {...register('enable')}
                  defaultChecked={sensor?.controlNumber?.enable}
                />
              </FormControl>
            </div>
            <div className="mb-2">
              <Button
                size="sm"
                colorScheme="green"
                type="submit"
                onClick={handleSubmit(submitControlNumber)}
              >
                {t('utility.save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
