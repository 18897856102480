/* eslint-disable import/no-duplicates */
import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  Input,
  Select,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Slider,
  SliderMark,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import _, { set } from 'lodash'
import { useTranslation } from 'react-i18next'
import GalleryImageDiagram from './GalleryImageDiagram'

import Config from '../../../config'
import config from '../../../config'

// Create For Test New Solution diagram type scada.
export default function CreateComponentDiagram({
  SelectDiagram,
  diagramData,
  attr,
  index,
  register,
  stationData,
  onDeleteAttribute,
  selectImage,
  setSelectImage,
  newList,
  setTypeImage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const [selectedSourceType, setSelectedSourceType] = useState(attr.dataSource)
  const [selectGallery, setSelectGallery] = useState(false)

  const [selectedPreferDataType, setSelectedPreferDataType] = useState(
    attr.preferDataType
  )

  const genRefStringList = () => {
    return _.map(stationData?.sensors, (sensor) => (
      <option value={sensor?.refString}>{sensor?.refString}</option>
    ))
  }

  const genVariableList = () => {
    return _.map(stationData?.variables, (variable) => (
      <option value={variable?.id}>{variable?.variableName}</option>
    ))
  }

  switch (SelectDiagram) {
    case config?.ComponentType?.DiagramType?.Null?.value:
      return (
        <Box
          key={index}
          padding="4"
          borderRadius="lg"
          borderWidth="2"
          className="text-sm font-semibold border mb-2"
        >
          Null
        </Box>
      )
    case config?.ComponentType?.DiagramType?.Image?.value:
      return (
        <Box
          key={index}
          padding="4"
          borderRadius="lg"
          borderWidth="2"
          className="text-sm font-semibold border mb-2"
        >
          <FormControl>
            <Button colorScheme="blue" onClick={() => onOpen()}>
              เลือกรูป
            </Button>
          </FormControl>
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalContent>
              <ModalHeader>เลือกรูปที่ต้องการ</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <GalleryImageDiagram
                  setSelectImage={setSelectImage}
                  selectImage={selectImage}
                  setTypeImage={setTypeImage}
                ></GalleryImageDiagram>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="green" mr={3} onClick={onClose}>
                  Submit
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <FormControl>
            <label className="font-semibold">
              {t('sensor.diagramSizeImage')}
            </label>
            <Slider
              defaultValue={diagramData?.imageSize}
              min={0}
              max={280}
              step={10}
              name="imageSize"
              {...register('imageSize')}
              // ref={register}
            >
              <SliderMark value={0}>0%</SliderMark>
              <SliderMark value={70}>25%</SliderMark>
              <SliderMark value={140}>50%</SliderMark>
              <SliderMark value={210}>75%</SliderMark>
              <SliderMark value={280}>100%</SliderMark>

              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </FormControl>

          <FormControl>
            <label>{t('sensor.dataSource')}</label>
            <Select
              name={`attribute[${index}].dataSource`}
              defaultValue={attr?.dataSource}
              size="sm"
              // ref={register}
              {...register(`attribute[${index}].dataSource`)}
              value={selectedSourceType}
              onChange={(event) => setSelectedSourceType(event.target.value)}
            >
              <option value="">{t('sensor.dataSourceSelect')}</option>
              <option value={Config?.TypeOfSource?.GaugeSource?.sensor}>
                {t('setting.sensor')}
              </option>
              <option value={Config?.TypeOfSource?.GaugeSource?.variable}>
                {t('setting.variable')}
              </option>
              <option value={Config?.TypeOfSource?.GaugeSource?.null}>
                ไม่มี
              </option>
            </Select>
          </FormControl>
          {selectedSourceType === Config?.TypeOfSource?.GaugeSource?.sensor ? (
            <FormControl>
              <label> {t('sensor.sensorDisplay')}</label>
              <Select
                name={`attribute[${index}].sensorRef`}
                defaultValue={attr?.sensorRef}
                // ref={register}
                {...register(`attribute[${index}].sensorRef`)}
                size="sm"
              >
                <option value=""> {t('sensor.dataSourceSelect')}</option>
                {genRefStringList()}
              </Select>
            </FormControl>
          ) : (
            <>
              {' '}
              {selectedSourceType ===
              Config?.TypeOfSource?.GaugeSource?.variable ? (
                <FormControl>
                  <label> {t('variable.variableDisplay')}</label>
                  <Select
                    name={`attribute[${index}].variableId`}
                    defaultValue={attr?.variableId}
                    // ref={register}
                    {...register(`attribute[${index}].variableId`)}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genVariableList()}
                  </Select>
                </FormControl>
              ) : (
                <div></div>
              )}
            </>
          )}
        </Box>
      )

    case config?.ComponentType?.DiagramType?.Text?.value:
      return (
        <Box
          key={index}
          padding="4"
          borderRadius="lg"
          borderWidth="2"
          className="text-sm font-semibold border mb-2"
        >
          <FormControl>
            <label className="font-semibold">ขนาดของกล่องข้อความ</label>
            <Slider
              defaultValue={diagramData?.imageSize}
              min={0}
              max={100}
              step={10}
              name="imageSize"
              {...register('imageSize')}
              //ref={register}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </FormControl>
          <FormControl>
            <label>{t('utility.valueName')}</label>
            <Input
              type="text"
              name={`attribute[${index}].label`}
              defaultValue={attr.label}
              // ref={register}
              {...register(`attribute[${index}].label`)}
              size="sm"
            />
          </FormControl>
          <FormControl>
            <label>{t('utility.valueType')}</label>
            <Select
              name={`attribute[${index}].preferDataType`}
              defaultValue={attr.preferDataType}
              size="sm"
              {...register(`attribute[${index}].preferDataType`)}
              //ref={register}
              value={selectedPreferDataType}
              onChange={(event) =>
                setSelectedPreferDataType(event.target.value)
              }
            >
              <option value="">{t('utility.valueTypeSelect')}</option>
              <option value={Config.DataType.DataType.boolean}>Boolean</option>
              <option value={Config.DataType.DataType.number}>
                {t('variable.numberic')}
              </option>
              <option value={Config.DataType.DataType.string}>
                {t('variable.string')}
              </option>
              <option value={Config.DataType.DataType.inputText}>
                ป้อนข้อความ
              </option>
            </Select>
          </FormControl>

          {selectedPreferDataType === Config?.DataType?.DataType?.number && (
            <>
              {' '}
              <FormControl>
                <label>{t('sensor.dataSource')}</label>
                <Select
                  name={`attribute[${index}].dataSource`}
                  defaultValue={attr.dataSource}
                  size="sm"
                  {...register(`attribute[${index}].dataSource`)}
                  // ref={register}
                  value={selectedSourceType}
                  onChange={(event) =>
                    setSelectedSourceType(event.target.value)
                  }
                >
                  <option value="">{t('sensor.dataSourceSelect')}</option>
                  <option value={Config.TypeOfSource.GaugeSource.sensor}>
                    {t('setting.sensor')}
                  </option>
                  <option value={Config.TypeOfSource.GaugeSource.variable}>
                    {t('setting.variable')}
                  </option>
                </Select>
              </FormControl>
              {selectedSourceType ===
              Config?.TypeOfSource?.GaugeSource?.sensor ? (
                <FormControl>
                  <label> {t('sensor.sensorDisplay')}</label>
                  <Select
                    name={`attribute[${index}].sensorRef`}
                    defaultValue={attr.sensorRef}
                    {...register(`attribute[${index}].sensorRef`)}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genRefStringList()}
                  </Select>
                </FormControl>
              ) : (
                <FormControl>
                  <label> {t('variable.variableDisplay')}</label>
                  <Select
                    name={`attribute[${index}].variableId`}
                    defaultValue={attr.variableId}
                    {...register(`attribute[${index}].variableId`)}
                    //ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genVariableList()}
                  </Select>
                </FormControl>
              )}
              <FormControl>
                <label>{t('utility.unitOfValue')}</label>
                <Input
                  type="text"
                  defaultValue={attr.unit}
                  name={`attribute[${index}].unit`}
                  {...register(`attribute[${index}].unit`)}
                  // ref={register}
                  size="sm"
                />
              </FormControl>
            </>
          )}
          {selectedPreferDataType === Config?.DataType?.DataType?.boolean && (
            <>
              <FormControl>
                <label>{t('sensor.dataSource')}</label>
                <Select
                  name={`attribute[${index}].dataSource`}
                  defaultValue={attr.dataSource}
                  {...register(`attribute[${index}].dataSource`)}
                  size="sm"
                  // ref={register}
                  value={selectedSourceType}
                  onChange={(event) =>
                    setSelectedSourceType(event.target.value)
                  }
                >
                  <option value="">{t('sensor.dataSourceSelect')}</option>
                  <option value={Config.TypeOfSource.GaugeSource.sensor}>
                    {t('setting.sensor')}
                  </option>
                  <option value={Config.TypeOfSource.GaugeSource.variable}>
                    {t('setting.variable')}
                  </option>
                </Select>
              </FormControl>
              {selectedSourceType === Config.TypeOfSource.GaugeSource.sensor ? (
                <FormControl>
                  <label> {t('sensor.sensorDisplay')}</label>
                  <Select
                    {...register(`attribute[${index}].sensorRef`)}
                    name={`attribute[${index}].sensorRef`}
                    defaultValue={attr.sensorRef}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genRefStringList()}
                  </Select>
                </FormControl>
              ) : (
                <FormControl>
                  <label> {t('variable.variableDisplay')}</label>
                  <Select
                    name={`attribute[${index}].variableId`}
                    {...register(`attribute[${index}].variableId`)}
                    defaultValue={attr.variableId}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genVariableList()}
                  </Select>
                </FormControl>
              )}
              <FormControl>
                <Checkbox
                  defaultChecked={attr.isActiveHigh}
                  name={`attribute[${index}].isActiveHigh`}
                  {...register(`attribute[${index}].isActiveHigh`)}
                  // ref={register}
                  size="sm"
                >
                  {t('variable.value')} Active High
                </Checkbox>
              </FormControl>
              <FormControl>
                <label>{t('variable.wordDisplay')} Active</label>
                <Input
                  type="text"
                  defaultValue={attr.labelActive}
                  name={`attribute[${index}].labelActive`}
                  {...register(`attribute[${index}].labelActive`)}
                  // ref={register}
                  size="sm"
                />
              </FormControl>
              <FormControl>
                <label>{t('variable.wordDisplay')} Inactive</label>
                <Input
                  type="text"
                  defaultValue={attr.labelInactive}
                  name={`attribute[${index}].labelInactive`}
                  {...register(`attribute[${index}].labelInactive`)}
                  // ref={register}
                  size="sm"
                />
              </FormControl>
            </>
          )}
          {selectedPreferDataType === Config?.DataType?.DataType?.string && (
            <>
              <FormControl>
                <label>{t('sensor.dataSource')}</label>
                <Select
                  name={`attribute[${index}].dataSource`}
                  defaultValue={attr.dataSource}
                  size="sm"
                  {...register(`attribute[${index}].dataSource`)}
                  // ref={register}
                  value={selectedSourceType}
                  onChange={(event) =>
                    setSelectedSourceType(event.target.value)
                  }
                >
                  <option value="">{t('sensor.dataSourceSelect')}</option>
                  <option value={Config?.TypeOfSource?.GaugeSource?.sensor}>
                    {t('setting.sensor')}
                  </option>
                  <option value={Config?.TypeOfSource?.GaugeSource?.variable}>
                    {t('setting.variable')}
                  </option>
                </Select>
              </FormControl>
              {selectedSourceType ===
              Config?.TypeOfSource?.GaugeSource?.sensor ? (
                <FormControl>
                  <label> {t('sensor.sensorDisplay')}</label>
                  <Select
                    name={`attribute[${index}].sensorRef`}
                    defaultValue={attr.sensorRef}
                    {...register(`attribute[${index}].sensorRef`)}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genRefStringList()}
                  </Select>
                </FormControl>
              ) : (
                <FormControl>
                  <label> {t('variable.variableDisplay')}</label>
                  <Select
                    name={`attribute[${index}].variableId`}
                    defaultValue={attr.variableId}
                    {...register(`attribute[${index}].variableId`)}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genVariableList()}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {selectedPreferDataType === Config?.DataType?.DataType?.inputText && (
            <div>
              <FormControl>
                <label> input text</label>
                <Input
                  type="text"
                  defaultValue={attr.inputText}
                  name={`attribute[${index}].inputText`}
                  {...register(`attribute[${index}].inputText`)}
                  // ref={register}
                  size="sm"
                />
              </FormControl>
            </div>
          )}
        </Box>
      )
    case config?.ComponentType?.DiagramType?.String?.value:
      return (
        <Box
          key={index}
          padding="4"
          borderRadius="lg"
          borderWidth="2"
          className="text-sm font-semibold border mb-2"
        >
          <div className="flex justify-between">
            <h5 className="mb-2">
              {t('utility.noList')} {index + 1}{' '}
            </h5>
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => {
                onDeleteAttribute(index)
              }}
            >
              {t('utility.delete')}
            </Button>
          </div>
          <FormControl>
            <label className="font-semibold">ขนาดของกล่องข้อความ</label>
            <Slider
              defaultValue={diagramData.imageSize}
              min={0}
              max={100}
              step={10}
              name="imageSize"
              {...register('imageSize')}
              // ref={register}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </FormControl>
          <FormControl>
            <label>{t('utility.valueName')}</label>
            <Input
              type="text"
              name={`attribute[${index}].label`}
              {...register(`attribute[${index}].label`)}
              defaultValue={attr.label}
              // ref={register}
              size="sm"
            />
          </FormControl>
          <FormControl>
            <label>{t('utility.valueType')}</label>
            <Select
              name={`attribute[${index}].preferDataType`}
              {...register(`attribute[${index}].preferDataType`)}
              defaultValue={attr.preferDataType}
              size="sm"
              // ref={register}
              value={selectedPreferDataType}
              onChange={(event) =>
                setSelectedPreferDataType(event.target.value)
              }
            >
              <option value="">{t('utility.valueTypeSelect')}</option>
              <option value={Config.DataType.DataType.boolean}>Boolean</option>
              <option value={Config.DataType.DataType.number}>
                {t('variable.numberic')}
              </option>
              <option value={Config.DataType.DataType.string}>
                {t('variable.string')}
              </option>
              <option value={Config.DataType.DataType.inputText}>
                ป้อนข้อความ
              </option>
            </Select>
          </FormControl>

          {selectedPreferDataType === Config.DataType.DataType.number && (
            <>
              <FormControl>
                <label>{t('sensor.dataSource')}</label>
                <Select
                  name={`attribute[${index}].dataSource`}
                  {...register(`attribute[${index}].dataSource`)}
                  defaultValue={attr.dataSource}
                  size="sm"
                  // ref={register}
                  value={selectedSourceType}
                  onChange={(event) =>
                    setSelectedSourceType(event.target.value)
                  }
                >
                  <option value="">{t('sensor.dataSourceSelect')}</option>
                  <option value={Config.TypeOfSource.GaugeSource.sensor}>
                    {t('setting.sensor')}
                  </option>
                  <option value={Config.TypeOfSource.GaugeSource.variable}>
                    {t('setting.variable')}
                  </option>
                </Select>
              </FormControl>
              {selectedSourceType === Config.TypeOfSource.GaugeSource.sensor ? (
                <FormControl>
                  <label> {t('sensor.sensorDisplay')}</label>
                  <Select
                    name={`attribute[${index}].sensorRef`}
                    {...register(`attribute[${index}].sensorRef`)}
                    defaultValue={attr.sensorRef}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genRefStringList()}
                  </Select>
                </FormControl>
              ) : (
                <FormControl>
                  <label> {t('variable.variableDisplay')}</label>
                  <Select
                    name={`attribute[${index}].variableId`}
                    {...register(`attribute[${index}].variableId`)}
                    defaultValue={attr.variableId}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genVariableList()}
                  </Select>
                </FormControl>
              )}
              <FormControl>
                <label>{t('utility.unitOfValue')}</label>
                <Input
                  type="text"
                  defaultValue={attr.unit}
                  name={`attribute[${index}].unit`}
                  {...register(`attribute[${index}].unit`)}
                  // ref={register}
                  size="sm"
                />
              </FormControl>
            </>
          )}
          {selectedPreferDataType === Config.DataType.DataType.boolean && (
            <>
              <FormControl>
                <label>{t('sensor.dataSource')}</label>
                <Select
                  name={`attribute[${index}].dataSource`}
                  {...register(`attribute[${index}].dataSource`)}
                  defaultValue={attr.dataSource}
                  size="sm"
                  // ref={register}
                  value={selectedSourceType}
                  onChange={(event) =>
                    setSelectedSourceType(event.target.value)
                  }
                >
                  <option value="">{t('sensor.dataSourceSelect')}</option>
                  <option value={Config.TypeOfSource.GaugeSource.sensor}>
                    {t('setting.sensor')}
                  </option>
                  <option value={Config.TypeOfSource.GaugeSource.variable}>
                    {t('setting.variable')}
                  </option>
                </Select>
              </FormControl>
              {selectedSourceType === Config.TypeOfSource.GaugeSource.sensor ? (
                <FormControl>
                  <label> {t('sensor.sensorDisplay')}</label>
                  <Select
                    name={`attribute[${index}].sensorRef`}
                    {...register(`attribute[${index}].sensorRef`)}
                    defaultValue={attr.sensorRef}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genRefStringList()}
                  </Select>
                </FormControl>
              ) : (
                <FormControl>
                  <label> {t('variable.variableDisplay')}</label>
                  <Select
                    name={`attribute[${index}].variableId`}
                    {...register(`attribute[${index}].variableId`)}
                    defaultValue={attr.variableId}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genVariableList()}
                  </Select>
                </FormControl>
              )}
              <FormControl>
                <Checkbox
                  defaultChecked={attr.isActiveHigh}
                  name={`attribute[${index}].isActiveHigh`}
                  {...register(`attribute[${index}].isActiveHigh`)}
                  // ref={register}
                  size="sm"
                >
                  {t('variable.value')} Active High
                </Checkbox>
              </FormControl>
              <FormControl>
                <label>{t('variable.wordDisplay')} Active</label>
                <Input
                  type="text"
                  defaultValue={attr.labelActive}
                  name={`attribute[${index}].labelActive`}
                  {...register(`attribute[${index}].labelActive`)}
                  // ref={register}
                  size="sm"
                />
              </FormControl>
              <FormControl>
                <label>{t('variable.wordDisplay')} Inactive</label>
                <Input
                  type="text"
                  defaultValue={attr.labelInactive}
                  name={`attribute[${index}].labelInactive`}
                  {...register(`attribute[${index}].labelInactive`)}
                  // ref={register}
                  size="sm"
                />
              </FormControl>
            </>
          )}

          {selectedPreferDataType === Config.DataType.DataType.string && (
            <>
              <FormControl>
                <label>{t('sensor.dataSource')}</label>
                <Select
                  name={`attribute[${index}].dataSource`}
                  {...register(`attribute[${index}].dataSource`)}
                  defaultValue={attr.dataSource}
                  size="sm"
                  // ref={register}
                  value={selectedSourceType}
                  onChange={(event) =>
                    setSelectedSourceType(event.target.value)
                  }
                >
                  <option value="">{t('sensor.dataSourceSelect')}</option>
                  <option value={Config.TypeOfSource.GaugeSource.sensor}>
                    {t('setting.sensor')}
                  </option>
                  <option value={Config.TypeOfSource.GaugeSource.variable}>
                    {t('setting.variable')}
                  </option>
                </Select>
              </FormControl>
              {selectedSourceType === Config.TypeOfSource.GaugeSource.sensor ? (
                <FormControl>
                  <label> {t('sensor.sensorDisplay')}</label>
                  <Select
                    name={`attribute[${index}].sensorRef`}
                    {...register(`attribute[${index}].sensorRef`)}
                    defaultValue={attr.sensorRef}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genRefStringList()}
                  </Select>
                </FormControl>
              ) : (
                <FormControl>
                  <label> {t('variable.variableDisplay')}</label>
                  <Select
                    name={`attribute[${index}].variableId`}
                    {...register(`attribute[${index}].variableId`)}
                    defaultValue={attr.variableId}
                    // ref={register}
                    size="sm"
                  >
                    <option value=""> {t('sensor.dataSourceSelect')}</option>
                    {genVariableList()}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {selectedPreferDataType === Config.DataType.DataType.inputText && (
            <di>
              <FormControl>
                <label> input text</label>
                <Input
                  type="text"
                  defaultValue={attr.inputText}
                  name={`attribute[${index}].inputText`}
                  {...register(`attribute[${index}].inputText`)}
                  // ref={register}
                  size="sm"
                />
              </FormControl>
            </di>
          )}
          <div className="mt-6">
            <Button
              isFullWidth
              colorScheme="blue"
              size="sm"
              onClick={() => {
                newList(true)
              }}
            >
              {t('utility.addNewList')}
            </Button>
          </div>
        </Box>
      )
    case config.ComponentType.DiagramType.MeterColors.value:
      return (
        <Box
          key={index}
          padding="4"
          borderRadius="lg"
          borderWidth="2"
          className="text-sm font-semibold border mb-2"
        >
          <FormControl>
            <label className="font-semibold">ขนาดของมิเตอร์</label>
            <Slider
              defaultValue={diagramData.imageSize}
              min={0}
              max={100}
              step={10}
              name="imageSize"
              {...register('imageSize')}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </FormControl>

          <FormControl>
            <label>{t('sensor.dataSource')}</label>
            <Select
              name={`attribute[${index}].dataSource`}
              {...register(`attribute[${index}].dataSource`)}
              defaultValue={attr.dataSource}
              size="sm"
              // ref={register}
              value={selectedSourceType}
              onChange={(event) => setSelectedSourceType(event.target.value)}
            >
              <option value="">{t('sensor.dataSourceSelect')}</option>
              <option value={Config.TypeOfSource.GaugeSource.sensor}>
                {t('setting.sensor')}
              </option>
              <option value={Config.TypeOfSource.GaugeSource.variable}>
                {t('setting.variable')}
              </option>
            </Select>
          </FormControl>
          {selectedSourceType === Config.TypeOfSource.GaugeSource.sensor ? (
            <FormControl>
              <label> {t('sensor.sensorDisplay')}</label>
              <Select
                name={`attribute[${index}].sensorRef`}
                {...register(`attribute[${index}].sensorRef`)}
                defaultValue={attr.sensorRef}
                // ref={register}
                size="sm"
              >
                <option value=""> {t('sensor.dataSourceSelect')}</option>
                {genRefStringList()}
              </Select>
            </FormControl>
          ) : (
            <FormControl>
              <label> {t('variable.variableDisplay')}</label>
              <Select
                name={`attribute[${index}].variableId`}
                {...register(`attribute[${index}].variableId`)}
                defaultValue={attr.variableId}
                // ref={register}
                size="sm"
              >
                <option value=""> {t('sensor.dataSourceSelect')}</option>
                {genVariableList()}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <label>{t('utility.unitOfValue')}</label>
            <Input
              type="text"
              defaultValue={attr.unit}
              name={`attribute[${index}].unit`}
              {...register(`attribute[${index}].unit`)}
              // ref={register}
              size="sm"
            />
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold">ค่าสูงที่สุดที่ในเกจ</label>
            <Input
              type="number"
              name={`attribute[${index}].high`}
              {...register(`attribute[${index}].high`)}
              size="sm"
              defaultValue={attr.high}
              // ref={register}
            />
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold"> ค่าต่ำที่สุดในเกจ</label>
            <Input
              type="number"
              name={`attribute[${index}].low`}
              {...register(`attribute[${index}].low`)}
              size="sm"
              defaultValue={attr.low}
              // ref={register}
            />
          </FormControl>
        </Box>
      )
    case config.ComponentType.DiagramType.MeterClassic.value:
      return (
        <Box
          key={index}
          padding="4"
          borderRadius="lg"
          borderWidth="2"
          className="text-sm font-semibold border mb-2"
        >
          <FormControl>
            <label className="font-semibold">ขนาดของมิเตอร์</label>
            <Slider
              defaultValue={diagramData.imageSize}
              min={0}
              max={100}
              step={10}
              name="imageSize"
              {...register('imageSize')}
              // ref={register}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </FormControl>

          <FormControl>
            <label>{t('sensor.dataSource')}</label>
            <Select
              name={`attribute[${index}].dataSource`}
              {...register(`attribute[${index}].dataSource`)}
              defaultValue={attr.dataSource}
              size="sm"
              //ref={register}
              value={selectedSourceType}
              onChange={(event) => setSelectedSourceType(event.target.value)}
            >
              <option value="">{t('sensor.dataSourceSelect')}</option>
              <option value={Config.TypeOfSource.GaugeSource.sensor}>
                {t('setting.sensor')}
              </option>
              <option value={Config.TypeOfSource.GaugeSource.variable}>
                {t('setting.variable')}
              </option>
            </Select>
          </FormControl>
          {selectedSourceType === Config.TypeOfSource.GaugeSource.sensor ? (
            <FormControl>
              <label> {t('sensor.sensorDisplay')}</label>
              <Select
                name={`attribute[${index}].sensorRef`}
                {...register(`attribute[${index}].sensorRef`)}
                defaultValue={attr.sensorRef}
                //ref={register}
                size="sm"
              >
                <option value=""> {t('sensor.dataSourceSelect')}</option>
                {genRefStringList()}
              </Select>
            </FormControl>
          ) : (
            <FormControl>
              <label> {t('variable.variableDisplay')}</label>
              <Select
                name={`attribute[${index}].variableId`}
                {...register(`attribute[${index}].variableId`)}
                defaultValue={attr.variableId}
                // ref={register}
                size="sm"
              >
                <option value=""> {t('sensor.dataSourceSelect')}</option>
                {genVariableList()}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <label>{t('utility.unitOfValue')}</label>
            <Input
              type="text"
              defaultValue={attr.unit}
              name={`attribute[${index}].unit`}
              {...register(`attribute[${index}].unit`)}
              // ref={register}
              size="sm"
            />
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold">ค่าสูงที่สุดที่ในเกจ</label>
            <Input
              type="number"
              name={`attribute[${index}].high`}
              {...register(`attribute[${index}].high`)}
              size="sm"
              defaultValue={attr.high}
              // ref={register}
            />
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold"> ค่าต่ำที่สุดในเกจ</label>
            <Input
              type="number"
              name={`attribute[${index}].low`}
              {...register(`attribute[${index}].low`)}
              size="sm"
              defaultValue={attr.low}
              // ref={register}
            />
          </FormControl>
        </Box>
      )
    default:
      return <div></div>
  }
}
