import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../redux/actions'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@chakra-ui/react'
import ModalTemplateUpload from './Components/ModalTemplateUpload'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import TemplateList from './Components/TemplateList'

export default function Diagrams() {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [isTemplateUploadOpen, setisTemplateUploadOpen] = useState(false)
    const dispatch = useDispatch()
    const templates = useSelector((state) => state.stationTemplate)

    const closeTemplateUploadModal = () => {
        setisTemplateUploadOpen(false)
    }
    useEffect(() => {
        dispatch(actions.stationTemplateAll())
        return () => {}
    }, [])

    useEffect(() => {
        setIsLoading(true)
        return () => {}
    }, [])

    return isLoading ? (
      <>
        <ModalTemplateUpload
          isOpen={isTemplateUploadOpen}
          onClose={closeTemplateUploadModal}
        />
        <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                  {t('setting.systemManagement')}
                </h6>
                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                  {t('setting.allStationType')}
                </h2>
              </div>
            </div>
          </div>
          <div className="flex justify-start p-4 space-x-1">
            <Button
              colorScheme="blue"
              variant="solid"
              size="sm"
              onClick={() => setisTemplateUploadOpen(true)}
            >
              {t('utility.upload')}
            </Button>
          </div>
          <div className="p-4 w-full">
            {templates && templates.arr && !_.isEmpty(templates.arr) ? (
              <div className="overflow-x-auto">
                <TemplateList templateArray={templates.arr} />
              </div>
                    ) : (
                      <p className=" text-base mb-6">
                        {t('template.emptyTemplate')}
                      </p>
                    )}
          </div>
        </div>
      </>
    ) : (
      <SpinnerLoading />
    )
}
