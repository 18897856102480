import React from 'react'
import _ from 'lodash'
import { Checkbox, FormControl, Input, Select } from '@chakra-ui/react'
import Config from '../../../../../config'

export default function EditStaticNumberDisplay({
  gaugeData,
  register,
  stationData,
  gaugeSource,
}) {
  const genRefStringList = () => {
    return _.map(stationData.sensors, (sensor) => (
      <option value={sensor.refString}>{sensor.refString}</option>
    ))
  }
  const genVariableList = () => {
    return _.map(stationData.variables, (variable) => (
      <option value={variable._id}>{variable.variableName}</option>
    ))
  }
  return (
    <>
      {gaugeSource === Config.TypeOfSource.GaugeSource.sensor ? (
        <FormControl>
          <label className="text-sm font-bold">
            {' '}
            เซ็นเซอร์ที่ต้องการเชื่อมต่อ{' '}
          </label>
          <Select
            defaultValue={gaugeData.sensorRefString}
            {...register('sensorRefString')}
            size="sm"
            name="sensorRefString"
          >
            {genRefStringList()}
          </Select>
        </FormControl>
      ) : (
        <FormControl>
          <label className="text-sm font-bold">
            {' '}
            ตัวแปรที่ต้องการเชื่อมต่อ{' '}
          </label>
          <Select
            defaultValue={gaugeData.variableId}
            {...register('variableId')}
            size="sm"
            name="variableId"
          >
            {genVariableList()}
          </Select>
        </FormControl>
      )}
      <FormControl>
        <label className="text-sm font-bold">ตัวคูณหน่วย</label>
        <Input
          type="text"
          name="valueMultiUnit"
          size="sm"
          defaultValue={gaugeData.valueMultiUnit}
          {...register('valueMultiUnit')}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">หน่วย</label>
        <Input
          type="text"
          name="valueUnit"
          size="sm"
          defaultValue={gaugeData.valueUnit}
          {...register('valueUnit')}
        />
      </FormControl>
    </>
  )
}
