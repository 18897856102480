import React from 'react'
import { Button, Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import SensorRequireAttribute from '../../../views/system/management-statations/Components/SensorRequireAttribute'

export default function MultiInputForm({
  register,
  inputType,
  setInputType,
  append,
  remove,
  fields,
  // inputSensor,
  // setInputSensor,
  station,
}) {
  const { t, i18n } = useTranslation()
  const handleRemove = (index) => {
    remove(index)
  }

  return (
    <div className="w-full  px-4">
      <div>
        <div className="w-full  px-4">
          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ชื่อฟอร์ม
              </label>
              <input
                name="name"
                type="text"
                required
                {...register('name')}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Meter"
              />
            </div>
          </div>
        </div>

        <div className="w-full px-4">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              ชื่อหัวข้อใหญ่
            </label>
            <input
              name="topic"
              type="text"
              required
              {...register('topic')}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="มิเตอร์"
            />
          </div>
        </div>

        <div className="w-full  px-4">
          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                คำอธิบายหัวข้อใหญ่
              </label>
              <input
                name="description"
                type="text"
                {...register('description')}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="ใช้สำหรับมิเตอร์"
              />
            </div>
          </div>
          {_.map(fields, (row, index) => (
            <Box
              key={row?.id}
              padding="4"
              borderRadius="lg"
              borderWidth="2"
              className="text-sm font-semibold border mb-2"
            >
              <div className="flex justify-between">
                <h5 className="mb-2">หัวข้อย่อยที่ {index + 1} </h5>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => handleRemove(index)}
                >
                  {t('utility.delete')}
                </Button>
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อหัวข้อย่อย
                </label>
                <input
                  name={`attributeArray.${index}.subTopic`}
                  type="text"
                  required
                  {...register(`attributeArray.${index}.subTopic`)}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="อุณหภูมิ"
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  คำอธิบายหัวข้อย่อย
                </label>
                <input
                  name={`attributeArray.${index}.subDesc`}
                  type="text"
                  {...register(`attributeArray.${index}.subDesc`)}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ใช้สำหรับระบุ..."
                />
              </div>

              <label>{t('utility.valueType')}</label>
              <select
                name={`attributeArray.${index}.subInputType`}
                required
                {...register(`attributeArray.${index}.subInputType`)}
                //onChange={(event) => setInputType(event.target.value)}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              >
                <option value="DATE">Date</option>
                <option value="TIME">Time</option>
                <option value="NUMBER">Number</option>
                <option value="TEXT">Text</option>
              </select>
              <div className="relative w-full mb-3 py-3 ">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Required
                </label>
                <select
                  name={`attributeArray.${index}.required`}
                  {...register(`attributeArray.${index}.required`)}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              {/* <div className="w-full py-2">
                <SensorRequireAttribute
                  inputSensor={inputSensor}
                  setInputSensor={setInputSensor}
                  register={register}
                  station={station}
                  index={index}
                />
              </div> */}
            </Box>
          ))}
          <Button
            isFullWidth
            colorScheme="blue"
            size="sm"
            onClick={() => append({ subTopic: '', inputType: '' })}
          >
            {t('utility.addNewList')}
          </Button>

          {/* <div className="relative w-full  mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Required
            </label>
            <select
              name="required"
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div> */}
          {inputType === 'DATE' && (
            <div>
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ตัวอย่างฟอร์ม
              </label>
              <Box
                maxW="sm"
                borderWidth="1px"
                borderRadius="lg"
                className=" ml-4 my-2 p-4 w-full h-32"
              >
                <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                  วันที่
                </label>
                <input
                  readOnly
                  type="date"
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                />
              </Box>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
