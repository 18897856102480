/* eslint-disable radix */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import React, { Component, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import dayjs from 'dayjs'

export default function YieldCumulaitve({ valueYieldCPO }) {
  console.log('valueYieldCPO', valueYieldCPO)
  const [tickAmount, setTickAmount] = useState(valueYieldCPO.length - 1)
  const [valueFormat, setValueFormat] = useState('DD MMM')
  const chartMargin = (valueYieldCPO[0]?.y * 10) / 100
  const series = [
    {
      name: 'ปริมาณ Yield ที่ได้',
      type: 'line',
      data: valueYieldCPO,
    },
  ]
  const options = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3],
    },
    markers: {
      size: 5,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => dayjs(value).locale('th').format(valueFormat),
      },
      tickAmount,
    },
    yaxis: [
      {
        min: parseInt(valueYieldCPO[0]?.y) - parseInt(chartMargin),
        max: parseInt(valueYieldCPO[0]?.y) + parseInt(chartMargin),
        //stepSize: 5,
        // axisBorder: {
        //   show: true,
        //   color: '#FEB019',
        // },
        labels: {
          formatter: function (val) {
            return val.toFixed(2)
          },

          // style: {
          //   colors: '#FEB019',
          // },
        },
        title: {
          text: 'Yield ที่ได้ (เปอร์เซ็นต์)',
          // style: {
          //   color: '#FEB019',
          // },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    // legend: {
    //   horizontalAlign: 'left',
    //   offsetX: 40,
    // },
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  )
}
