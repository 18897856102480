export const DataType = {
  boolean: 'BOOLEAN',
  number: 'NUMBER',
  string: 'STRING',
  inputText: 'INPUT_TEXT',
  twoStepBoolean: 'TWO_STEP_BOOLEAN',
  staticMeter: 'Static Meter',
  meterColors: 'Meter Colors',
  meterClassic: 'Meter Classic',
}

export default { DataType }
