/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../redux/actions'
import {
  Button,
  Checkbox,
  Stack,
  CheckboxGroup,
  useCheckboxGroup,
} from '@chakra-ui/react'
import ModalStationCreateConfirmation from '../Components/ModalStationCreateConfirmation'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'

export default function CreateStation() {
  const { t } = useTranslation()
  const { register, handleSubmit, reset } = useForm()
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
  const [templateId, setTemplateId] = useState('')
  const [formData, setFormData] = useState()
  const allTemplates = useSelector((state) => state.stationTemplate)
  const dispatch = useDispatch()
  console.log('Create Station is Reload')

  useEffect(() => {
    dispatch(actions.stationTemplateAll())
    return () => {}
  }, [])

  useEffect(() => {
    if (allTemplates) {
      genTemplateList()
    }
    return () => {}
  }, [allTemplates])

  const { isCheckBox } = useCheckboxGroup()
  console.log('isCheckBox = ', isCheckBox)

  const genTemplateList = () => {
    if (allTemplates && allTemplates.arr) {
      return _.map(allTemplates.arr, (stationTheme) => (
        <option value={stationTheme._id}>{stationTheme.templateName}</option>
      ))
    }
    return <div></div>
  }

  const onSubmit = async (data, e) => {
    console.log('XXXXXXXXXXXXXXXXXX', data)
    e.preventDefault()
    console.log('From Data', data)
    setTemplateId(data.template)
    setFormData(data)
    setIsModalConfirmOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalConfirmOpen(false)
  }

  return allTemplates && allTemplates.arr ? (
    <>
      <ModalStationCreateConfirmation
        isOpen={isModalConfirmOpen}
        onClose={handleCloseModal}
        templateId={templateId}
        formData={formData}
      />
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-base font-sans font-bold">
                    {t('setting.createStation')}
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="text-gray-500 text-base font-sans mt-3 mb-6 font-bold uppercase">
                    {t('dashboard.stationInfo')}
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('setting.nameStation')}
                        </label>
                        <input
                          name="name"
                          type="text"
                          required
                          {...register('name')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder={t('setting.nameStationDes')}
                        />
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase  text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('setting.stationDescrip')}
                        </label>
                        <textarea
                          name="description"
                          type="textarea"
                          {...register('description')}
                          className="px-3 py-3  placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder={t('setting.stationDescripDes')}
                        />
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('setting.addressStation')}
                        </label>

                        <input
                          name="location"
                          type="text"
                          {...register('location')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder={t('setting.addressStationDes')}
                        />
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('setting.refStation')}
                        </label>
                        <label
                          className="block  text-gray-500 text-xs font-normal mb-2"
                          htmlFor="grid-password"
                        >
                          {t('setting.refStationDes')}
                        </label>
                        <input
                          name="externalRef"
                          type="text"
                          required
                          {...register('externalRef')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="001"
                        />
                      </div>
                    </div>

                    {/** End of Input */}
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        โมดูล
                      </label>
                      <label
                        className="block  text-gray-500 text-xs font-normal mb-2"
                        htmlFor="grid-password"
                      >
                        เลือกโมดูลที่ต้องการใช้งาน
                      </label>
                      <Checkbox
                        name="moduleElectricBill"
                        {...register('moduleElectricBill')}
                      >
                        โมดูลประมาณการบิลค่าไฟ
                      </Checkbox>
                      <Checkbox
                        name="moduleCounting"
                        {...register('moduleCounting')}
                      >
                        โมดูลสรุปผลการนับ
                      </Checkbox>
                      <Checkbox
                        name="moduleHumanInput"
                        {...register('moduleHumanInput')}
                      >
                        โมดูลการกรอกข้อมูล
                      </Checkbox>
                    </div>
                  </div>

                  <div className="w-full  px-4">
                    <div className="relative w-full  mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        {t('setting.typeStation')}
                      </label>
                      <select
                        name="template"
                        required
                        {...register('template')}
                        onChange={(event) => {
                          setTemplateId(event.target.value)
                        }}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      >
                        {genTemplateList()}
                        <option value="no-template">
                          {t('utility.nothingSelect')}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="text-center px-4 gap-0 py-3 ">
                    <Button
                      isFullWidth
                      colorScheme="blue"
                      variant="solid"
                      type="submit"
                    >
                      {t('setting.createStation')}{' '}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
