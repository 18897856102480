import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button, FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import GaugeTypeData from '../../../../config/GaugeType.json'
import NumberSensorType from '../../../../config/NumberSensorType.json'
import { useTranslation } from 'react-i18next'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'

export default function AddSensor() {
  const { t, i18n } = useTranslation()

  const { register, handleSubmit, reset } = useForm()
  const [sensorType, setsensorType] = useState('BOOLEAN')
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const station = useSelector((state) => state.station)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.stationGet(params.stationId))
    return () => {}
  }, [params])

  useEffect(() => {
    setIsLoading(true)
    return () => {}
  }, [])

  const onSubmit = async (data, e) => {
    let confirm = window.confirm('ยืนยันการเพิ่มเซ็นเซอร์')
    if (confirm) {
      e.preventDefault()
      console.log('From Data', data)
      let templateValue = station

      if (templateValue && templateValue.sensors) {
        data.controlRefString = data.refString
        console.log(templateValue.sensors)
        templateValue.sensors.push(data)
        console.log('PUSHED')
      }
      console.log('New Added Value ', templateValue)
      dispatch(actions.stationPut(params.stationId, templateValue)).then(() => {
        dispatch(actions.stationAll()).then(() => {
          history.goBack()
        })
      })
    }
  }

  if (station && isLoading) {
    return (
      <div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      {t('sensor.addSensorToStation')} {station.name}
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      {t('sensor.sensorInfo')}
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {t('sensor.name')}
                          </label>
                          <input
                            name="name"
                            type="text"
                            required
                            {...register('name')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder={t('sensor.rainSensor')}
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {t('sensor.description')}
                          </label>
                          <input
                            name="description"
                            type="text"
                            {...register('description')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder={t('sensor.sensorDes')}
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {t('sensor.refString')}
                          </label>
                          <label
                            className="block  text-gray-500 text-xs font-normal mb-2"
                            htmlFor="grid-password"
                          >
                            {t('sensor.refStringDescp')}
                          </label>
                          <input
                            name="refString"
                            type="text"
                            {...register('refString')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="rain1"
                          />
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {t('sensor.sensorType')}
                          </label>
                          <select
                            name="sensorType"
                            required
                            {...register('sensorType')}
                            onChange={(event) =>
                              setsensorType(event.target.value)
                            }
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value="BOOLEAN">BOOLEAN</option>
                            <option value="NUMBER">NUMBER</option>
                            <option value="STRING">STRING</option>
                          </select>
                        </div>
                      </div>

                      {/** End of  General Input */}
                      {/** Specific Input
                       * 2020/02/17 ปรับให้มันแยกเพิ่มอีกว่าส่วนไหนให้แสดงเฉพาะ Type ที่เป็น Boolean ส่วนไหนแสดงเฉพาะ Number มันจะได้ไม่มั่ว
                       *
                       */}
                      {sensorType === 'BOOLEAN' && (
                        <>
                          <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.subSensorType')}
                              </label>
                              <label
                                className="block  text-gray-500 text-xs font-normal mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.subSensorTypeDescp')}
                              </label>
                              <select
                                name="gaugeType"
                                required
                                {...register('gaugeType')}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {_.map(
                                  GaugeTypeData,
                                  (eachGaugeType, index) => (
                                    <option
                                      key={index}
                                      value={eachGaugeType.value}
                                    >
                                      {i18n.language === 'en'
                                        ? eachGaugeType.enDescribe
                                        : eachGaugeType.describe}{' '}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.pulseControl')}
                              </label>
                              <label
                                className="block  text-gray-500 text-xs font-normal mb-2"
                                htmlFor="grid-password"
                              ></label>
                              <select
                                name="controlWithPulse"
                                required
                                {...register('controlWithPulse')}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {' '}
                                <option value={false}>{t('control.no')}</option>
                                <option value={true}>{t('control.yes')}</option>
                              </select>
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.defaultValue')}
                              </label>
                              <select
                                name="booleanDefaultValue"
                                defaultValue={false}
                                {...register('booleanDefaultValue')}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                              </select>
                            </div>
                          </div>
                        </>
                      )}

                      {sensorType === 'NUMBER' && (
                        <>
                          <div className="w-full px-4">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              ประเภทของเซนเซอร์
                            </label>
                            <select
                              name="controlType"
                              required
                              {...register('controlType')}
                              // onChange={(event) =>
                              //   setsensorType(event.target.value)
                              // }
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            >
                              <option value="DISPLAY">แสดงค่า</option>
                              <option value="CONTROLNUMBER">
                                ควบคุมค่าด้วยตัวเลข
                              </option>
                              <option value="CONTROLFORM">
                                ควบคุมค่าด้วยฟอร์ม
                              </option>
                            </select>
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              {t('sensor.unit')}
                            </label>
                            <div className="relative w-full mb-3">
                              <input
                                name="valueUnit"
                                type="text"
                                {...register('valueUnit')}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder={t('sensor.cubicCentimeters')}
                              />
                            </div>
                          </div>
                          <div className="w-full px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.subSensorType')}
                              </label>
                              <label
                                className="block  text-gray-500 text-xs font-normal mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.subSensorTypeDes')}
                              </label>
                              <select
                                name="gaugeType"
                                required
                                {...register('gaugeType')}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {_.map(NumberSensorType, (eachType, index) => (
                                  <option key={index} value={eachType.value}>
                                    {eachType.describe}{' '}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="w-full px-4 py-4">
                            <FormControl display="flex">
                              <FormLabel>
                                <div className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                  {t('sensor.thresholdEnable')}
                                </div>
                              </FormLabel>
                              <Switch
                                size="lg"
                                name="enableThreshold"
                                {...register('enableThreshold')}
                              />
                            </FormControl>
                          </div>
                          <div className="w-full px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.upperThreshold')}
                              </label>
                              <label
                                className="block  text-gray-500 text-xs font-normal mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.upperThresholdDescp')}
                              </label>

                              <input
                                name="upperThresholdValue"
                                type="text"
                                {...register('upperThresholdValue')}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder={t('sensor.upperThresholdDes')}
                              />
                            </div>
                          </div>
                          <div className="w-full px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.lowerThreshold')}
                              </label>
                              <label
                                className="block  text-gray-500 text-xs font-normal mb-2"
                                htmlFor="grid-password"
                              >
                                {t('sensor.lowerThresholdDescp')}
                              </label>

                              <input
                                name="lowerThresholdValue"
                                type="text"
                                {...register('lowerThresholdValue')}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder={t('sensor.lowerThresholdDes')}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        {t('utility.save')}{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
