import React, { useState, useEffect } from 'react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'
import EstimateBillLists from './components/EstimateBillLists'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { stationAll } from '../../redux/actions'

export default function ElectricBillEstimator() {
  const { t } = useTranslation()
  const [allowedDashboard, setAllowedDashboard] = useState()
  const me = useSelector((state) => state.me)
  const countingSummary = useSelector((state) => state.countingSummary)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const electricConsumption = useSelector((state) => state.electricConsumption)
  console.log('Electric consumtion ------ปปปปป-------', electricConsumption)
  const systemInfo = useSelector((state) => state.system)
  console.log('Systeminfo ----------', systemInfo)
  const stations = useSelector((state) => state.station)
  console.log('allStation ----------', stations)
  const params = useParams()
  console.log('Params ', params)

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => setIsLoading(true))
    return () => {}
  }, [])

  useEffect(() => {
    dispatch(actions.electricConsumptionAll()).then(() => {
      dispatch(actions.systemInfoGet()).then(() => {
        setIsLoading(true)
      })
    })

    return () => {}
  }, [])

  useEffect(() => {
    dispatch(stationAll()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  // ตรวจสอบว่า User ที่เข้ามามี Permission ที่เข้าสถานีไหนได้บ้าง
  // ตอนนี้ยังเก็บเป็น Text อยู่ เก็บเฉพาะใน User ไม่ได้เก็บในสถานี
  // ถ้าอนุญาติหมดจะเป็น ALLAREA
  // TODO: ถ้าเป็น Array จะดีมาก
  useEffect(() => {
    if (me && me._id) {
      if (_.size(stations.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          const allowedStationStack = stations.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          const allowedStation = _.filter(
            stations.arr,
            (station, index) => station._id === me.duty[index]
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [stations, me])

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('electricBill.electricBill')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('electricBill.electricBill')}
            </h2>
          </div>
        </div>
        <div className="p-4 w-full">
          {_.size(electricConsumption.arr) ? (
            <div className="overflow-x-auto">
              <EstimateBillLists
                systemInfo={systemInfo}
                electricConsumption={_.reverse(electricConsumption.arr)}
                stationId={params.id}
              />
            </div>
          ) : (
            <div>{t('utility.empty')}</div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
