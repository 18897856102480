/* eslint-disable react/no-deprecated */
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import { Provider } from 'react-redux'
import configureStore from './redux/configureStore'

import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/styles/index.css'
import './assets/styles/print.css'
import './i18n'

//import './assets/styles/tailwind.css'

//PrivateRoute Contexts
import PrivateRoute from './contexts/PrivateRoute'
//AuthProvider Contexts
import AuthProvider from './contexts/AuthContext'

//Home or Portal Menu
import Home from './views/Home'

// layouts
import Auth from './layouts/Auth'

import Dashboard from './layouts/Dashboard'
import Controls from './layouts/Controls'
import Statistics from './layouts/Statistics'
import ElectricBillEstimator from './layouts/EBE'
import Notify from './layouts/Notify'
import System from './layouts/System'
import CountingSummary from './layouts/CountingSummary'
import HumanIput from './layouts/HumanInput'
//import Prediction from './layouts/Prediction'
import Summarize from './layouts/Summarize'
import { ChakraProvider } from '@chakra-ui/react'
import { ThemeProvider } from '@mui/material'
import muiTheme from './muiTheme'
import SpinnerLoading from './components/Loading/SpinnerLoading'

const store = configureStore()
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
}

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <AuthProvider>
        {' '}
        <ThemeProvider theme={muiTheme}>
          {' '}
          <ChakraProvider>
            <Suspense fallback={<SpinnerLoading />}>
              <div className="font-sans">
                <Switch>
                  {/* add routes with layouts */}
                  <Route path="/auth" component={Auth} />
                  {/*PrivateRoute*/}
                  <PrivateRoute>
                    <Route exact path="/" component={Home} />
                    <Route path="/dashboards" component={Dashboard} />
                    <Route path="/controls" component={Controls} />
                    <Route path="/statistics" component={Statistics} />
                    <Route
                      path="/electric-bill-estimator"
                      component={ElectricBillEstimator}
                    />
                    <Route
                      path="/counting-summary"
                      component={CountingSummary}
                    />
                    <Route path="/humaninput" component={HumanIput} />
                    {/* <Route path="/prediction" component={Prediction} /> */}
                    <Route path="/summarize" component={Summarize} />
                    <Route path="/notify" component={Notify} />
                    <Route path="/system" component={System} />
                    {/* <Redirect from="*" to="/" /> */}
                  </PrivateRoute>
                  {/* add redirect for NotFound page */}
                  <Redirect from="*" to="/auth" />
                </Switch>
              </div>{' '}
            </Suspense>{' '}
          </ChakraProvider>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
)
