import axios from 'axios'
import { ME_GET, ME_RESET } from '../types'
import { setStorage, removeStorage } from 'util/localstorage'

export const meGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/me/' + payload)
      .then((res) => {
        console.log('Request Server to Get Authorize')
        if (res.data) dispatch({ type: ME_GET, payload: res.data })
      })
  }
}

export const meReset = () => {
  return async (dispatch) => {
    dispatch({ type: ME_RESET, payload: null })
  }
}

export const meSignout = () => {
  return async (dispatch) => {
    removeStorage('token')
    removeStorage('eiot_remember')
    dispatch({ type: ME_RESET, payload: null })
  }
}
