import React, { useState, useEffect } from 'react'
import { Button, Box, Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import axios from 'axios'

export default function FetchSubexpression({
  register,
  append,
  remove,
  fields,
  station,
  control,
}) {
  const dispatch = useDispatch()
  const summarizes = useSelector((state) => state.summarize)
  const { t, i18n } = useTranslation()
  const handleRemove = (index) => {
    remove(index)
  }

  const [summarizeAll, setSummarize] = useState([])

  useEffect(() => {
    dispatch(actions.stationAll())

    axios.get(process.env.REACT_APP_API_URL + '/summarize').then((res) => {
      setSummarize(res.data)
    })
    return () => {}
  }, [])

  console.log('summarizeAll', summarizeAll)

  const findData = (id) => {
    const data = _?.find(summarizes?.arr, (each) => each?._id === id)
    return data
  }

  return (
    <div className="w-full  px-4">
      <div>
        <div className="w-full  px-4">
          {_.map(fields, (row, index) => (
            <Box
              key={row?.id}
              padding="4"
              borderRadius="lg"
              borderWidth="2"
              className="text-sm font-semibold border mb-2"
            >
              {console.log('Field', row)}
              <div className="flex justify-between">
                <h5 className="mb-2">พจน์ที่ {index + 1} </h5>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => handleRemove(index)}
                >
                  {t('utility.delete')}
                </Button>
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  เลือกข้อมูลพจน์
                </label>
                {console.log('Log', row?.variable?._id)}
                <Controller
                  name={`varComponent.${index}`}
                  control={control}
                  render={(field) => (
                    <Select
                      // ref={field.ref}
                      {...register(`varComponent.${index}`)}
                      size="sm"
                      name={`varComponent.${index}`}
                      placeholder="เลือกข้อมูล"
                      onChange={(e) => {
                        const selectData = findData(e?.target?.value)
                        console.log('Select Data', selectData)
                        field.onChange(selectData)
                      }}
                      defaultValue={row?.variable?._id}
                    >
                      {/* ใช้ตัวนี้เป็นการรับ Source ว่าเป็น Sensor หรือ Varible หรือ การกรอกข้อมูล */}
                      {_.map(summarizeAll, (pt, key) => {
                        return (
                          <option value={pt?._id}>
                            {pt?.variable?.variableName}
                          </option>
                        )
                      })}
                    </Select>
                  )}
                />
              </div>
            </Box>
          ))}
          <Button
            isFullWidth
            colorScheme="blue"
            size="sm"
            onClick={() => append({})}
          >
            {t('utility.addNewList')}
          </Button>
        </div>
      </div>
    </div>
  )
}
