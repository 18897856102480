import React from 'react'
import config from '../../../config'
import _ from 'lodash'

import { DisplayMeterColorsAttribute, DisplayMeterClassicAttribute, DisplayTextString, DisplayTextAttribute, DisplayImageAttribute } from '../Display/Attribute'
/**
 * @function DiagramRenderingComponent
 * @description ฟังก์ชันสำหรับแสดง Diagram Component
 * @typedef {Object} DiagramRenderingComponentObject
 * @property {Object}  stationData Object ข้อมูลของ Station
 * @property {Object} attributeData ตัวหนึ่งใน Array ของ Attribute
 * @property {Object} diagramData Object ของ Diagram
 * @property {Number} staticValue ค่าตรงๆ ของ Diagram
 * @param {DiagramRenderingComponentObject} diagramRenderingComponentObject
 * @returns
 */
function DiagramRenderingComponent({
  stationData,
  attributeData,
  staticValue = 0,
  diagramData,
}) {
  switch (diagramData?.diagramType) {
    case config.ComponentType.DiagramType.Image.value:
      return (
        <DisplayImageAttribute
          attributeData={attributeData}
          stationData={stationData}
          diagramData={diagramData}
        />
      )
    case config.ComponentType.DiagramType.Text.value:
      return (
        <div>
          <DisplayTextAttribute
            attributeData={attributeData}
            stationData={stationData}
            staticValue={staticValue}
            diagramData={diagramData}
          />
        </div>
      )
    case config.ComponentType.DiagramType.String.value:
      return (
        <div>
          <DisplayTextString
            attributeData={attributeData}
            stationData={stationData}
            staticValue={staticValue}
            diagramData={diagramData}
          />
        </div>
      )
    case config.ComponentType.DiagramType.MeterColors.value:
      return (
        <div>
          <DisplayMeterColorsAttribute
            attributeData={attributeData}
            stationData={stationData}
            graphLabel={attributeData?.label}
            highResolution={attributeData?.high}
            lowResolution={attributeData?.low}
            staticData={staticValue}
            unit={attributeData?.unit}
            valueLable={attributeData?.valueNote}
          />
        </div>
      )
    case config.ComponentType.DiagramType.MeterClassic.value:
      return (
        <div>
          <DisplayMeterClassicAttribute
            attributeData={attributeData}
            stationData={stationData}
            graphLabel={attributeData?.label}
            highResolution={attributeData?.high}
            lowResolution={attributeData?.low}
            staticData={staticValue}
            unit={attributeData?.unit}
            valueLable={attributeData?.valueNote}
          />
        </div>
      )
    default:
      return (
        <div>
          Please !!! <br />
          Select Type of Diagram.
        </div>
      )
  }
}

export default DiagramRenderingComponent
