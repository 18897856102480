import React from 'react'
import _ from 'lodash'
import { Checkbox, FormControl, Input, Select } from '@chakra-ui/react'

/**
 * @function EditBooleanWaterDisplay
 * @deprecated since 2021-04-15
 */

export default function EditBooleanWaterDisplay({
  gaugeData,
  register,
  stationData,
}) {
  const genRefStringList = () => {
    return _.map(stationData.sensors, (sensor) => (
      <option value={sensor.refString}>{sensor.refString}</option>
    ))
  }
  return (
    <div>
      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          เซ็นเซอร์ที่ต้องการเชื่อมต่อ{' '}
        </label>
        <Select
          defaultValue={gaugeData.sensorRefString}
          ref={register}
          size="sm"
          name="sensorRefString"
        >
          {genRefStringList()}
        </Select>
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          คำที่จะแสดงเมื่อเป็นค่า High{' '}
        </label>
        <Input
          type="text"
          name="valueLabel.high"
          size="sm"
          defaultValue={gaugeData.valueLabel?.high}
          ref={register}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          ค่าที่จะแสดงเมื่อเป็นค่า Low{' '}
        </label>
        <Input
          type="text"
          name="valueLabel.low"
          size="sm"
          defaultValue={gaugeData.valueLabel?.low}
          ref={register}
        />
      </FormControl>
      <FormControl>
        <Checkbox
          type="text"
          name="isActiveHigh"
          size="sm"
          defaultChecked={gaugeData.isActiveHigh}
          ref={register}
        >
          <label className="text-sm font-bold"> ค่า Active High </label>
        </Checkbox>
      </FormControl>
    </div>
  )
}
