/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import { Box } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function StaticMeter({
  staticData,
  graphLabel,
  unit,
  highResolution,
  lowResolution,
  valueLable,
  favoriteButton,
  link = null,
  size = 'md', // md or sm,
}) {
  const sizeSelector = {
    md: {
      mainData: 'text-4xl',
      label: 'text-2xl',
      weight: 'font-bold',
      unit: 'text-xl',
    },
    sm: {
      mainData: 'text-xl',
      label: 'text-normal',
      weight: 'font-bold',
      unit: 'text-normal',
    },
  }
  const series = [(staticData * 100) / (highResolution - lowResolution)]
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            formatter: (val) =>
              staticData !== ''
                ? parseFloat(staticData).toFixed(2) + ' ' + unit
                : ' ' + unit,
            offsetY: 5,
          },
        },
      },
    },
    // stroke: {
    //   dashArray: 4,
    // },
    labels: [valueLable ? valueLable : ''],
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-4 w-full  h-25"
    >
      {favoriteButton}
      {link ? (
        <Link
          to={link}
          className={`${sizeSelector[size]?.weight || 'font-bold'}  
          ${sizeSelector[size]?.label || 'text-2xl'}  ${
            favoriteButton ? 'px-2' : ''
          } hover:text-gray-600`}
        >
          {graphLabel}
        </Link>
      ) : (
        <h5
          className={`${sizeSelector[size]?.weight || 'font-bold'}  
          ${sizeSelector[size]?.label || 'text-2xl'}  ${
            favoriteButton ? 'px-2' : ''
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: graphLabel }}></div>
        </h5>
      )}
      <Chart height="270" series={series} options={options} type="radialBar" />{' '}
    </Box>
  )
}
