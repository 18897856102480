import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Code,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export default function SensorComponent({
  stationData,
  sensorData,
  placeToEdit = 'station',
}) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()

  const prepareUpdateDatabase = (data) => {
    console.log('data', data)
    // Find Sensor that user have edit
    const selectedSensor = _.find(
      stationData.sensors,
      (sensor) => sensor._id === sensorData._id
    )
    const selectedSensorIndex = _.findIndex(
      stationData.sensors,
      (sensor) => sensor._id === sensorData._id
    )
    const payload = {
      ...data,
      controlRange: {
        upper: data?.upper,
        lower: data?.lower,
        enable: data?.enable,
      },
    }

    // Merged the Sensor Data and Make new Station Data to Database
    const mergedSensorData = _.merge(selectedSensor, payload)
    let editedStation = stationData
    editedStation.sensors[selectedSensorIndex] = mergedSensorData

    // is on Template or on Station FIXME: Magic Code
    if (placeToEdit === 'template') {
      console.log('This is Template not station')
      dispatch(actions.stationTemplatePut(stationData._id, editedStation)).then(
        () => {
          dispatch(actions.stationAll())
        }
      )
    } else {
      console.log('This is Station')
      console.log('Station Data ', stationData)

      dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
        dispatch(actions.stationGet(stationData._id)).then(() => {
          setIsEditorMode(false)
        })
      })
    }
  }

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {' '}
              {sensorData.name}{' '}
              <Badge colorScheme="teal" size="sm">
                {sensorData.sensorType}{' '}
              </Badge>{' '}
            </h5>
          </div>
          {/************ Control Button **********/}
          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>{' '}
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </>
            ) : (
              <>
                {sensorData.sensorType === 'BOOLEAN' && (
                  <Button
                    size="sm"
                    colorScheme="yellow"
                    onClick={() => setIsEditorMode(true)}
                    type="button"
                  >
                    {t('utility.edit')}
                  </Button>
                )}
                {sensorData.sensorType === 'NUMBER' && (
                  <Button
                    size="sm"
                    colorScheme="yellow"
                    onClick={() => setIsEditorMode(true)}
                    type="button"
                  >
                    {t('utility.edit')}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {/************ End of Control Button **********/}
        <div className="flex gap-3">
          <div>
            {/************ Editor Mode **********/}
            {isEditorMode === true ? (
              <>
                {/* BOOLEAN TYPE */}
                {sensorData.sensorType === 'BOOLEAN' && (
                  <div className="mx-4">
                    <FormControl display="flex">
                      <FormLabel>
                        <div className="block uppercase text-gray-700 text-sm font-bold my-3">
                          {t('sensor.thresholdEnable')}
                        </div>
                      </FormLabel>
                      <Switch
                        className="my-2"
                        size="lg"
                        name="enableThreshold"
                        {...register('enableThreshold')}
                        defaultChecked={sensorData.enableThreshold}
                      />
                    </FormControl>
                    <FormControl>
                      <Select
                        name="triggerThresholdValue"
                        {...register('triggerThresholdValue')}
                        defaultValue={sensorData.triggerThresholdValue}
                      >
                        <option value={true}>{t('control.on')}</option>
                        <option value={false}>{t('control.off')}</option>
                      </Select>
                    </FormControl>
                  </div>
                )}
                {/* NUMBER TYPE */}
                {sensorData.sensorType === 'NUMBER' && (
                  <div className="mx-4">
                    <FormControl display="flex">
                      <FormLabel>
                        <div className="block uppercase text-gray-700 text-sm font-bold my-3">
                          {t('sensor.thresholdEnable')}
                        </div>
                      </FormLabel>
                      <Switch
                        className="my-2"
                        size="lg"
                        name="enableThreshold"
                        {...register('enableThreshold')}
                        defaultChecked={sensorData.enableThreshold}
                      />
                    </FormControl>
                    <FormControl>
                      <label className="text-sm font-bold">
                        {t('sensor.upperThreshold')}
                      </label>
                      <div className="flex flex-wrap">
                        <div className="w-1/2">
                          <Input
                            type="text"
                            name="upperThresholdValue"
                            size="sm"
                            {...register('upperThresholdValue')}
                            defaultValue={sensorData?.upperThresholdValue}
                          />
                        </div>
                        <div className="w-1/2">
                          <label className="m-2 text-sm font-bold">
                            {sensorData?.valueUnit}
                          </label>
                        </div>
                      </div>
                    </FormControl>
                    <FormControl>
                      <label className="text-sm font-bold">
                        {t('sensor.lowerThreshold')}
                      </label>
                      <div className="flex">
                        <div className="w-1/2">
                          <Input
                            type="text"
                            name="lowerThresholdValue"
                            size="sm"
                            {...register('lowerThresholdValue')}
                            defaultValue={sensorData.lowerThresholdValue}
                          />
                        </div>
                        <div className="w-1/2">
                          <label className="p-2 text-sm font-bold">
                            {sensorData.valueUnit}
                          </label>
                        </div>
                      </div>
                    </FormControl>
                  </div>
                )}
                {/************ Config Control Range **********/}
                <div className="mx-4">
                  <FormControl display="flex">
                    <FormLabel>
                      <div className="block uppercase text-gray-700 text-sm font-bold my-3">
                        {'เปิด/ปิด ใช้ค่า Control'}
                      </div>
                    </FormLabel>
                    <Switch
                      className="my-2"
                      size="lg"
                      name="enable"
                      {...register('enable')}
                      defaultChecked={sensorData?.controlRange?.enable}
                    />
                  </FormControl>
                  <FormControl>
                    <label className="text-sm font-bold">
                      {'ค่า Control บน'}
                    </label>
                    <div className="flex flex-wrap">
                      <div className="w-1/2">
                        <Input
                          type="text"
                          name="upper"
                          size="sm"
                          {...register('upper')}
                          defaultValue={sensorData?.controlRange?.upper}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="m-2 text-sm font-bold">
                          {sensorData?.valueUnit}
                        </label>
                      </div>
                    </div>
                  </FormControl>
                  <FormControl>
                    <label className="text-sm font-bold">
                      {'ค่า Control ล่าง'}
                    </label>
                    <div className="flex">
                      <div className="w-1/2">
                        <Input
                          type="text"
                          name="lower"
                          size="sm"
                          {...register('lower')}
                          defaultValue={sensorData?.controlRange?.lower}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="p-2 text-sm font-bold">
                          {sensorData.valueUnit}
                        </label>
                      </div>
                    </div>
                  </FormControl>
                </div>
              </>
            ) : (
              <>
                {/************ Display Mode **********/}
                <ul className="mx-4">
                  {sensorData.sensorType === 'BOOLEAN' && (
                    <div>
                      <li>
                        <span className="text-sm font-semibold text-gray-700">
                          {t('sensor.thresholdEnable')} :
                        </span>
                        {sensorData.enableThreshold ? (
                          <span className="text-md font-semibold text-green-500">
                            {' '}
                            {t('control.on')}{' '}
                          </span>
                        ) : (
                          <span className="text-md font-semibold text-red-500">
                            {' '}
                            {t('control.off')}{' '}
                          </span>
                        )}
                      </li>
                    </div>
                  )}

                  {sensorData.sensorType === 'NUMBER' && (
                    <>
                      <li>
                        <span className="text-sm font-semibold text-gray-700">
                          {t('sensor.thresholdEnable')} :
                        </span>
                        {sensorData.enableThreshold ? (
                          <span className="text-md font-semibold text-green-500">
                            {' '}
                            {t('control.on')}{' '}
                          </span>
                        ) : (
                          <span className="text-md font-semibold text-red-500">
                            {' '}
                            {t('control.off')}{' '}
                          </span>
                        )}
                      </li>
                      {sensorData.enableThreshold && (
                        <>
                          <li>
                            <span className="text-sm font-semibold text-gray-700">
                              {t('sensor.upperThreshold')} :
                            </span>
                            <span className="text-md font-semibold text-red-600">
                              {sensorData?.upperThresholdValue
                                ? sensorData?.upperThresholdValue.toFixed(2)
                                : '0.00'}
                              <span className="text-md font-semibold text-gray-700">
                                {sensorData?.valueUnit}
                              </span>
                            </span>
                          </li>

                          <li>
                            <span className="text-sm font-semibold text-gray-700">
                              {t('sensor.lowerThreshold')}:
                            </span>
                            <span className="text-md font-semibold text-red-600">
                              {sensorData?.lowerThresholdValue
                                ? sensorData?.lowerThresholdValue.toFixed(2)
                                : '0.00'}

                              <span className="text-md font-semibold text-gray-700">
                                {sensorData?.valueUnit}
                              </span>
                            </span>
                          </li>
                        </>
                      )}
                      {
                        <>
                          <li>
                            <span className="text-sm font-semibold text-gray-700">
                              {'ค่า CONTROL '} :
                            </span>
                            {sensorData?.controlRange?.enable ? (
                              <span className="text-md font-semibold text-green-500">
                                {' '}
                                {t('control.on')}{' '}
                              </span>
                            ) : (
                              <span className="text-md font-semibold text-red-500">
                                {' '}
                                {t('control.off')}{' '}
                              </span>
                            )}
                          </li>
                          <li>
                            <span className="text-sm font-semibold text-gray-700">
                              {'ค่า Control บน'} :{' '}
                            </span>
                            <span className="text-md font-semibold text-yellow-600">
                              {sensorData?.controlRange?.upper
                                ? sensorData?.controlRange?.upper.toFixed(2)
                                : '0.00'}
                              <span className="text-md font-semibold text-gray-700">
                                {' '}
                                {sensorData?.valueUnit}
                              </span>
                            </span>
                          </li>

                          <li>
                            <span className="text-sm font-semibold text-gray-700">
                              {'ค่า Control ล่าง'} :{' '}
                            </span>
                            <span className="text-md font-semibold text-yellow-600">
                              {sensorData?.controlRange?.lower
                                ? sensorData?.controlRange?.lower.toFixed(2)
                                : '0.00'}

                              <span className="text-md font-semibold text-gray-700">
                                {' '}
                                {sensorData?.valueUnit}
                              </span>
                            </span>
                          </li>
                        </>
                      }
                    </>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      </form>
    </Box>
  )
}
