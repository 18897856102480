import api from '../../../util/api'
import {
  SUB_FORM_ALL,
  SUB_FORM_GET,
  SUB_FORM_PUT,
  SUB_FORM_DEL,
  SUB_FORM_LOADING,
  SUB_FORM_ERROR,
  SUB_FORM_POST,
} from '../types'

export const subFormAll =
  ({ form }) =>
  async (dispatch) => {
    console.log('dd', form)
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/sub-form?form=${form}`
      )
      if (status === 200) {
        dispatch({ type: SUB_FORM_ALL, payload: data })
      }
    } catch (error) {
      console.error(error)
      dispatch({ type: SUB_FORM_ERROR })
      throw new Error(error)
    }
  }

export const subFormGet = (id) => async (dispatch) => {
  console.log('id', id)
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/sub-form/${id}`
    )
    if (status === 200) {
      dispatch({ type: SUB_FORM_GET, payload: data })
    }
  } catch (error) {
    console.error(error)
    dispatch({ type: SUB_FORM_ERROR })
    throw new Error(error)
  }
}

export const subFormPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SUB_FORM_LOADING })
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/sub-form/${id}`,
      payload
    )
    dispatch({ type: SUB_FORM_PUT, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: SUB_FORM_ERROR })
    throw new Error(error)
  }
}

export const subFormPost = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SUB_FORM_LOADING })
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/sub-form`,
      payload
    )
    dispatch({ type: SUB_FORM_POST, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: SUB_FORM_ERROR })
    throw new Error(error)
  }
}

export const subFormDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUB_FORM_LOADING })
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/sub-form/${id}`
    )
    dispatch({ type: SUB_FORM_DEL, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: SUB_FORM_ERROR })
    throw new Error(error)
  }
}
