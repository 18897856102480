import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash'
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from '../../../../components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'

//Page of Create form in management form.
export default function CreateSubForm() {
  const { t, i18n } = useTranslation()

  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const station = useSelector((state) => state.station)
  const { register, handleSubmit, reset, control } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [subForm, setSubForm] = useState(false)

  // useEffect(() => {
  //   dispatch(actions.stationGet(params.stationId))
  //   return () => {}
  // }, [params])

  useEffect(() => {
    setIsLoading(true)
    return () => {}
  }, [])

  const onSubmit = async (data, e) => {
    const confirm = window.confirm('ยืนยันการเพิ่มฟอร์ม')
    if (confirm) {
      const dataSubmit = {
        ...data,
        form_id: params?.formId,
      }
      console.log('dataSubmit', dataSubmit)
      await dispatch(actions.subFormPost(dataSubmit))
      await dispatch(actions.subFormAll({ form: params?.formId }))
      history.goBack()
    }
  }

  if (station) {
    return (
      <div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      เพิ่มแบบฟอร์มย่อย
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      ข้อมูลแบบฟอร์ม
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              ชื่อฟอร์มย่อย
                            </label>
                            <input
                              name="name"
                              type="text"
                              required
                              {...register('name')}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        {t('utility.save')}{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
