export const TimePeriod = {
    5: {
        value: 5,
        describe: '5 นาที',
    },
    10: {
        value: 10,
        describe: '10 นาที',
    },
    15: {
        value: 15,
        describe: '15 นาที',
    },
    30: {
        value: 30,
        describe: '30 นาที',
    },
    60: {
        value: 60,
        describe: '1 ชั่วโมง',
    },
    120: {
        value: 120,
        describe: '2 ชั่วโมง',
    },
    180: {
        value: 180,
        describe: '3 ชั่วโมง',
    },
    300: {
        value: 300,
        describe: '5 ชั่วโมง',
    },
    720: {
        value: 720,
        describe: '12 ชั่วโมง',
    },
    1440: {
        value: 1440,
        describe: '1 วัน',
    },
}

export default TimePeriod
