import React, { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { target } from 'tailwindcss/stubs/defaultConfig.stub'
import { useTranslation } from 'react-i18next'

export default function ModalStationCreateConfirmation({ isOpen, onClose }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [file, setFile] = useState()
  const handleUploadFile = (e) => {
    const fileReader = new FileReader()

    fileReader.readAsText(e.target.files[0], 'UTF-8')
    fileReader.onload = (e) => {
      // console.log("Target Result ", e.target.result);
      setFile(JSON.parse(e.target.result))
    }
  }
  const handleConfirmation = () => {
    console.log('Upload Template', file?.template?.name)
    dispatch(actions.stationTemplatePost(file?.template))

    onClose()
  }
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="font-sans">
          {t('template.uploadTemplate')}
        </ModalHeader>

        <ModalBody>
          <h5 className="font-normal text-base">
            {' '}
            {t('template.uploadTemplateDes')} <i>.json</i>
          </h5>
          <input type="file" onChange={handleUploadFile} accept=".json" />

          <br />
          {file ? (
            <div>
              {file && file.template ? (
                <div>
                  <p>
                    <b> {t('template.nameTemplate')}</b>{' '}
                    {file?.template?.templateName}{' '}
                  </p>
                  <p className="my-2"> {t('sensor.sensorStation')}</p>
                  {_.map(file.template?.sensors, (eachSensor) => (
                    <li> {eachSensor.name} </li>
                  ))}

                  <p className="text-gray-700 text-sm my-3">
                    {t('template.uploadTemplateDetail')}
                  </p>
                  <Button
                    colorScheme="blue"
                    type="button"
                    onClick={() => handleConfirmation()}
                  >
                    {t('utility.confirm')}
                  </Button>
                </div>
              ) : (
                <div>
                  {' '}
                  <p> {t('template.uploadFailDes')}</p>
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" type="button" onClick={onClose}>
            {t('utility.cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
