import React from 'react'
import { Badge, Box, Button } from '@chakra-ui/react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import DisplayBooleanAttribute from './DisplayBooleanAttribute'
import DisplayNumberAttribute from './DisplayNumberAttribute'
import DisplayStringAttribute from './DisplayStringAttribute'
import DisplayInputTextAttribute from './DisplayInputTextAttribute'
import Config from '../../../../config'

export default function DisplayTextAttribute({
  stationData,
  attributeData,
  diagramData,
}) {
  let displaySize = diagramData?.imageSize
  const { t } = useTranslation()
  return (
    <Box
      padding="1"
      w={`${displaySize}%`}
      borderRadius="lg"
      borderWidth="2"
      className="bg-gray-100 font-semibold border mb-2"
    >
      <div className="flex flex-col items-center">
        {_.map(diagramData?.attribute, (attr, index) => (
          <div key={index}>
            {attr.preferDataType === Config.DataType.DataType.boolean ? (
              <DisplayBooleanAttribute
                attributeData={attr}
                stationData={stationData}
              />
            ) : (
              <div>
                {attr.preferDataType === Config.DataType.DataType.number ? (
                  <DisplayNumberAttribute
                    attributeData={attr}
                    stationData={stationData}
                  />
                ) : (
                  <div>
                    {attr.preferDataType ===
                    Config.DataType.DataType.inputText ? (
                      <DisplayInputTextAttribute
                        attributeData={attr}
                        stationData={stationData}
                      />
                    ) : (
                      <DisplayStringAttribute
                        attributeData={attr}
                        stationData={stationData}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </Box>
  )
}
