import React from 'react'
import PropTypes from 'prop-types'

function FooterReportType2() {
  return {
    margin: [20, 90, 20, 0],
    style: 'tableExample',
    table: {
      widths: ['33.33%', '33.33%', '33.33%'],
      body: [
        [
          {
            text: '............................................',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: '............................................',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: '............................................',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: '( พนักงานควบคุมคุณภาพ )',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: '( ผู้ตรวจสอบ )',
            alignment: 'center',
            border: [false, false, false, false],
          },
          {
            text: '( หัวหน้างานผลิต )',
            alignment: 'center',
            border: [false, false, false, false],
          },
        ],
      ],
    },
  }
}

FooterReportType2.propTypes = {}

export default FooterReportType2
