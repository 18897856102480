import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../redux/actions'

export default function AddSensorInTemplate() {
  const { t } = useTranslation()
  const { register, handleSubmit, reset } = useForm()
  const [sensorType, setsensorType] = useState('BOOLEAN')
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const template = useSelector((state) => state.stationTemplate)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.stationTemplateGet(params.templateId))
    return () => {}
  }, [params])

  useEffect(() => {
    setIsLoading(true)
    return () => {}
  }, [])

  const onSubmit = async (data, e) => {
    let confirm = window.confirm('ยืนยันการเพิ่มเซ็นเซอร์')
    if (confirm) {
      e.preventDefault()
      console.log('From Data', data)
      let templateValue = template

      if (templateValue && templateValue.sensors) {
        data.controlRefString = data.refString
        console.log(templateValue.sensors)
        templateValue.sensors.push(data)
        console.log('PUSHED')
      }

      console.log('New Added Value ', templateValue)
      dispatch(actions.stationTemplatePut(params.templateId, templateValue))
      history.goBack()
    }
  }

  if (template && isLoading) {
    return (
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-base font-sans font-bold ">
                    {t('template.addSensorToTemplate')} {template.templateName}
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                    {t('sensor.sensorInfo')}
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('sensor.name')}
                        </label>
                        <input
                          name="name"
                          type="text"
                          required
                          {...register('name')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder={t('sensor.rainSensor')}
                        />
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('sensor.description')}
                        </label>
                        <input
                          name="description"
                          type="text"
                          {...register('description')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder={t('sensor.sensorDes')}
                        />
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('sensor.sensorID')}
                        </label>
                        <label
                          className="block  text-gray-500 text-xs font-normal mb-2"
                          htmlFor="grid-password"
                        >
                          {t('sensor.sensorIDDes')}
                        </label>
                        <input
                          name="id"
                          type="text"
                          required
                          {...register('id')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="00001"
                        />
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('sensor.refString')}
                        </label>
                        <label
                          className="block  text-gray-500 text-xs font-normal mb-2"
                          htmlFor="grid-password"
                        >
                          {t('sensor.refStringDescp')}
                        </label>
                        <input
                          name="refString"
                          type="text"
                          {...register('refString')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="rain1"
                        />
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('sensor.sensorType')}
                        </label>
                        <select
                          name="sensorType"
                          required
                          {...register('sensorType')}
                          onChange={(event) =>
                            setsensorType(event.target.value)
                          }
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          <option value="BOOLEAN">BOOLEAN</option>
                          <option value="NUMBER">NUMBER</option>
                          <option value="STRING">STRING</option>
                        </select>
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('sensor.subSensorType')}
                        </label>
                        <select
                          name="gaugeType"
                          required
                          {...register('gaugeType')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          <option value="OnOffGauge">
                            {t('sensor.onOffSensor')}
                          </option>
                          <option value="TriggerGague">
                            {t('sensor.triggerSensor')}
                          </option>
                          <option value="WaterLevelGauge">
                            {t('sensor.waterLevelSensor')}
                          </option>
                          <option value="AmpMeter">
                            {t('sensor.electricitySensor')}
                          </option>
                          <option value="NumberOnly">
                            {t('sensor.countingSensor')}
                          </option>
                          <option value="Barometer">
                            {t('sensor.pressureSensor')}
                          </option>
                          <option value="FlowGague">
                            {t('sensor.waterFlowSensor')}
                          </option>
                          <option value="StatusOnlyOnOff">
                            {t('sensor.displayOnOffSensor')}
                          </option>
                          <option value="MajorControl">
                            {t('sensor.allowAnotherControlSensor')}
                          </option>
                          <option value="NoDisplay">
                            {t('sensor.noDisplay')}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t('graph.graphTypeDisplay')}
                        </label>
                        <select
                          name="chartType"
                          required
                          {...register('chartType')}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          <option value="AreaRealtimeGraph">
                            {t('graph.lineNormalGraph')}
                          </option>
                          <option value="BaroGraph">
                            {t('graph.pressureGraph')}
                          </option>
                          <option value="AmpGraph">
                            {t('graph.electricityGraph')}
                          </option>
                          <option value="NoDisplay">
                            {t('graph.noDisplay')}
                          </option>
                        </select>
                      </div>
                    </div>
                    {/** End of  General Input */}
                    {/** Specific Input */}
                    {sensorType === 'BOOLEAN' && (
                      <div>
                        <div className="w-full  px-4">
                          <div className="relative w-full  mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              {t('control.pulseControl')}
                            </label>
                            <select
                              name="controlWithPulse"
                              required
                              {...register('controlWithPulse')}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            >
                              {' '}
                              <option value={false}>{t('control.no')}</option>
                              <option value={true}>{t('control.yes')}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    {sensorType === 'NUMBER' && (
                      <>
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              {t('sensor.valueOfSensor')}
                            </label>

                            <input
                              name="valueNote"
                              type="text"
                              {...register('valueNote')}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              placeholder={t('sensor.valueOfRain')}
                            />
                          </div>
                        </div>
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              {t('sensor.unitOfValue')}
                            </label>

                            <input
                              name="valueUnit"
                              type="text"
                              {...register('valueUnit')}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              placeholder={t('sensor.cubicCentimeters')}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="text-center px-4 gap-0 py-3 ">
                    <Button
                      isFullWidth
                      colorScheme="blue"
                      variant="solid"
                      type="submit"
                    >
                      {t('utility.save')}{' '}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
