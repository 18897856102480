/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react'
import config from '../../../config'
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import _ from 'lodash'

export default function GalleryImageDiagram({
  setSelectImage,
  selectImage,
  setTypeImage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [titleImage, setTitleImage] = useState('')

  const imageList = [
    {
      title: 'BoilerType',
      src: '/images/Boiler/Boiler.svg',
    },
    {
      title: 'PumpType',
      src: '/images/Pump/Chemicalpump.svg',
    },
    {
      title: 'ConveyorType',
      src: '/images/Conveyor/Shorthorizontalsegment.svg',
    },
    {
      title: 'FlowMeterType',
      src: '/images/FlowMeter/Flowmeter.svg',
    },
    {
      title: 'MotorType',
      src: '/images/Motor/Motor2.svg',
    },
    {
      title: 'PipeType',
      src: '/images/Pipe/ShortHorizontalpipe.svg',
    },
  ]

  const genImages = () => {
    switch (titleImage) {
      case config?.ImagesType?.BoilerType?.Boiler.value:
        return (
          <div>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
              <ModalContent>
                <ModalHeader>เลือกรูปที่ต้องการ</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {_.map(
                    config?.ImagesType?.BoilerType?.BoilerSrc,
                    (eachBoiler, index) => (
                      <button
                        className="m-3 p-3 bg-gray-200 "
                        key={index}
                        onClick={() => {
                          setSelectImage(eachBoiler)
                          setTypeImage('Boiler')
                        }}
                      >
                        {' '}
                        <div className="center">
                          <h6>{eachBoiler.name}</h6>
                          <img
                            src={'/Images/Boiler/' + eachBoiler.value}
                            className="w-20 "
                          />
                        </div>
                      </button>
                    )
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={() => {
                      setSelectImage()
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                  <Button colorScheme="green" onClick={onClose}>
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        )
      case config.ImagesType.PumpType.Pump.value:
        return (
          <div>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
              <ModalContent>
                <ModalHeader>เลือกรูปที่ต้องการ</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {_.map(
                    config.ImagesType.PumpType.PumpSrc,
                    (eachPump, index) => (
                      <button
                        className="m-3 p-3 bg-gray-200 "
                        key={index}
                        onClick={() => {
                          setSelectImage(eachPump)
                          setTypeImage('Pump')
                        }}
                      >
                        {' '}
                        <div className="center">
                          <h6>{eachPump.name}</h6>
                          <img
                            src={'/Images/Pump/' + eachPump.value}
                            className="w-20 "
                          />
                        </div>
                      </button>
                    )
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={() => {
                      setSelectImage()
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                  <Button colorScheme="green" onClick={onClose}>
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        )
      case config.ImagesType.ConveyorType.Conveyor.value:
        return (
          <div>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
              <ModalContent>
                <ModalHeader>เลือกรูปที่ต้องการ</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {_.map(
                    config.ImagesType.ConveyorType.ConveyorSrc,
                    (eachConveyor, index) => (
                      <button
                        className="m-3 p-3 bg-gray-200 "
                        key={index}
                        onClick={() => {
                          setSelectImage(eachConveyor)
                          setTypeImage('Conveyor')
                        }}
                      >
                        {' '}
                        <div className="center">
                          <h6>{eachConveyor.name}</h6>
                          <img
                            src={'/Images/Conveyor/' + eachConveyor.value}
                            className="w-20 "
                          />
                        </div>
                      </button>
                    )
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={() => {
                      setSelectImage()
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                  <Button colorScheme="green" onClick={onClose}>
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        )
      case config.ImagesType.FlowMeterType.FlowMeter.value:
        return (
          <div>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
              <ModalContent>
                <ModalHeader>เลือกรูปที่ต้องการ</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {_.map(
                    config.ImagesType.FlowMeterType.FlowMeterSrc,
                    (eachFlowmeter, index) => (
                      <button
                        className="m-3 p-3 bg-gray-200 "
                        key={index}
                        onClick={() => {
                          setSelectImage(eachFlowmeter)
                          setTypeImage('FlowMeter')
                        }}
                      >
                        {' '}
                        <div className="center">
                          <h6>{eachFlowmeter.name}</h6>
                          <img
                            src={'/Images/FlowMeter/' + eachFlowmeter.value}
                            className="w-20 "
                          />
                        </div>
                      </button>
                    )
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={() => {
                      setSelectImage()
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                  <Button colorScheme="green" onClick={onClose}>
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        )
      case config.ImagesType.MotorType.Motor.value:
        return (
          <div>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
              <ModalContent>
                <ModalHeader>เลือกรูปที่ต้องการ</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {_.map(
                    config.ImagesType.MotorType.MotorSrc,
                    (eachMotor, index) => (
                      <button
                        className="m-3 p-3 bg-gray-200 "
                        key={index}
                        onClick={() => {
                          setSelectImage(eachMotor)
                          setTypeImage('Motor')
                        }}
                      >
                        {' '}
                        <div className="center">
                          <h6>{eachMotor.name}</h6>
                          <img
                            src={'/Images/Motor/' + eachMotor.value}
                            className="w-20 "
                          />
                        </div>
                      </button>
                    )
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={() => {
                      setSelectImage()
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                  <Button colorScheme="green" onClick={onClose}>
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        )
      case config.ImagesType.PipeType.Pipe.value:
        return (
          <div>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
              <ModalContent>
                <ModalHeader>เลือกรูปที่ต้องการ</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {_.map(
                    config.ImagesType.PipeType.PipeSrc,
                    (eachPipe, index) => (
                      <button
                        className="m-3 p-3 bg-gray-200 "
                        key={index}
                        onClick={() => {
                          setSelectImage(eachPipe)
                          setTypeImage('Pipe')
                        }}
                      >
                        {' '}
                        <div className="center">
                          <h6>{eachPipe.name}</h6>
                          <img
                            src={'/Images/Pipe/' + eachPipe.value}
                            className="w-20 "
                          />
                        </div>
                      </button>
                    )
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={() => {
                      setSelectImage()
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                  <Button colorScheme="green" onClick={onClose}>
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        )
      default:
        return <div></div>
    }
  }

  return (
    <div>
      {genImages()}

      {_.map(imageList, (eachImage, index) => (
        <button
          className="m-3 p-3 bg-gray-200 "
          key={index}
          onClick={() => {
            setTitleImage(eachImage.title)
            onOpen()
          }}
        >
          {' '}
          <div>
            <div>{eachImage?.title}</div>
            <img src={eachImage?.src} className="w-20" />
          </div>
        </button>
      ))}

      {/* {console.log('select Imagee', selectImage)} */}
    </div>
  )
}
