/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import BackButton from 'components/Button/BackButton'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link, useParams, useHistory } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// components
import { Button, Collapse, Select } from '@chakra-ui/react'
import DashboardMonitorComponent from './Components/DashboardMonitorComponent'
import DashboardFavoriteComponent from './Components/DashboardFavoriteComponent'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import PlaceHolderLogo from '../../assets/img/eiotlogo.png'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { index } from 'mathjs'
import Slider from 'react-slick'

export default function StationFavorite({ sidebarStatus, setIsShowSidebar }) {
  const [selectType, setSelectType] = useState('slide01')
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [currentStation, setcurrentStation] = useState()
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const [isStationInfoShow, setIsStationInfoShow] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const me = useSelector((state) => state.me)
  const history = useHistory()
  useEffect(() => {
    if (me && me._id && currentStation) {
      if (_.includes(me.duty, 'ALLAREA') || me.duty === currentStation._id) {
        console.log('ALLAREA')
      } else {
        console.log('ผู้ใช้ไม่มีสิทธิเข้าใช้')
      }
      console.log('current ', currentStation)
    }
    return () => {}
  }, [me, currentStation])

  useEffect(() => {
    if (isFetchNewOne === true) {
      dispatch(actions.stationAll())
      //FIXME: Uncommen to Enable Auto Refresh
      setisFetchNewOne(false)
    }
    return () => {}
  }, [isFetchNewOne])

  // ดึงข้อมูลสถานีทั้งหมดมา เพราะว่า จะต้องเอาไปโชว์ใน Sidebar
  // จากนั้นค่อยหาเอาตัวหนึ่งที่ไอดีมันถูก
  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.stationId
      )
      setcurrentStation(tempcurrentStatus)
      console.log('Curent Station', currentStation)
    }
    return () => {}
  }, [params, allStations])

  useEffect(() => {
    setIsLoading(true)
    return () => {}
  }, [])

  useEffect(() => {
    if (isFetchNewOne === false) {
      setTimeout(() => {
        setisFetchNewOne(true)
      }, 5000)
    }
    return () => {}
  }, [isFetchNewOne])

  const handleRefreshStation = () => {
    dispatch(actions.stataionRefresh(currentStation._id))
  }

  const settingsSlide = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    cssEase: 'linear',
  }

  const handleSelectType = (e) => {
    setSelectType(e.target.value)
  }

  let orderedGauge = _.orderBy(currentStation?.gauges, 'orderLevel', 'asc')

  return isLoading && currentStation ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('dashboard.dashboardMonitor')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('dashboard.dashboard')}รายการโปรด {currentStation.name}
            </h2>
          </div>
        </div>
        <Button>
          <BackButton />
        </Button>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex justify-items-start">
          {/* <Select
            placeholder="รูปแบบการโชว์"
            type="text"
            name="electricType"
            defaultValue={selectType}
            onChange={handleSelectType}
          >
            <option value="slide01">เลื่อนโชว์</option>
            <option value="slide02">เลือกแผนก</option>
          </Select> */}
        </div>
        <div className="flex justify-items-end gap-1">
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsStationInfoShow(!isStationInfoShow)}
          >
            {t('dashboard.stationInfo')}
          </Button>
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsShowSidebar(!sidebarStatus)}
          >
            {t('dashboard.toggleSidebar')}
          </Button>
        </div>
      </div>
      <DashboardFavoriteComponent currentStation={currentStation} user={me} />
    </div>
  ) : (
    <SpinnerLoading />
  )
}
