/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button, FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import GaugeTypeData from '../../../../config/GaugeType.json'
import NumberSensorType from '../../../../config/NumberSensorType.json'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { InlineMath, BlockMath } from 'react-katex'
import 'katex/dist/katex.min.css'
import Latex from 'react-latex'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'

export default function AddEquation() {
  const { t, i18n } = useTranslation()

  const { register, handleSubmit, reset } = useForm()
  const [sensorType, setsensorType] = useState('BOOLEAN')
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const station = useSelector((state) => state.station)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.stationGet(params.stationId))
    setIsLoading(true)
    return () => {}
  }, [params])

  const [isShowEquation, setIsShowEquation] = useState('')
  const [isEquation, setIsEquation] = useState('')
  const [isSymbol, setIsSymbol] = useState([])

  const renderEquation = () => {
    axios
      .post(process.env.REACT_APP_API_URL + '/equation', {
        equation: isEquation,
      })
      .then(async (res) => {
        const payload = res.data
        console.log(payload)
        setIsShowEquation(payload.latex)
        setIsSymbol(payload.symbol)
      })
  }

  const onSubmit = async (data, e) => {
    let confirm = window.confirm('ยืนยันการเพิ่มสมการ')
    data.latex = isShowEquation
    data.symbol = isSymbol
    //console.log('EQUATION = ', data)
    if (confirm) {
      e.preventDefault()
      console.log('From Data', data)
      let templateValue = station

      if (templateValue && templateValue.equations) {
        templateValue.equations.push(data)
        console.log('PUSHED')
      }
      console.log('New Added Value ', templateValue)
      dispatch(actions.stationPut(params.stationId, templateValue)).then(() => {
        dispatch(actions.stationAll()).then(() => {
          history.goBack()
        })
      })
    }
  }

  if (station && isLoading) {
    return (
      <div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      {'เพิ่มสมการลงในสถานี\n'}
                      {station.name}
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      {'สมการ'}
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'ชื่อสมการ'}
                          </label>
                          <input
                            name="name"
                            type="text"
                            required
                            {...register('name')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder={t('เช่น สมการคำนวณพื้นที่วงกลม ')}
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'เกี่ยวกับสมการ'}
                          </label>
                          <input
                            name="description"
                            type="text"
                            {...register('description')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder={'เช่น ใช้คำนวณหาพื้นที่วงกลม'}
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'ใส่สมการ'}
                          </label>
                          <input
                            name="equationString"
                            type="text"
                            {...register('equationString')}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="pi*(r**2)"
                            onChange={(e) => setIsEquation(e.target.value)}
                          />
                          <div className="flex flex-center justify-items-end mx-2 my-2">
                            <Button
                              colorScheme="blue"
                              variant="solid"
                              onClick={renderEquation}
                            >
                              {'แสดงสมการ'}{' '}
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {'แสดงสมการ'}
                          </label>
                          <div className="flex justify-center mx-2 my-2 text-lg font-bold mb-2 bg-gray-100 shadow-md rounded-md">
                            <Latex displayMode={true}>
                              {'$$ f(x) = ' + isShowEquation + '$$'}
                            </Latex>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        {t('utility.save')}{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <SpinnerLoading />
  }
}
