import React, { useState } from 'react'
import _ from 'lodash'
import {
  Switch,
  FormControl,
  Input,
  Select,
  Button,
  Tr,
  Td,
} from '@chakra-ui/react'

export default function VariableAddingComponent({
  gaugeVariables,
  setGaugeVariables,
  stationData,
  rendered,
  setRendered,
}) {
  const [isUsingSensor, setIsUsingSensor] = useState(true)
  const sensorRefList = () => {
    return _.map(stationData.sensors, (sensor) => (
      <option value={sensor.refString}> {sensor.refString} </option>
    ))
  }

  const [sensorRef, setSensorRef] = useState('')
  const [constantValue, setConstantValue] = useState(1)
  const [variableName, setVariableName] = useState('')

  const handlingUsingSensor = (booleanString) => {
    if (booleanString === 'true') {
      setIsUsingSensor(true)
    } else if (booleanString === 'false') {
      setIsUsingSensor(false)
    }
  }
  const handleAddVariable = () => {
    const tempJSONVariable = {
      variableName: variableName,
      sensorRef: sensorRef,
      constantValue: constantValue,
      useFromSensorNotVariable: isUsingSensor,
    }
    let tempGagueVariableList = gaugeVariables
    tempGagueVariableList.push(tempJSONVariable)
    setGaugeVariables(tempGagueVariableList)
    setRendered(!rendered)
    // console.log(gaugeVariables)
  }
  return (
    <Tr>
      <Td>
        <Input
          type="text"
          size="sm"
          onChange={(event) => setVariableName(event.target.value)}
        />
      </Td>
      <Td>
        <Select
          className="w-1/3"
          size="sm"
          onChange={(event) => handlingUsingSensor(event.target.value)}
        >
          <option value={true}>เซ็นเซอร์</option>
          <option value={false}>ค่าคงที่</option>
        </Select>
      </Td>
      <Td>
        {isUsingSensor === true ? (
          <div>
            <Select
              className="w-1/3"
              size="sm"
              onChange={(event) => setSensorRef(event.target.value)}
            >
              <option value="">เลือกเซ็นเซอร์</option>
              {sensorRefList()}
            </Select>
          </div>
        ) : (
          <div>
            <Input
              type="number"
              className="w-1/3"
              size="sm"
              required={false}
              onChange={(event) => setConstantValue(event.target.value)}
            />
          </div>
        )}
      </Td>
      <Td>
        <Button
          colorScheme="blue"
          size="sm"
          className="self-center"
          type="button"
          onClick={() => handleAddVariable()}
        >
          เพิ่ม
        </Button>
      </Td>
    </Tr>
  )
}
